import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import { logoutUser } from "../../store/actions";

//redux
import { useDispatch } from "react-redux";

interface LogoutProps {
  history: any;
}

const Logout = ({ history }: LogoutProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      dispatch(logoutUser(history));
    } catch {
      console.log("로그아웃");
      localStorage.removeItem("access_token");
      localStorage.removeItem("login_key");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("factoryList");
      history.push("/login");
    }
  }, [dispatch, history]);

  return <></>;
};

export default withRouter(Logout);
