/** @format */

import React, { memo } from "react";
import { Table, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

const RealTimeTable = memo(({ latestMessage }: any) => {
  const { t } = useTranslation();

  const pannelList = Object.keys(latestMessage).filter((item: string) => {
    if (!item.includes("set") && item !== "date") {
      return true;
    }
    return false;
  });

  return (
    <div>
      <div className='d-flex flex-wrap align-items-center justify-content-between'>
        <h5 className='card-title me-2'>{t("Realtime Data")}</h5>
        <h5 className='card-title me-2'>{latestMessage.date}</h5>
      </div>
      <Table className='table mb-0'>
        <thead>
          <tr>
            <th className='text-center'>Pannel Name</th>
            <th className='text-center'>Data</th>
          </tr>
        </thead>
        <tbody>
          {!isEmpty(pannelList) &&
            pannelList.map((pannel: string) => {
              return (
                <tr key={pannel} style={{ cursor: "auto" }}>
                  <td className='text-center'>
                    {pannel.includes("leak")
                      ? pannel.replace("leak", "overcurrent")
                      : pannel}
                  </td>
                  <td className='text-center'>
                    {latestMessage[pannel]
                      ? Number(latestMessage[pannel])
                      : "-"}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
});

export default RealTimeTable;
