/** @format */

import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import makeAnimated from "react-select/animated";
import { removeToken } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

interface LoginProps {
  history: object;
}

const UserLogoutModal = (
  { isOpen, closeModal, loginUserList, userInputInfo }: any,
  { history }: LoginProps
) => {
  const animatedComponents = makeAnimated();

  const [userList, setUserList] = useState<any>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loginUserList) {
      const data = [];
      for (let [key, value] of Object.entries<Object>(loginUserList)) {
        data.push({
          ...value,
          login_key: key,
        });
      }

      for (let i = 0; i < data.length - 1; i++) {
        for (let j = i + 1; j < data.length; j++) {
          const date1 = Number(data[i].date);
          const date2 = Number(data[j].date);
          if (date1 < date2) {
            const temp = _.cloneDeep(data[i]);
            data[i] = data[j];
            data[j] = temp;
          }
        }
      }

      setUserList(data);
    }
  }, [loginUserList]);

  useEffect(() => {}, []);
  const toggle = () => {
    closeModal();
  };

  function currentDate(data: any) {
    const resUtc = new Date(data);
    const year = resUtc.getFullYear();
    const month = ("0" + (resUtc.getMonth() + 1)).slice(-2);
    const date = ("0" + resUtc.getDate()).slice(-2);

    const hours = ("0" + resUtc.getHours()).slice(-2);
    const minutes = ("0" + resUtc.getMinutes()).slice(-2);
    const seconds = ("0" + resUtc.getSeconds()).slice(-2);

    return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle} tag='h6'>
        {"사용자 동시 접속 초과 알림"}
      </ModalHeader>
      <ModalBody>
        <Card>
          <CardBody style={{ overflow: "auto", maxHeight: "50vh" }}>
            {/* <p className="text-muted mb-2">로그인 사용자 수를 초과했습니다.</p> */}

            <AvForm onValidSubmit={(e: any, values: any) => {}}>
              <Row form>
                <Col xs={12}>
                  <div>
                    {userList &&
                      _.sortBy(userList, "date").map((item: any) => {
                        return (
                          <div
                            key={item.login_key}
                            style={{
                              borderBottom: "1px solid #e9e9ef",
                              padding: "5px",
                              marginTop: "5px",
                            }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}>
                              <div>
                                <h6>
                                  {item.name} (
                                  {currentDate(Number(item.date) * 1000)})
                                </h6>
                                <h6>
                                  {item.device}&nbsp;/&nbsp;{item.addr}
                                </h6>
                              </div>

                              <div className='square-switch mt-1'>
                                <button
                                  className='btn btn-outline-danger btn-sm waves-effect waves-light'
                                  onClick={() => {
                                    const data = {
                                      email: userInputInfo.email,
                                      password: userInputInfo.password,
                                      login_key: item.login_key,
                                    };
                                    dispatch(removeToken(data, history));
                                    closeModal();
                                  }}>
                                  Logout
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
        <div className='text-end'>
          <button
            type='submit'
            className='btn btn-secondary save-user'
            onClick={() => {
              // setAddModal(false);
              closeModal();
            }}>
            Cancel
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UserLogoutModal;
