/**
 *  interface for Login Type
 */
export enum LoginTypes {
  LOGIN_USER = "@@login/LOGIN_USER",
  LOGIN_SUCCESS = "@@login/LOGIN_SUCCESS",
  LOGOUT_USER = "@@login/LOGOUT_USER",
  LOGOUT_USER_SUCCESS = "@@login/LOGOUT_USER_SUCCESS",
  API_ERROR = "@@login/API_ERROR",
  SOCIAL_LOGIN = "@@login/SOCIAL_LOGIN",
  VERIFICATION_SEND_MAIL = "@@login/VERIFICATION_SEND_MAIL",
  VERIFICATION_CODE = "@@login/VERIFICATION_CODE",
  SEND_RESET_CODE = "@@login/SEND_RESET_CODE",
  RESET_VERIFICATION_CODE = "@@login/RESET_VERIFICATION_CODE",
  RESET_PASSWORD = "@@login/RESET_PASSWORD",
  GET_USER = "@@login/GET_USER",
  PUT_USER = "@@login/PUT_USER",
  DELETE_USER = "@@login/DELETE_USER",
  REMOVE_TOKEN = "@@login/REMOVE_TOKEN",
  GETUSER = "@@login/GETUSER",
}
