import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  CardBody,
  Label,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { isEmpty } from "lodash";
import { get, put, post, del } from "../../helpers/api_helper";

const optionMulti = [
  { label: "dustmqdkdlel@ijoon.net", value: "dustmqdkdlel@ijoon.net" },
  { label: "xpavmdkdlel@ijoon.net", value: "xpavmdkdlel@ijoon.net" },
  { label: "xptmxm@ijoon.net", value: "xptmxm@ijoon.net" },
  { label: "dlrjteh@ijoon.net", value: "dlrjteh@ijoon.net" },
  { label: "ze_regre@ijoon.net", value: "ze_regre@ijoon.net" },
  { label: "g2@ijoon.net", value: "g2@ijoon.net" },
  { label: "facefrg@ijoon.net", value: "facefrg@ijoon.net" },
];
const AddShare = ({
  addModal,
  setAddModal,
  setAddUser,
  selectFac,
  handleAddUser,
  toggle,
}: any) => {
  const [optionMulti, setOptionMulti]: any[] = useState();
  const animatedComponents = makeAnimated();
  const [checkData, setCheckData] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [selectedValue, setSelectedValue] = useState();
  const [selectedEmail, setSelectedEmail]: any = useState([]);
  useEffect(() => {
    const authUser: any = localStorage.getItem("authUser");
    if (authUser) {
      const obj = JSON.parse(authUser);

      get(`/api/auth/v1/users/`, {
        headers: {
          Authorization: localStorage.getItem("access_token"),
        },
      })
        .then((res) => {
          if (!isEmpty(res)) {
            setOptionMulti(
              res.users
                .filter((item: any) => item.email !== "airwalk741@naver.com")
                .map((v: any) => {
                  return {
                    label: v.email,
                    value: v.email,
                  };
                })
            );
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            // get(`/api/auth/v1/access-token`, {
            //   headers: {
            //     Authorization: localStorage.getItem("refresh_token"),
            //   },
            // }).then((res) => {
            //   if (!isEmpty(res)) {
            //     localStorage.setItem("access_token", res.accessToken);
            //     location.reload();
            //   }
            // });
          } else {
            setOptionMulti([
              {
                label: "dustmqdkdlel@ijoon.net",
                value: "dustmqdkdlel@ijoon.net",
              },
              {
                label: "xpavmdkdlel@ijoon.net",
                value: "xpavmdkdlel@ijoon.net",
              },
              { label: "xptmxm@ijoon.net", value: "xptmxm@ijoon.net" },
              { label: "dlrjteh@ijoon.net", value: "dlrjteh@ijoon.net" },
              { label: "ze_regre@ijoon.net", value: "ze_regre@ijoon.net" },
              { label: "g2@ijoon.net", value: "g2@ijoon.net" },
              { label: "facefrg@ijoon.net", value: "facefrg@ijoon.net" },
            ]);
          }
        });
    }
  }, []);

  const handleChange = (e: any) => {
    setSelectedValue(e.value);
    // const data = e.map((v: any) => {
    //   return v.value;
    // });

    setSelectedEmail([e.value]);
  };
  useEffect(() => {
    setSelectedEmail([]);
    setSelectedValue(undefined);
  }, [addModal]);

  const AddShareUser = (data: any) => {
    post("/api/main/v1/share", data[0], {
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    })
      .then((res) => {
        handleAddUser({
          ...data[0],
          id: res.id,
        });
        setAddUser([]);
      })
      .catch((err: any) => {
        console.log(err);
        alert("이미 추가된 회원입니다.");
        setAddUser([]);
      });
  };

  return (
    <Modal isOpen={addModal} toggle={toggle}>
      <CardBody style={{ padding: "1rem" }}>
        <label className='control-label'>Add User</label>
        <br />
        {selectFac && (
          <label className='control-label'>
            Selected Factory : {selectFac.name}
          </label>
        )}

        {optionMulti && (
          <Select
            isMulti={false}
            options={optionMulti}
            classNamePrefix='select2-selection'
            closeMenuOnSelect={true}
            value={optionMulti.find((obj: any) => obj.value === selectedValue)}
            components={animatedComponents}
            onChange={handleChange}
          />
        )}
      </CardBody>
      <ModalBody>
        <AvForm onValidSubmit={(e: any, values: any) => {}}>
          <Row form>
            <Col xs={12}>
              <div className='mb-3'>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <h5>Create</h5>{" "}
                  <div
                    className='square-switch'
                    style={{
                      marginLeft: "20px",
                    }}>
                    <Input
                      type='checkbox'
                      name='square-switch1'
                      id='square-switch1'
                      switch='none'
                      checked={checkData[0]}
                      onChange={() => {
                        const data = [...checkData];
                        data[0] = !checkData[0];
                        setCheckData(data);
                      }}
                    />
                    <Label
                      htmlFor='square-switch1'
                      data-on-label='On'
                      data-off-label='Off'></Label>
                  </div>
                </div>
                <div
                  style={{
                    paddingBottom: "10px",
                    borderBottom: "1px solid #7a7fdc",
                  }}>
                  공장을 만들 수 있는 권한입니다.
                </div>
              </div>
              <div className='mb-3'>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <h5>Read</h5>{" "}
                  <div className='square-switch' style={{ marginLeft: "20px" }}>
                    <Input
                      type='checkbox'
                      name='square-switch2'
                      id='square-switch2'
                      switch='none'
                      checked={checkData[1]}
                      onChange={() => {
                        const data = [...checkData];
                        data[1] = !checkData[1];
                        setCheckData(data);
                      }}
                    />
                    <Label
                      htmlFor='square-switch2'
                      data-on-label='On'
                      data-off-label='Off'></Label>
                  </div>
                </div>
                <div
                  style={{
                    paddingBottom: "10px",
                    borderBottom: "1px solid #7a7fdc",
                  }}>
                  공장을 읽을 수 있는 권한입니다.
                </div>
              </div>
              <div className='mb-3'>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <h5>Update</h5>{" "}
                  <div className='square-switch' style={{ marginLeft: "20px" }}>
                    <Input
                      type='checkbox'
                      id='square-switch3'
                      name='square-switch3'
                      switch='none'
                      checked={checkData[2]}
                      onChange={() => {
                        const data = [...checkData];
                        data[2] = !checkData[2];
                        setCheckData(data);
                      }}
                    />
                    <Label
                      htmlFor='square-switch3'
                      data-on-label='On'
                      data-off-label='Off'></Label>
                  </div>
                </div>
                <div
                  style={{
                    paddingBottom: "10px",
                    borderBottom: "1px solid #7a7fdc",
                  }}>
                  공장을 수정할 수 있는 권한입니다.
                </div>
              </div>
              <div className='mb-3'>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <h5>Delete</h5>{" "}
                  <div className='square-switch' style={{ marginLeft: "20px" }}>
                    <Input
                      type='checkbox'
                      name='square-switch4'
                      id='square-switch4'
                      switch='none'
                      checked={checkData[3]}
                      onChange={() => {
                        const data = [...checkData];
                        data[3] = !checkData[3];
                        setCheckData(data);
                      }}
                    />
                    <Label
                      htmlFor='square-switch4'
                      data-on-label='On'
                      data-off-label='Off'></Label>
                  </div>
                </div>
                <div
                  style={{
                    paddingBottom: "10px",
                    borderBottom: "1px solid #7a7fdc",
                  }}>
                  공장을 삭제할 수 있는 권한입니다.
                </div>
              </div>
              <div className='mb-3'>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <h5>Share User</h5>{" "}
                  <div className='square-switch' style={{ marginLeft: "20px" }}>
                    <Input
                      type='checkbox'
                      id='square-switch5'
                      name='square-switch5'
                      switch='none'
                      checked={checkData[4]}
                      onChange={() => {
                        const data = [...checkData];
                        data[4] = !checkData[4];
                        setCheckData(data);
                      }}
                    />
                    <Label
                      htmlFor='square-switch5'
                      data-on-label='On'
                      data-off-label='Off'></Label>
                  </div>
                </div>
                <div
                  style={{
                    paddingBottom: "10px",
                    borderBottom: "1px solid #7a7fdc",
                  }}>
                  공장을 다른 사람에게 공유할 수 있는 권한입니다.
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='text-end'>
                <button
                  type='submit'
                  className='btn btn-secondary save-user'
                  onClick={() => {
                    setAddModal(false);
                    setCheckData([false, false, false, false, false]);
                    setSelectedEmail([]);
                  }}>
                  Cancel
                </button>{" "}
                <button
                  type='submit'
                  className='btn btn-primary save-user'
                  onClick={() => {
                    if (isEmpty(selectedEmail)) return;

                    AddShareUser([
                      ...selectedEmail.map((v: any, index: number) => {
                        return {
                          factory_id: selectFac.id,
                          owner_email: selectFac.owner_email,
                          share_email: v,
                          share_auth: {
                            machine_create: checkData[0],
                            machine_delete: checkData[3],
                            machine_read: checkData[1],
                            machine_update: checkData[2],
                            share_user_update: checkData[4],
                          },
                        };
                      }),
                    ]);
                    // setAddUser([
                    //   ...selectedEmail.map((v: any, index: number) => {
                    //     return {
                    //       factory_id: selectFac.id,
                    //       owner_email: selectFac.owner_email,
                    //       share_email: v,
                    //       share_auth: {
                    //         machine_create: checkData[0],
                    //         machine_delete: checkData[3],
                    //         machine_read: checkData[1],
                    //         machine_update: checkData[2],
                    //         share_user_update: checkData[4],
                    //       },
                    //     };
                    //   }),
                    // ]);
                    setAddModal(false);
                    setCheckData([false, false, false, false, false]);
                    setSelectedEmail([]);
                  }}>
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default AddShare;
