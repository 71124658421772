import { FactoryTypes } from "./actionTypes";

export const getFactory = (email: string, history: any) => {
  return {
    type: FactoryTypes.GET_FACTORY,
    payload: { email, history },
  };
};

export const getFactorySuccess = (factoryList: any, history: any) => {
  return {
    type: FactoryTypes.GET_FACTORY_SUCCESS,
    payload: { factoryList, history },
  };
};

export const apiErr = (error: any) => {
  return {
    type: FactoryTypes.API_ERROR,
    payload: error,
  };
};
