import { axiosApi as axios } from "./api_helper";
import { ColumnToggle } from "react-bootstrap-table2-toolkit";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

const getFactoryList = (email: any, config: any) => {
  return axios
    .get(url.GET_FACTORY_LIST + `?owner_email=${email}&cursor=0&count=1000`, {
      ...config,
    })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    });
};

// Login Method
const postLogin = (data: any) => {
  return axios
    .post(url.POST_LOGIN, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let error = undefined;
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            error = {
              message: "User not exists",
              code: 404,
            };
            break;
          case 400:
            error = {
              message: "The password is incorrect.",
              code: 400,
            };
            break;
          case 500:
            error = {
              message: "Something went wrong, please contact our support team",
              code: 500,
            };
            break;
          case 401:
            error = {
              message: "The email verification you entered is required.",
              code: 401,
            };

            break;
          case 409:
            error = {
              message: "Login temporally unavailable.",
              code: 409,
            };
            break;
          case 429:
            error = {
              message: "The number of simultaneous logins has been exceeded.",
              code: 429,
              userList: JSON.parse(err.response.data.message).login_infos,
            };
            break;

          default:
            message = err[1];
            break;
        }
      }
      throw error;
    });
};

// Logout Method
const postLogout = (data: any) =>
  post(url.POST_LOGOUT, data, {
    headers: {
      Authorization: localStorage.getItem("access_token"),
    },
  });

// Register Method
const postRegister = (data: any) => {
  return axios
    .post(url.POST_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          case 409:
            message = "The email already exists.";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Verification Send Mail
const postVerificationSendMail = (data: any) => {
  return axios
    .post(url.POST_MAIL_VERIFICATION, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let error = undefined;
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            error = {
              message: "User not exists",
              code: 404,
            };
            break;
          case 400:
            error = {
              message: "Verification code does not match.",
              code: 400,
            };
            break;
          case 409:
            error = {
              message: "Exceed try count.",
              code: 409,
            };
            break;
          case 401:
            error = {
              message: "Authorization token is not provided",
              code: 401,
            };
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw error;
    });
};

// Verification Check Code
const postVerificationCode = (data: any) => {
  return axios
    .post(url.POST_VERIFICATION_CODE, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let error = undefined;
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            error = {
              message: "User does not exist",
              code: 404,
            };
            break;
          case 400:
            error = {
              message: "Please enter the correct email format.",
              code: 400,
            };
            break;
          case 403:
            error = {
              message: "Permission denied",
              code: 403,
            };
            break;
          case 401:
            error = {
              message: "Authorization token is not provided",
              code: 401,
            };
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw error;
    });
};
// Verification Check Code
const postSendResetCode = (data: any) => {
  return axios
    .post(url.POST_SEND_RESET_CODE, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let error = undefined;
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            error = {
              message: "User does not exist",
              code: 404,
            };
            break;

          default:
            message = err[1];
            break;
        }
      }
      throw error;
    });
};

// Verification Check Code
const postResetVerificationCode = (data: any) => {
  return axios
    .post(url.POST_RESET_VERIFICATION_CODE, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let error = undefined;
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 400:
            error = {
              message: "Verification code does not match",
              code: 400,
            };
            break;
          case 401:
            error = {
              message: "Authorization token is not provided",
              code: 401,
            };
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw error;
    });
};

// Rest Password
const putResetPassword = (data: any) => {
  return axios
    .put(url.POST_RESET_PASSWORD, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let error = undefined;
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 400:
            error = {
              message: "Verification code does not match",
              code: 400,
            };
            break;
          case 401:
            error = {
              message: "Authorization token is not provided",
              code: 401,
            };
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw error;
    });
};
// Get User
const getUser = (config: any) => {
  return axios
    .get(url.GET_USER, { ...config })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {});
};
// Put User
const putUser = (data: any, config: any) => {
  return axios
    .put(url.PUT_USER, data, { ...config })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let error = undefined;
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 400:
            error = {
              message: "Password not matched",
              code: 400,
            };
            break;
          case 401:
            error = {
              message: "Authorization token is not provided",
              code: 401,
            };
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw error;
    });
};

// Delete User
const retiredUser = (data: any, config: any) => {
  return axios
    .delete(url.RETIRED_USER, config)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let error = undefined;
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 400:
            error = {
              message: "Password not matched",
              code: 400,
            };
            break;
          case 409:
            error = {
              message: "User not found",
              code: 409,
            };
            break;
          case 401:
            error = {
              message: "Authorization token is not provided",
              code: 401,
            };
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw error;
    });
};

// Target Logout
const removeToken = (data: any, config: any) => {
  return axios
    .delete(url.REMOVETOKEN, config)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let error = undefined;
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            error = {
              message: "User not found",
              code: 404,
            };
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw error;
    });
};

export {
  postLogin,
  getFactoryList,
  postLogout,
  postRegister,
  postVerificationSendMail,
  postVerificationCode,
  postSendResetCode,
  postResetVerificationCode,
  putResetPassword,
  getUser,
  putUser,
  retiredUser,
  removeToken,
};
