import React, { useState, useEffect, useRef } from "react";
import { Card, Alert, CardBody, CardHeader, CardTitle } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { changeInfo, apiError } from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
//i18n
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
const Profiletab3 = ({ toggleTab }: any) => {
  let usehistory = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");

  const authUser: any = localStorage.getItem("authUser");
  if (!authUser) return null;
  const user = JSON.parse(authUser);

  const { error } = useSelector((state: any) => ({
    error: state.login.error,
  }));

  // handleValidSubmit
  const handleValidSubmit = (values: any) => {
    delete values.confirmpassword;
    values.name = user.name;
    values.phone_number = user.phoneNumber;
    dispatch(changeInfo(values));
  };
  useEffect(() => {
    if (error.code === 200) {
      setOldPassword("");
      setPassword("");
      setPasswordCheck("");
      const timerId = setInterval(() => {
        dispatch(
          apiError({
            message: "",
            code: 0,
          })
        );
      }, 3000);
      return () => clearInterval(timerId);
    }
  }, [error]);

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardTitle className="mb-0">{t("User Pw Change")}</CardTitle>
        </CardHeader>
        <CardBody>
          <div>
            {error && error.message && error.code !== 200 ? (
              <Alert color="warning">{error.message}</Alert>
            ) : error.code === 200 ? (
              <Alert color="success">{error.message}</Alert>
            ) : null}
            <div>
              <div className="text-muted">
                <ul className="list-unstyled mb-0">
                  <AvForm
                    className="needs-validation custom-form mt-4 pt-2"
                    onValidSubmit={(e: any, v: any) => {
                      handleValidSubmit(v);
                      e.target.reset();
                    }}
                  >
                    <li className="py-1">
                      <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                      {t("Prev Password")}{" "}
                      <div className="mb-3">
                        <AvField
                          name="old_password"
                          type="password"
                          value={oldPassword}
                          onChange={(e: any) => {
                            setOldPassword(e.target.value);
                          }}
                          placeholder={t("Prev Password Placehold")}
                          errorMessage={t("Prev Password Placehold")}
                        />
                      </div>
                    </li>
                    <li className="py-1">
                      <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                      {t("Password")}{" "}
                      <div className="mb-3">
                        <AvField
                          name="password"
                          type="password"
                          value={password}
                          onChange={(e: any) => {
                            setPassword(e.target.value);
                          }}
                          placeholder={t("Password placeholder")}
                          errorMessage={t("Password placeholder")}
                        />
                      </div>
                    </li>
                    <li className="py-1">
                      <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                      {t("Confirm Password")}{" "}
                      <div className="mb-3">
                        <AvField
                          name="confirmpassword"
                          type="password"
                          value={passwordCheck}
                          onChange={(e: any) => {
                            setPasswordCheck(e.target.value);
                          }}
                          placeholder={t("Confirm Password placeholder")}
                          validate={{
                            match: {
                              value: "password",
                              errorMessage: t("Check Password"),
                            },
                          }}
                          errorMessage={t("Confirm Password placeholder")}
                        />
                      </div>
                    </li>

                    <div className="mb-3" style={{ marginTop: "20px" }}>
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        type="submit"
                      >
                        {t("Change")}
                      </button>
                    </div>
                  </AvForm>
                </ul>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Profiletab3;
