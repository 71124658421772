import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//Import Icons
import Icon from "@ailibs/feather-react-ts";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector } from "react-redux";

const Navbar = (props: any) => {
  const { leftMenu } = useSelector((state: any) => ({
    leftMenu: state.Layout.leftMenu,
  }));
  const parseMenu = localStorage.getItem("factoryList");
  const menu = parseMenu !== null ? JSON.parse(parseMenu).factory : [];
  const [dashboard, setDashboard] = useState<boolean>(false);
  const [ui, setui] = useState<boolean>(false);
  const [app, setapp] = useState<boolean>(false);
  const [email, setemail] = useState<boolean>(false);
  const [contact, setcontact] = useState<boolean>(false);
  const [component, setcomponent] = useState<boolean>(false);
  const [form, setform] = useState<boolean>(false);
  const [table, settable] = useState<boolean>(false);
  const [chart, setchart] = useState<boolean>(false);
  const [icon, seticon] = useState<boolean>(false);
  const [map, setmap] = useState<boolean>(false);
  const [extra, setextra] = useState<boolean>(false);
  const [invoice, setinvoice] = useState<boolean>(false);
  const [auth, setauth] = useState<boolean>(false);
  const [utility, setutility] = useState<boolean>(false);
  useEffect(() => {
    var matchingMenuItem = null;
    var ul: any = document.getElementById("navigation");
    var items: any = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });
  function activateParentDropdown(item: any) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      setapp(!app);
                    }}
                    className="nav-link dropdown-togglez arrow-none"
                  >
                    <Icon name="home" />
                    <span>{props.t("Dashboard")}</span>{" "}
                  </Link>

                  <div className={classname("dropdown-menu", { show: app })}>
                    <ul className="sub-menu">
                      {menu.map((v: any, index: number) => {
                        return (
                          <li key={index} style={{ listStyle: "none" }}>
                            <Link to={`/dashboard/${v.id}`}>{v.name}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/share"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <Icon name="share-2" />
                    {props.t("Share")}
                  </Link>
                </li>{" "}
                <li className="nav-item dropdown">
                  <Link
                    to="/algorithm"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <Icon name="cpu" />
                    {props.t("Algorithm")}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/detection"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <Icon name="pie-chart" />
                    {props.t("Detection")}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/pages-faqs"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <Icon name="file-text" />
                    {props.t("FAQs")}
                  </Link>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(Navbar));
