/** @format */

import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Alert, Container } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useHistory } from "react-router-dom";

// import images
import logo from "../../assets/images/logo-sm.svg";

//i18n
import { useTranslation } from "react-i18next";

interface RegisterProps {
  history: object;
}
const Register = ({ history }: RegisterProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const usehistory = useHistory();
  const { userMail } = useSelector((state: any) => ({
    userMail: state.login.user.email,
  }));

  useEffect(() => {
    if (userMail) {
      usehistory.push("/main");
    }
  }, [userMail]);

  const { user, registrationError } = useSelector((state: any) => ({
    user: state.register.user,
    registrationError: state.register.registrationError,
    loading: state.register.loading,
  }));

  // handleValidSubmit
  const handleValidSubmit = (values: any) => {
    delete values.confirmpassword;
    dispatch(registerUser(values, history));
    dispatch(
      apiError({
        message: "",
        code: 0,
      })
    );
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Plandy</title>
      </MetaTags>
      <div className='auth-page'>
        <Container className='p-0'>
          <Row>
            <Col>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "",
                }}>
                <div style={{ width: "600px" }}>
                  <div className='auth-full-page-content d-flex p-sm-5 p-4'>
                    <div className='w-100'>
                      <div className='d-flex flex-column h-100'>
                        <div className='mb-4 mb-md-5 text-center'>
                          <Link to='/main' className='d-block auth-logo'>
                            <img src={logo} alt='' height='28' />{" "}
                            <span className='logo-txt'>Plandy</span>
                          </Link>
                        </div>
                        <div className='auth-content my-auto'>
                          <div className='text-center'>
                            <h5 className='mb-0'>Register Account</h5>
                            <p className='text-muted mt-2'>
                              Get your free Plandy account now.
                            </p>
                          </div>

                          <AvForm
                            className='needs-validation custom-form mt-4 pt-2'
                            onValidSubmit={(e: any, v: any) => {
                              handleValidSubmit(v);
                            }}>
                            {user && user ? (
                              <Alert color='success'>
                                Register User Successfully
                              </Alert>
                            ) : null}

                            {registrationError && registrationError ? (
                              <Alert color='danger'>{registrationError}</Alert>
                            ) : null}

                            <div className='mb-3'>
                              <AvField
                                id='email'
                                name='email'
                                label={t("Email")}
                                className='form-control'
                                placeholder={t("Email placeholder")}
                                type='email'
                                validate={{
                                  pattern: {
                                    value:
                                      /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/,
                                    errorMessage: t("Email check"),
                                  },
                                }}
                                errorMessage={t("Email placeholder")}
                                required
                              />
                            </div>
                            <div className='mb-3'>
                              <AvField
                                name='password'
                                label={t("Password")}
                                type='password'
                                required
                                placeholder={t("Password placeholder")}
                                errorMessage={t("Password placeholder")}
                              />
                            </div>
                            <div className='mb-3'>
                              <AvField
                                name='confirmpassword'
                                label={t("Confirm Password")}
                                type='password'
                                required
                                placeholder={t("Confirm Password placeholder")}
                                validate={{
                                  match: {
                                    value: "password",
                                    errorMessage: t("Check Password"),
                                  },
                                }}
                                errorMessage={t("Confirm Password placeholder")}
                              />
                            </div>
                            <div className='mb-3'>
                              <AvField
                                name='name'
                                label={t("User Name")}
                                type='text'
                                required
                                placeholder={t("User Name placeholder")}
                                errorMessage={t("User Name placeholder")}
                              />
                            </div>
                            <div className='mb-3'>
                              <AvField
                                name='phone_number'
                                label={t("Phone Number")}
                                type='phone'
                                required
                                placeholder={t("Phone Number placeholder")}
                                validate={{
                                  pattern: {
                                    value:
                                      /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
                                    errorMessage: t("Phone Number message"),
                                  },
                                }}
                                errorMessage={t("Phone Number empty")}
                              />
                            </div>

                            <div className='mb-3' style={{ marginTop: "30px" }}>
                              <button
                                className='btn btn-primary w-100 waves-effect waves-light'
                                type='submit'>
                                {t("Register")}
                              </button>
                            </div>
                          </AvForm>
                          <div className='mt-5 text-center'>
                            <p className='text-muted mb-0'>
                              {t("Already account")}{" "}
                              <Link
                                to='/login'
                                className='text-primary fw-semibold'>
                                {" "}
                                {t("Log In")}{" "}
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                        <div className='mt-4 mt-md-5 text-center'>
                          <div className='mb-0'>
                            <p className='mb-0'>
                              Copyright © {new Date().getFullYear()} Ijoon All
                              right reserved.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            {/* <CarouselPage /> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;
