/** @format */

import React, { memo, useEffect, useState } from "react";

import { Col, Container, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import FacCam08Chart from "./FacCam08Chart/FacCam08Chart";

const numbers = [0, "one", "two", "three", "four"];

const FacCam08 = ({ chartData, time }: any) => {
  const [series, setseries] = useState({
    one: [],
    two: [],
    three: [],
    four: [],
  });

  useEffect(() => {
    console.log("go");
    const chartLabel = Object.keys(chartData);
    const fake_series: any = { one: [], two: [], three: [], four: [] };

    for (let number = 1; number <= 4; number++) {
      for (let j = 0; j < chartLabel.length; j++) {
        const serial = chartLabel[j];

        const num = Number(serial.replace("leak", ""));
        if ((number - 1) * 6 + 1 <= num && number * 6 >= num) {
          const data = {
            name: serial.includes("leak")
              ? serial.replace("leak", "overcurrent")
              : serial,
            data: [...chartData[serial]].reverse(),
          };
          fake_series[numbers[number]].push(data);
        } else if (number * 6 < num) {
          break;
        }
      }
    }

    console.log("finish");
    setseries({ ...fake_series });
  }, [chartData]);
  return (
    <>
      <Row>
        <Col lg={6}>
          <FacCam08Chart chartData={series["one"]} time={time} number={1} />
          {/* <FacCam08Chart /> */}
        </Col>
        <Col lg={6}>
          <FacCam08Chart chartData={series["two"]} time={time} number={2} />
        </Col>
        <Col lg={6}>
          <FacCam08Chart chartData={series["three"]} time={time} number={3} />
        </Col>
        <Col lg={6}>
          <FacCam08Chart chartData={series["four"]} time={time} number={4} />
        </Col>
      </Row>
    </>
  );
};

export default FacCam08;
