/** @format */

import { Col, Row } from "reactstrap";
import LineApexChart from "../AllCharts/apex/chartapex";
import RecentActivity from "./RecentActivity";

//i18n
import { useTranslation } from "react-i18next";
import { isObject } from "lodash";
const CurrentStatus = ({ prod1, prod2, time }: any) => {
  const { t } = useTranslation();
  let prod1Avg = 0;
  let prod2Avg = 0;
  let prevProd1Avg = 0;
  let prevProd2Avg = 0;
  if (prod1 && prod2) {
    prod1.map((v: any) => {
      prod1Avg = Number.parseInt(v);
    });
    prod2.map((v: any) => {
      prod2Avg = Number.parseInt(v);
    });
    prod1Avg = Math.floor(prod1Avg / time.length);
    prod2Avg = Math.floor(prod2Avg / time.length);
    if (time.length > 1) {
      const copyProd1 = [...prod1];
      const copyProd2 = [...prod2];
      copyProd1.pop();
      copyProd2.pop();
      copyProd1.map((v: any) => {
        prevProd1Avg = Number.parseInt(v);
      });
      copyProd2.map((v: any) => {
        prevProd2Avg = Number.parseInt(v);
      });

      prevProd1Avg = Math.floor(prevProd1Avg / time.length - 1);
      prevProd2Avg = Math.floor(prevProd2Avg / time.length - 1);
    }
  }
  return (
    <>
      <div className='d-flex flex-wrap align-items-center'>
        <h5 className='card-title me-2'>{t("Production Status Trend")}</h5>
      </div>
      <Row>
        <Col xl={8}>
          <LineApexChart prod1={prod1} prod2={prod2} time={time} />
        </Col>
        <Col>
          <RecentActivity prod1={prod1} prod2={prod2} time={time} />
          {/* <p className="mb-2">{t("Average production per hour")}</p>
              <p
                className="mb-2"
                style={{
                  paddingLeft: "40px",
                }}
              >
                {t("Error Quantity")}
              </p>
            </div>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
              }}
            >
              <div>
                <p
                  className="text-muted mb-2"
                  style={{
                    fontSize: "0.675rem",
                  }}
                >
                  Prod1
                </p>
                <div style={{ display: "flex", whiteSpace: "nowrap" }}>
                  <h5>{prod1Avg}개</h5>
                  <p
                    className=" text-muted mb-2"
                    style={{
                      fontSize: "0.675rem",
                      padding: "2px",
                    }}
                  >
                    ({prod1Avg - prevProd1Avg})
                  </p>
                </div>

                <p
                  className=" text-muted mb-2"
                  style={{
                    fontSize: "0.675rem",
                  }}
                >
                  Prod2
                </p>
                <div style={{ display: "flex", whiteSpace: "nowrap" }}>
                  <h5>{prod2Avg}개</h5>
                  <p
                    className=" text-muted mb-2"
                    style={{
                      fontSize: "0.675rem",
                      padding: "2px",
                    }}
                  >
                    ({prod2Avg - prevProd2Avg})
                  </p>
                </div>
              </div>
              <div
                style={{
                  marginLeft: "77px",
                }}
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      className="text-muted"
                      style={{
                        fontSize: "0.675rem",
                        margin: "0",
                      }}
                    >
                      13:00
                    </p>
                    <p
                      className="text-muted"
                      style={{
                        fontSize: "0.675rem",
                        margin: "0",
                      }}
                    >
                      Prod1
                    </p>
                    <p
                      className="text-muted"
                      style={{
                        fontSize: "0.675rem",
                        margin: "0",
                      }}
                    >
                      10개
                    </p>
                  </div>
                  <p
                    className=" mb-2"
                    style={{
                      fontSize: "0.675rem",
                      paddingBottom: "10px",
                    }}
                  >
                    이전 시간 수량보다 작습니다.
                  </p>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      className="text-muted"
                      style={{
                        fontSize: "0.675rem",
                        margin: "0",
                      }}
                    >
                      18:00
                    </p>
                    <p
                      className="text-muted"
                      style={{
                        fontSize: "0.675rem",
                        margin: "0",
                      }}
                    >
                      Prod1
                    </p>
                    <p
                      className="text-muted"
                      style={{
                        fontSize: "0.675rem",
                        margin: "0",
                      }}
                    >
                      40개
                    </p>
                  </div>
                  <p
                    className=" mb-2"
                    style={{
                      fontSize: "0.675rem",
                      paddingBottom: "10px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    이전 시간 수량과 동일 합니다.
                  </p>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      className="text-muted"
                      style={{
                        fontSize: "0.675rem",
                        margin: "0",
                      }}
                    >
                      10:00
                    </p>
                    <p
                      className="text-muted"
                      style={{
                        fontSize: "0.675rem",
                        margin: "0",
                      }}
                    >
                      Prod1
                    </p>
                    <p
                      className="text-muted"
                      style={{
                        fontSize: "0.675rem",
                        margin: "0",
                      }}
                    >
                      1000개
                    </p>
                  </div>

                  <p
                    className="text-danger mb-2"
                    style={{
                      fontSize: "0.675rem",
                      paddingBottom: "10px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    비정상 수량으로 확인이 필요합니다.
                  </p>
                </div>
              </div> */}
        </Col>
      </Row>
    </>
  );
};

export default CurrentStatus;
