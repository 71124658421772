/** @format */

import EmptyData from "src/pages/Utility/EmptyData";
import { Table } from "reactstrap";
import { currentDate } from "src/ts/operator";

const FcailitySpinearea = ({ data }: any) => {
  return (
    <div className='table-responsive'>
      <Table className='table mb-0'>
        <thead>
          <tr>
            <th>#</th>
            <th>No</th>
            <th>Date</th>
            <th>take_time_1</th>
            <th>take_time_2</th>
            <th>take_time_3</th>
            <th>take_time_4</th>
          </tr>
        </thead>
        <tbody>
          {data ? (
            data.map((v: any, index: number) => {
              return (
                <tr key={`${v.index}_${v.date}`}>
                  <th>
                    <div className='text-danger'>
                      <i className='bx bx-error font-size-15 align-middle'></i>
                    </div>
                  </th>
                  <td scope='row'>{index + 1}</td>
                  <td>
                    {currentDate(v.date.replace(/-/g, "/")).split(" ")[1]}
                  </td>
                  <th>{v.take_time_1}</th>
                  <th>{v.take_time_2}</th>
                  <th>{v.take_time_3}</th>
                  <th>{v.take_time_4}</th>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={7}>
                <EmptyData />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default FcailitySpinearea;
