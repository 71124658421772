/** @format */

import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
// import excel from "../../assets/excel/sample.xlsx";
// import excel from "./sample.xlsx";
//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Col, Container, Row } from "reactstrap";

import InputAlgorithm from "./InputAlgorithm";
import OutputAlgorithm from "./OutputAlgorithm";
import GraphNode from "./GraphNode";

//i18n
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

const Algorithm = ({ match }: any) => {
  const { t } = useTranslation();
  const [input, setInput]: any = useState([]);
  const [output, setOutput]: any = useState([]);
  const [resultTime, setResultTime] = useState();
  const [inputNetworkNodes, setInputNetworkNodes] = useState([]);
  const [inputNetworkEdges, setInputNetworkEdges] = useState([]);

  const fileDownload = () => {
    const aTag = document.createElement("a");
    aTag.href = "../../assets/excel/sample.xlsx";
    document.body.appendChild(aTag);
    aTag.click();
    document.body.removeChild(aTag);
  };
  // Graph Data
  let inputNodesDict: any = {};

  // 출력 부분 데이터 넣기
  function completeGraph(data: any) {
    const nodes = Object.keys(data);

    const output = [...input].map((item: any, index: number) => {
      let time: any = Number(data[item.process]);
      if (data[item.process] === undefined) {
        time = "";
      } else {
        time = convertTimeFunction(time);
      }

      let confirmTime = convertTimeFunction(item.time);

      if (confirmTime === "0.000초") {
        confirmTime = "";
      }

      return {
        key: index.toString(),
        process: item.process,
        time: confirmTime,
        dependency: nodes.includes(item.process)
          ? !item.dependency
            ? "-"
            : item.dependency
          : "",
        waitingTime: time,
      };
    });
    setOutput(output);
  }
  function convertTimeFunction(value: any) {
    let time = Number(value);
    let hour = Math.floor(time / (1000 * 60 * 60));
    time = time - hour * (1000 * 60 * 60);
    let minute = Math.floor(time / (1000 * 60));
    time = time - minute * (1000 * 60);
    let second = (time / 1000).toFixed(3);

    const convertedTime = `${hour === 0 ? "" : `${hour}시간 `}${
      minute === 0 ? "" : `${minute}분 `
    }${second}초`;

    return convertedTime;
  }

  const [resultNodes, setresultNodes] = useState([]);

  const handleResultNode = (data: any) => {
    setresultNodes(data);
  };

  const [startNodes, setstartNodes] = useState([]);
  const handleStartNode = (data: any) => {
    setstartNodes(data);
  };

  const [maxNum, setmaxNum] = useState<any>(0);
  const setMaxTime = (data: any) => {
    setmaxNum(data);
  };

  const [newNetworkEdges, setnewNetworkEdges] = useState<any>([]);

  useEffect(() => {
    let arr: any = [...inputNetworkEdges];

    for (let node of startNodes) {
      arr.push({ from: node, to: "start" });
    }

    setnewNetworkEdges([...arr]);
  }, [inputNetworkEdges]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <MetaTags>
          <title>Plandy</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title='Algorithm' breadcrumbItem='Algorithm' />
          <Row>
            <Col lg={6}>
              <InputAlgorithm
                input={input}
                setInput={setInput}
                inputNodesDict={inputNodesDict}
                completeGraph={completeGraph}
                setResultTime={setResultTime}
                setOutput={setOutput}
                setInputNetworkNodes={setInputNetworkNodes}
                setInputNetworkEdges={setInputNetworkEdges}
                handleResultNode={handleResultNode}
                handleStartNode={handleStartNode}
                setMaxTime={setMaxTime}
              />
            </Col>
            <Col lg={6}>
              <OutputAlgorithm output={output} />
            </Col>
          </Row>
          {!isEmpty(output) && (
            <Row>
              <Col lg={12}>
                <GraphNode
                  resultTime={convertTimeFunction(resultTime)}
                  inputNetworkNodes={inputNetworkNodes}
                  newNetworkEdges={newNetworkEdges}
                  resultNodes={resultNodes}
                  maxNum={maxNum}
                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Algorithm;
