import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LoginTypes } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { postFakeLogin } from "../../../helpers/fakebackend_helper";
import {
  getFactoryList,
  postLogin,
  postLogout,
  postVerificationSendMail,
  postVerificationCode,
  postSendResetCode,
  postResetVerificationCode,
  putResetPassword,
  getUser,
  putUser,
  retiredUser,
  removeToken,
} from "../../../helpers/backend_helper";
import { axiosApi } from "src/helpers/api_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }: any) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response: Promise<any> = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      );
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response: Promise<any> = yield call(postLogin, {
        email: user.email,
        password: user.password,
      });
      if (response !== undefined) {
        const responseData = JSON.stringify(response);
        const obj = JSON.parse(responseData);
        localStorage.setItem(
          "access_token",
          JSON.stringify(obj.accessToken).replace(/\"/gi, "")
        );
        axiosApi.defaults.headers.common["Authorization"] = obj.accessToken;
        localStorage.setItem(
          "login_key",
          JSON.stringify(obj.loginKey).replace(/\"/gi, "")
        );
        localStorage.setItem(
          "refresh_token",
          JSON.stringify(obj.refreshToken).replace(/\"/gi, "")
        );

        const resUser: Promise<any> = yield call(getUser, {
          headers: {
            Authorization: JSON.stringify(obj.accessToken).replace(/\"/gi, ""),
          },
        });
        const data = JSON.stringify(resUser);
        const psarseUser = JSON.parse(data);

        localStorage.setItem(
          "authUser",
          JSON.stringify({
            email: psarseUser.users.email,
            name: psarseUser.users.name,
            phoneNumber: psarseUser.users.phoneNumber,
          })
        );

        yield put(
          loginSuccess({
            email: psarseUser.users.email,
            name: psarseUser.users.name,
            phoneNumber: psarseUser.users.phoneNumber,
          })
        );
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response: Promise<any> = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }

    localStorage.removeItem("temp_mail");
    history.push("/main");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }: any) {
  try {
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response: Promise<any> = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      yield call(postLogout, {
        login_key: localStorage.getItem("login_key"),
      });

      yield put(logoutUserSuccess({}));
    }
  } catch (error) {
    yield put(apiError(error));
  }
  console.log("로그아웃");
  localStorage.removeItem("access_token");
  localStorage.removeItem("login_key");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("factoryList");
  history.push("/login");
}

function* sendMail({ payload: { data, history } }: any) {
  try {
    const response: Promise<any> = yield call(postVerificationSendMail, data);
    yield put(
      apiError({
        code: 0,
        message: "",
      })
    );
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}
function* checkCode({ payload: { data } }: any) {
  try {
    const response: Promise<any> = yield call(postVerificationCode, data);
    yield put(
      apiError({
        code: 0,
        message: "",
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}
function* resetCode({ payload: { data } }: any) {
  try {
    const response: Promise<any> = yield call(postSendResetCode, data);
    yield put(
      apiError({
        code: 0,
        message: "",
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}
function* resetVerificationCode({ payload: { data } }: any) {
  try {
    const response: Promise<any> = yield call(postResetVerificationCode, data);
    yield put(
      apiError({
        code: 0,
        message: "",
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* resetPassword({ payload: { data, history } }: any) {
  try {
    const response: Promise<any> = yield call(putResetPassword, data);
    yield put(
      apiError({
        code: 0,
        message: "",
      })
    );
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* changeInfo({ payload: { data } }: any) {
  try {
    const response: Promise<any> = yield call(putUser, data, {
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    });

    const authUser: any = localStorage.getItem("authUser");
    if (!authUser) return null;
    const user = JSON.parse(authUser);
    user.name = data.name;
    user.phoneNumber = data.phone_number;

    localStorage.setItem("authUser", JSON.stringify(user));

    yield put(
      apiError({
        code: 200,
        message: "You have successfully changed your member information.",
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* deleteUser({ payload: { data, history } }: any) {
  try {
    const response: Promise<any> = yield call(retiredUser, data, {
      data: {
        password: data.password,
        token: data.token,
      },

      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    });
    console.log("탈퇴");
    localStorage.removeItem("authUser");
    localStorage.removeItem("access_token");
    localStorage.removeItem("login_key");
    localStorage.removeItem("refresh_token");
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* targetLogout({ payload: { data, history } }: any) {
  try {
    const response: Promise<any> = yield call(removeToken, data, {
      data: {
        ...data,
      },
    });
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getUserRequest({ payload: { data, history } }: any) {
  try {
    const resUser: Promise<any> = yield call(getUser, {
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    });
    const data = JSON.stringify(resUser);
    const psarseUser = JSON.parse(data);
    localStorage.setItem(
      "authUser",
      JSON.stringify({
        email: psarseUser.users.email,
        name: psarseUser.users.name,
        phoneNumber: psarseUser.users.phoneNumber,
      })
    );

    yield put(
      loginSuccess({
        email: psarseUser.users.email,
        name: psarseUser.users.name,
        phoneNumber: psarseUser.users.phoneNumber,
      })
    );
  } catch (error) {
    console.log("get user");
    localStorage.removeItem("authUser");
    localStorage.removeItem("access_token");
    localStorage.removeItem("login_key");
    localStorage.removeItem("refresh_token");
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LoginTypes.LOGIN_USER, loginUser);
  yield takeEvery(LoginTypes.LOGOUT_USER, logoutUser);
  yield takeLatest(LoginTypes.VERIFICATION_SEND_MAIL, sendMail);
  yield takeLatest(LoginTypes.VERIFICATION_CODE, checkCode);
  yield takeLatest(LoginTypes.SEND_RESET_CODE, resetCode);
  yield takeLatest(LoginTypes.RESET_VERIFICATION_CODE, resetVerificationCode);
  yield takeLatest(LoginTypes.RESET_PASSWORD, resetPassword);
  yield takeLatest(LoginTypes.PUT_USER, changeInfo);
  yield takeLatest(LoginTypes.DELETE_USER, deleteUser);
  yield takeLatest(LoginTypes.REMOVE_TOKEN, targetLogout);
  yield takeLatest(LoginTypes.GETUSER, getUserRequest);
}

export default authSaga;
