/** @format */

import React, { useState, useContext, useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

//
import { WebSocketContext } from "../../websocket/WebSocketProvider";
import { get } from "../../helpers/api_helper";
import "../../assets/scss/custom/components/_search_date.scss";
import { mergeDate, currentDate } from "../../ts/operator";
import RealTimeTable from "./RealTimeTable";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { memo } from "react";

import RealTimeChart from "./RealTimeChart";
import LineCharts from "../AllCharts/chartjs/linechart";

interface GraphSeries {
  name: string;
  label: string;
  data: {
    x: number;
    y: string;
  }[];
}

// mqtt message에서 series가 빈값으로 나와서 새로 선언해 줌
let pannelList: string[] = [];
let pannelListData: GraphSeries[] = [];

const GET_STORAGE = "/api/main/v1/storage";

const RealTimeChartTable = memo(
  ({ match, cardHeight, startComponent }: any) => {
    const { userMail } = useSelector((state: any) => ({
      userMail: state.login.user.email,
    }));

    const client = useContext(WebSocketContext);
    const [series, setSeries] = useState<GraphSeries[]>([]);

    const [latestMessage, setLatestMessage] = useState<any>({});

    const [categories, setCategories]: any = useState([]);

    const { factory_id } = match.params;
    const factoryList = localStorage.getItem("factoryList");
    const { serial } = match.params;

    const { name } = match.params;

    const history = useHistory();

    const handleDetailData = async () => {
      pannelList = [];
      pannelListData = [];
      const prev_date = currentDate(new Date().getTime() - 1000 * 60 * 5);
      const next_date = currentDate(new Date());
      //  1분전 데이터 불러오기
      try {
        const readData = await get(
          GET_STORAGE +
            `?serial=${serial}&prev_date=${prev_date}&next_date=${next_date}&interval=1&cursor=1&count=1000`
        );

        const { storageData } = readData;
        if (storageData !== undefined) {
          storageData.reverse();
          for (let item of storageData) {
            const date = item.date.replace(/-/g, "/");
            const processing: { data: string } = JSON.parse(item.processing);
            addGraphSeries(processing, new Date(date).getTime());
          }
          setSeries([...pannelListData]);
        }
        oneSubscribe(`${serial}/${name}`);
      } catch (err) {
        console.log(err);
      }
    };

    function oneSubscribe(value: string) {
      const qos = 0;
      let machine_topic = `ijoon/ncs/${value}/data`;

      client.current.subscribe(machine_topic, { qos }, (err: any, res: any) => {
        if (err) {
          console.log(machine_topic, "연결 실패", err);
        } else {
          console.log(machine_topic, "연결 성공");
        }
      });

      // 실시간 데이터 받기
      client.current.on("message", (topic: any, res: any) => {
        if (topic === `ijoon/ncs/${serial}/${name}/data`) {
          const processing: { data: string } = JSON.parse(res);
          const date = mergeDate(currentDate(new Date()), 1);
          setLatestMessage({ ...processing, date });
          addGraphSeries(processing, new Date(date).getTime());
          for (let item of pannelListData) {
            if (item.data[0] !== undefined && item.data.length) {
              // 5분 이상이면 빼기
              const frontDate = new Date(item.data[0].x).getTime();
              const backDate = new Date(date).getTime();
              if (backDate - frontDate > 1000 * 60 * 5) {
                item.data.shift();
              }
            }
          }
          setSeries([...pannelListData]);
        }
      });
    }

    // 데이터 넣어주기
    function addGraphSeries(processing: { data: string }, date: number) {
      for (let [pannelName, pannelData] of Object.entries(processing)) {
        if (pannelName.includes("set") || !pannelData) continue;
        pannelName = pannelName.includes("leak")
          ? pannelName.replace("leak", "overcurrent")
          : pannelName;
        if (!pannelList.includes(pannelName)) {
          pannelListData.push({
            name: pannelName,
            label: pannelName,
            data: [
              {
                x: date,
                y: pannelData,
              },
            ],
          });
          pannelList.push(pannelName);
        } else {
          for (let pannel of pannelListData) {
            if (pannel.name === pannelName && pannel.data.length > 0) {
              // 시간 같으면 높은 값으로 갱신
              const { x, y } = pannel.data[pannel.data.length - 1];
              if (date - x < 1000) {
                if (y < pannelData) {
                  pannel.data[pannel.data.length - 1].y = pannelData;
                }
              } else {
                pannel.data.push({
                  x: date,
                  y: pannelData,
                });
              }

              break;
            }
          }
        }
      }
    }

    useEffect(() => {
      if (!startComponent) return;
      handleDetailData();
      return () => {
        console.log("구독 해제");
        client.current.unsubscribe(`ijoon/ncs/${serial}/${name}/data`);
        client.current.unsubscribe(`ijoon/ncs/camera/#`);
        setSeries([]);
      };
    }, [startComponent]);

    const [mobile, setMobile] = useState(false);

    useEffect(() => {
      if (window.innerWidth < 768) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }, []);

    return (
      <React.Fragment>
        <div className='data'>
          <Card
            className='card-h-100'
            style={{
              overflow: "auto",
              height: mobile
                ? "auto"
                : `calc(${cardHeight}px + var(--bs-card-spacer-y) * 2 )`,
            }}>
            <CardBody>
              <RealTimeTable latestMessage={latestMessage} />
            </CardBody>
          </Card>
        </div>
        <div className='graph'>
          {serial === "fac-cam-08" ? (
            <div>
              <Row>
                <Col xs={12} md={6}>
                  <Card className='card-h-100'>
                    <CardBody>
                      <RealTimeChart
                        serial={serial}
                        series={[
                          ...series.filter((item: any) => {
                            const num = Number(
                              item.name.replace("overcurrent", "")
                            );

                            if ((1 - 1) * 6 + 1 <= num && 1 * 6 >= num) {
                              return true;
                            }
                            return false;
                          }),
                        ]}
                        number={1}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6}>
                  <Card className='card-h-100'>
                    <CardBody>
                      <RealTimeChart
                        serial={serial}
                        series={[
                          ...series.filter((item: any) => {
                            const num = Number(
                              item.name.replace("overcurrent", "")
                            );

                            if ((2 - 1) * 6 + 1 <= num && 2 * 6 >= num) {
                              return true;
                            }
                            return false;
                          }),
                        ]}
                        number={2}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Card className='card-h-100'>
                    <CardBody>
                      <RealTimeChart
                        serial={serial}
                        series={[
                          ...series.filter((item: any) => {
                            const num = Number(
                              item.name.replace("overcurrent", "")
                            );

                            if ((3 - 1) * 6 + 1 <= num && 3 * 6 >= num) {
                              return true;
                            }
                            return false;
                          }),
                        ]}
                        categories={categories}
                        number={3}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6}>
                  <Card className='card-h-100'>
                    <CardBody>
                      <RealTimeChart
                        serial={serial}
                        series={[
                          ...series.filter((item: any) => {
                            const num = Number(
                              item.name.replace("overcurrent", "")
                            );

                            if ((4 - 1) * 6 + 1 <= num && 4 * 6 >= num) {
                              return true;
                            }
                            return false;
                          }),
                        ]}
                        number={4}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col>
                <Card className='card-h-100'>
                  <CardBody>
                    <RealTimeChart serial={serial} series={series} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </React.Fragment>
    );
  }
);

export default RealTimeChartTable;
