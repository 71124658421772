/** @format */

import React, { useState, useContext, useEffect } from "react";
import mqtt from "mqtt";

//i18n
import { useTranslation } from "react-i18next";
import { Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
// import images
import factory1 from "../../assets/images/factory/factory-1.png";
import factory2 from "../../assets/images/factory/factory-2.png";
import factory3 from "../../assets/images/factory/factory-3.png";
import factory4 from "../../assets/images/factory/factory-4.png";
import factory5 from "../../assets/images/factory/factory-5.png";
import emptyImg from "../../assets/images/broke.png";
import { WebSocketContext } from "../../websocket/WebSocketProvider";
import { get } from "../../helpers/api_helper";
import { isEmpty } from "lodash";
import SearchChartTable from "./SearchChartTable";
import "../../assets/scss/custom/components/_search_date.scss";
import { mergeDate, currentDate } from "../../ts/operator";
import { Link, useLocation, useParams } from "react-router-dom";

interface GraphSeries {
  name: string;
  label: string;
  data: {
    x: number;
    y: string;
  }[];
}

interface MachineTypes {
  factory_id: string;
  id: string;
  name: string;
  note: string;
  reg_time: string;
  serial: string;
}

const factoryImgList = [factory1, factory2, factory4, factory5];

const FacilityOperation = ({ machine, machineList }: any) => {
  const { t } = useTranslation();
  // const MQTTURL = `wss://ijoon.iptime.org:18081/ws`;
  const MQTTURL = "wss://" + window.location.host + "/mqtt";
  const GET_STORAGE = "/api/main/v1/storage";
  const client = useContext(WebSocketContext);
  const [topic, setTopic] = useState("");
  const [modal, setModal] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [series, setSeries] = useState<GraphSeries[]>([]);

  const [isOpenMachine, setIsOpenMachine]: any = useState([]);
  const [categories, setCategories]: any = useState([]);
  const imgHeight = 120;

  // 정상 작동 머신 확인
  function requestOpenMachine(
    opneMachine: any,
    prev_date: any,
    next_date: any
  ) {
    const allPromise = [];
    for (let [key, value] of Object.entries<any>(machine)) {
      const data = get(
        GET_STORAGE +
          `?serial=${value.serial}&prev_date=${prev_date}&next_date=${next_date}&interval=1&cursor=1&count=100`,
        {
          headers: {
            Authorization: localStorage.getItem("access_token"),
          },
        }
      );

      allPromise.push(data);
    }
    Promise.all(allPromise).then((res) => {
      for (let data of res) {
        if (
          !isEmpty(data) &&
          data.storageData !== undefined &&
          data.storageData.length > 0
        ) {
          opneMachine.push(data.storageData[0].serial);
        }
      }
      setIsOpenMachine(opneMachine);
    });
  }

  useEffect(() => {
    if (!isEmpty(machine)) {
      const opneMachine: any = [];
      let today = new Date();
      let prev_date = `${today.getFullYear()}-${
        (today.getMonth() + 1).toString().length === 1
          ? "0" + (today.getMonth() + 1)
          : today.getMonth() + 1
      }-${
        today.getDate().toString().length === 1
          ? "0" + today.getDate()
          : today.getDate()
      } ${today.getHours()}:${today.getMinutes() - 1}:${today.getSeconds()}`;
      let next_date = `${today.getFullYear()}-${
        (today.getMonth() + 1).toString().length === 1
          ? "0" + (today.getMonth() + 1)
          : today.getMonth() + 1
      }-${
        today.getDate().toString().length === 1
          ? "0" + today.getDate()
          : today.getDate()
      } ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;

      requestOpenMachine(opneMachine, prev_date, next_date);
    }
  }, [machine]);

  function toggleSearchModal() {
    setSearchModal(!searchModal);
    setCategories([]);
    setSeries([]);
  }

  const handleSearchModal = (value: any) => {
    setSearchModal(!searchModal);
    setTopic(value);
  };

  const open = (
    <p>
      <span
        className={"badge badge-soft-success text-success"}
        style={{ fontSize: "16px" }}
      >
        {/* <i className='mdi mdi-circle align-middle font-size-16 text-success'></i>{" "}
        {t("Facility State Normal")} */}
      </span>
    </p>
  );

  const close = (
    <p>
      <span
        className={"badge badge-soft-danger text-danger"}
        style={{ fontSize: "16px" }}
      >
        {/* <i className='mdi mdi-circle align-middle font-size-16 text-danger'></i>{" "}
        {t("Facility State Anomaly")} */}
      </span>
    </p>
  );

  return (
    <>
      <Col xl={12}>
        <Row>
          {!isEmpty(machineList) ? (
            machineList.map((item: any, index: number) => (
              <Col key={machine[item]?.reg_time} lg={4}>
                <div className='text-center mb-3'>
                  {!isEmpty(isOpenMachine) &&
                  isOpenMachine.includes(machine[item]?.serial)
                    ? open
                    : close}
                  <div className='mb-3 '>
                    <img
                      src={factoryImgList[index % 4]}
                      className='machine_img'
                      alt=''
                      height={imgHeight}
                      style={{
                        filter:
                          !isEmpty(isOpenMachine) &&
                          isOpenMachine.includes(machine[item]?.serial)
                            ? "none"
                            : "grayscale(100%)",
                      }}
                      onClick={() => {
                        handleSearchModal(
                          `${machine[item].serial}/${machine[item].name}`
                        );
                      }}
                    />
                  </div>
                  <p className='text-muted font-size-15 text-center mb-0'>
                    {machine[item]?.note}
                  </p>
                  <p className='text-muted font-size-13 text-center mb-0'>
                    {machine &&
                      machine[item] &&
                      `(${machine[item].serial}/${machine[item].name})`}
                  </p>

                  {/* {!isEmpty(isOpenMachine) &&
                  isOpenMachine.includes(machine[item]?.serial) ? (
                    <Link
                      to={`/${machine[item].factory_id}/${machine[item].serial}/${machine[item].name}`}
                      className=' text-muted text-center mb-0 font-size-13'
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {t("View Realtime Data")}
                    </Link>
                  ) : (
                    ""
                  )} */}
                  <div className='d-flex  justify-content-center'>
                    <Link
                      to={`/${machine[item].factory_id}/${machine[item].serial}/${machine[item].name}`}
                      className='text-muted text-center mb-0 font-size-13 d-flex align-items-center'
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      <i className='bx bx-camera'></i>&nbsp;
                      {t("View Realtime Data")}
                    </Link>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <div className='d-flex justify-content-center'>
              <div>
                <img src={emptyImg} alt='' className='empty_img mt-5 mb-3' />
                <p className='text-center fw-bold'>{t("Machine Is Empty")}</p>
              </div>
            </div>
          )}
        </Row>
      </Col>

      <Modal
        size='xl'
        isOpen={searchModal}
        toggle={toggleSearchModal}
        className='search_modal'
      >
        <ModalHeader toggle={toggleSearchModal} tag='h4'>
          {topic}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>
              <div className=''>
                <SearchChartTable
                  GET_STORAGE={GET_STORAGE}
                  get={get}
                  serial={topic.split("/")[0]}
                />
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default FacilityOperation;
