export const COLOR = [
  "#556ee6",
  "#34c38f",
  "#e655c4",
  "#8cc334",
  "#d355e6",
  "#34c33b",
  "#556ee6",
  "#4cc334",
  "#e68d55",
  "#34c38f",
  "#5f55e6",
  "#34c38f",
  "#e67055",
  "#34c38f",
  "#e655e1",
  "#c38034",
  "#556ee6",
  "#34c38f",
  "#556ee6",
  "#34c38f",
  "#e65555",
  "#34c377",
  "#556ee6",
  "#34c38f",
  "#e655c4",
  "#8cc334",
  "#d355e6",
  "#34c33b",
  "#556ee6",
  "#4cc334",
  "#e68d55",
  "#34c38f",
  "#5f55e6",
  "#34c38f",
  "#e67055",
  "#34c38f",
  "#e655e1",
  "#c38034",
  "#556ee6",
  "#34c38f",
  "#556ee6",
  "#34c38f",
  "#e65555",
  "#34c377",
  "#556ee6",
  "#34c38f",
  "#e655c4",
  "#8cc334",
  "#d355e6",
  "#34c33b",
  "#556ee6",
  "#4cc334",
  "#e68d55",
  "#34c38f",
  "#5f55e6",
  "#34c38f",
  "#e67055",
  "#34c38f",
  "#e655e1",
  "#c38034",
  "#556ee6",
  "#34c38f",
  "#556ee6",
  "#34c38f",
  "#e65555",
  "#34c377",
  "#556ee6",
  "#34c38f",
  "#e655c4",
  "#8cc334",
  "#d355e6",
  "#34c33b",
  "#556ee6",
  "#4cc334",
  "#e68d55",
  "#34c38f",
  "#5f55e6",
  "#34c38f",
  "#e67055",
  "#34c38f",
  "#e655e1",
  "#c38034",
  "#556ee6",
  "#34c38f",
  "#556ee6",
  "#34c38f",
  "#e65555",
  "#34c377",
  "#556ee6",
  "#34c38f",
  "#e655c4",
  "#8cc334",
  "#d355e6",
  "#34c33b",
  "#556ee6",
  "#4cc334",
  "#e68d55",
  "#34c38f",
  "#5f55e6",
  "#34c38f",
  "#e67055",
  "#34c38f",
  "#e655e1",
  "#c38034",
  "#556ee6",
  "#34c38f",
  "#556ee6",
  "#34c38f",
  "#e65555",
  "#34c377",
  "#556ee6",
  "#34c38f",
  "#e655c4",
  "#8cc334",
  "#d355e6",
  "#34c33b",
  "#556ee6",
  "#4cc334",
  "#e68d55",
  "#34c38f",
  "#5f55e6",
  "#34c38f",
  "#e67055",
  "#34c38f",
  "#e655e1",
  "#c38034",
  "#556ee6",
  "#34c38f",
  "#556ee6",
  "#34c38f",
  "#e65555",
  "#34c377",
  "#556ee6",
  "#34c38f",
  "#e655c4",
  "#8cc334",
  "#d355e6",
  "#34c33b",
  "#556ee6",
  "#4cc334",
  "#e68d55",
  "#34c38f",
  "#5f55e6",
  "#34c38f",
  "#e67055",
  "#34c38f",
  "#e655e1",
  "#c38034",
  "#556ee6",
  "#34c38f",
  "#556ee6",
  "#34c38f",
  "#e65555",
  "#34c377",
  "#556ee6",
  "#34c38f",
  "#e655c4",
  "#8cc334",
  "#d355e6",
  "#34c33b",
  "#556ee6",
  "#4cc334",
  "#e68d55",
  "#34c38f",
  "#5f55e6",
  "#34c38f",
  "#e67055",
  "#34c38f",
  "#e655e1",
  "#c38034",
  "#556ee6",
  "#34c38f",
  "#556ee6",
  "#34c38f",
  "#e65555",
  "#34c377",
  "#556ee6",
  "#34c38f",
  "#e655c4",
  "#8cc334",
  "#d355e6",
  "#34c33b",
  "#556ee6",
  "#4cc334",
  "#e68d55",
  "#34c38f",
  "#5f55e6",
  "#34c38f",
  "#e67055",
  "#34c38f",
  "#e655e1",
  "#c38034",
  "#556ee6",
  "#34c38f",
  "#556ee6",
  "#34c38f",
  "#e65555",
  "#34c377",
  "#556ee6",
  "#34c38f",
  "#e655c4",
  "#8cc334",
  "#d355e6",
  "#34c33b",
  "#556ee6",
  "#4cc334",
  "#e68d55",
  "#34c38f",
  "#5f55e6",
  "#34c38f",
  "#e67055",
  "#34c38f",
  "#e655e1",
  "#c38034",
  "#556ee6",
  "#34c38f",
  "#556ee6",
  "#34c38f",
  "#e65555",
  "#34c377",
];
