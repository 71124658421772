/** @format */

import React, { useCallback, useEffect, useRef, useState } from "react";
import CameraLoading from "src/assets/images/loading.png";
import { loadPlayer } from "rtsp-relay/browser";
import { memo } from "react";
import { isEmpty } from "lodash";
import { useHistory } from "react-router";

interface CameraPlayPros {
  serial: string;
  handleHight: (data: number) => void;
  cardHeight: number;
}

let mySocket: any = null;
let setTimeoutID: any;

const CameraPlay = memo(
  ({ serial, handleHight, cardHeight }: CameraPlayPros) => {
    const canvas = useRef<HTMLCanvasElement>(null);

    const history = useHistory();

    useEffect(() => {
      setTimeoutID = setTimeout(() => {
        if (isEmpty(mySocket)) {
          const pathname = window.location.pathname.split("/");
          // console.log(pathname);
          // history.push(`/dashboard/${pathname[1]}`);
          alert("카메라 상태를 확인해 주세요.");
        }
      }, 5000);

      return () => {
        if (setTimeoutID) {
          clearTimeout(setTimeoutID);
        }
      };
    }, []);

    const reHight = useCallback(() => {
      const videoCanvas: any = document.querySelector(".video-canvas");
      if (videoCanvas) {
        handleHight(videoCanvas.clientHeight);
      }
    }, []);

    useEffect(() => {
      if (!cardHeight) {
        reHight();
      }
    }, [cardHeight]);

    const [loading, setLoading] = useState(true);

    // 카메라 소켓
    const connectWebsocket = async () => {
      if (canvas.current) {
        mySocket = await loadPlayer({
          url: "wss://" + window.location.host + `/api/stream/${serial}`,
          canvas: canvas.current,
          // videoBufferSize: 1024 * 1024 * 4,
          onDisconnect: () => {
            console.log("Connection lost!");
          },
        });

        setLoading(false);
        reHight();
      }
    };

    // 카메라 끊기
    const getInUser = useCallback(() => {
      console.log("사용자가 웹페이지에 나갔습니다.");
      if (canvas.current) {
        if (mySocket) {
          mySocket.stop();
        }
        canvas.current.style.filter = "blur(4px)";
      }
    }, []);

    const getOutUser = useCallback(() => {
      console.log("사용자가 웹페이지에 돌아왔습니다.");
      if (canvas.current) {
        if (mySocket) {
          mySocket.play();
        }
        canvas.current.style.filter = "none";
      }
    }, []);

    const resizeCanvas = () => {
      const videoCanvas: any = document.querySelector(".video-canvas");
      if (videoCanvas) {
        handleHight(videoCanvas.clientHeight);
      }
    };

    useEffect(() => {
      if (!canvas.current) return;
      connectWebsocket();

      // 반응형
      window.addEventListener("resize", resizeCanvas, true);

      // window.addEventListener("blur", getInUser, true);
      // window.addEventListener("focus", getOutUser, true);

      return () => {
        if (mySocket) {
          mySocket.destroy(); // 페이지 벗어나면 끊기
          mySocket = null;
        }
        // window.removeEventListener("blur", getInUser, true);
        // window.removeEventListener("focus", getOutUser, true);
        window.addEventListener("resize", resizeCanvas, false);
      };
    }, []);

    return (
      <div className='video-canvas'>
        <canvas
          ref={canvas}
          id={serial}
          width='1920'
          height='1080'
          style={{ width: "100%" }}
        />
        {loading && (
          <div className='spinner-container'>
            <div className='spinner'>
              <i className='fas fa-spinner fa-7x'></i>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default CameraPlay;
