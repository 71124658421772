import usFlag from "../assets/images/flags/us.jpg";
import korea from "../assets/images/flags/korea.jpg";

const languages: Object = {
  ko: {
    label: "Korea",
    flag: korea,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
};

export default languages;
