import React, { useRef, useEffect } from "react";
import mqtt from "mqtt";
const WebSocketContext = React.createContext<any>(null);
export { WebSocketContext };

export default ({ children }: { children: React.ReactNode }) => {
  // const MQTTURL = `wss://ijoon.iptime.org:18081/ws`;
  const MQTTURL = "wss://" + window.location.host + "/mqtt";

  let client = useRef<any>(null);
  useEffect(() => {
    if (!client.current) {
      client.current = mqtt.connect(MQTTURL, {
        clientId: "mqttjs_" + Math.random().toString(16).substr(2, 8),
        username: "ijoon",
        password: "9DGQhyCH6RZ4",
      });

      client.current.on("connect", () => {
        console.log("mqtt 연결 성공");
      });
    }
  }, []);

  return (
    <WebSocketContext.Provider value={client}>
      {children}
    </WebSocketContext.Provider>
  );
};
``;
