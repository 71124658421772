/** @format */

import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import axios from "axios";

const PageFaqs = (props: any) => {
  const [defaultFaqList, setDefaultFaqList]: any = useState([{}]);
  const [faqList, setFaqList]: any = useState([{}]);
  const [search, setSearch] = useState("");
  const [i18n, setI18n]: any = useState();

  const faqCnt = 4;
  let tempFaqList = Array();

  const init = () => {
    for (let i = 1; i <= faqCnt; i++) {
      tempFaqList.push({
        no: i.toString().length < 2 ? `0${i}.` : `${i}.`,
        inquiry: props.t(`Plandy Faq Inquiry${i}`),
        answer: props.t(`Plandy Faq Answer${i}`),
      });
    }

    if (search)
      setFaqList(
        tempFaqList.filter(
          (v: any) =>
            v.inquiry.indexOf(search) !== -1 || v.answer.indexOf(search) !== -1
        )
      );
    else setFaqList(tempFaqList);

    setDefaultFaqList(tempFaqList);
  };

  useEffect(() => {
    setI18n(localStorage.getItem("I18N_LANGUAGE"));
    init();
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  const handleSearch = (value: string) => {
    setFaqList(
      defaultFaqList.filter(
        (v: any) =>
          v.inquiry.indexOf(value) !== -1 || v.answer.indexOf(value) !== -1
      )
    );
    setSearch(value);
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <MetaTags>
          <title>Plandy</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title='Pages' breadcrumbItem='FAQs' />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className='justify-content-center mt-3'>
                    <div className='col-xl-5 col-lg-8'>
                      <div className='text-center'>
                        <h5> {props.t("Plandy question")} </h5>
                        <p className='text-muted'>
                          {props.t("Plandy question description1")}
                          <br />
                          {props.t("Plandy question description2")}
                        </p>
                        <div>
                          <button
                            type='button'
                            className='btn btn-primary mt-2 me-2 waves-effect waves-light'
                            onClick={(e) => {
                              window.location.href = "mailto:hws202@ijoon.net";
                              e.preventDefault();
                            }}>
                            {props.t("Email Us")}
                          </button>

                          <button
                            type='button'
                            className='btn btn-success mt-2 waves-effect waves-light'
                            onClick={(e) => {
                              let url = "/pdf/Plandy.pdf";
                              url = encodeURI(url);

                              window.open(url, "_blank");
                              e.preventDefault();
                            }}>
                            {props.t("Description Download")}
                          </button>
                        </div>

                        <Row className='justify-content-center'>
                          <div className='col-xl-10'>
                            <div className='app-search d-none d-lg-block mt-4'>
                              <div className='search-box position-relative'>
                                <input
                                  type='text'
                                  className='form-control rounded border'
                                  placeholder={props.t("Search Placeholder")}
                                  value={search}
                                  onChange={(e: any) => {
                                    handleSearch(e.target.value);
                                  }}
                                />
                                <i className='bx bx-search search-icon'></i>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </Row>

                  <Row className='mt-5'>
                    {faqList &&
                      faqList.map((v: any, index: number) => {
                        return (
                          <Col xl={4} sm={6} key={`faqList${index}`}>
                            <Card>
                              <CardBody className='overflow-hidden position-relative'>
                                <div>
                                  <i className='bx bx-help-circle widget-box-1-icon text-primary'></i>
                                </div>
                                <div className='faq-count'>
                                  <h5 className='text-primary'>{v.no}</h5>
                                </div>
                                <h5 className='mt-3'>{v.inquiry}</h5>
                                <p className='text-muted mt-3 mb-0'>
                                  {v.answer}
                                </p>
                              </CardBody>
                            </Card>
                          </Col>
                        );
                      })}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(PageFaqs);
