/** @format */

import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import AddShare from "./AddShare";
import { get, put, post, del } from "../../helpers/api_helper";
import EmptyData from "src/pages/Utility/EmptyData";
import { useSelector } from "react-redux";

// Editable
import BootstrapTable from "react-bootstrap-table-next";
import { isEmpty } from "lodash";

const ShareTable = ({ factory }: any) => {
  const [shareUser, setShareUser]: any[] = useState();
  const [addUser, setAddUser]: any[] = useState();
  const [search, setSearch] = useState("");
  const [originData, setOriginData]: any[] = useState();
  const [modal, setModal] = useState<boolean>(false);
  const [userCnt, setUserCnt] = useState(0);
  const [addModal, setAddModal] = useState<boolean>(false);
  const [selectFac, setSelectFac] = useState();
  const [selectedValue, setSelectedValue]: any = useState(
    factory ? factory[0].id : ""
  );

  const { email } = useSelector((state: any) => ({
    email: state.login.user.email,
  }));

  useEffect(() => {
    if (factory) {
      setSelectedValue(factory[0].id);
      getShareUser(factory[0].id);
    }
  }, [factory]);

  const [currentRow, setCurrentRow] = useState(0);
  const toggle = () => {
    if (modal) {
      getShareUser(selectedValue);
    }
    setModal(!modal);
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      style: {
        verticalAlign: "middle",
      },
      formatter: (id: any, value: any, index: any) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      dataField: "share_email",
      text: "Mail	",
      style: {
        verticalAlign: "middle",
      },
      formatter: (email: any, value: any, index: any) => {
        return <span>{email}</span>;
      },
    },
    {
      dataField: "share_auth.machine_create",
      text: "Create",
      formatter: (value: any) => {
        const spanStyle = value
          ? "avatar-title bg-soft-primary rounded-circle"
          : "avatar-title bg-soft-secondary rounded-circle";
        const icon = value
          ? "mdi mdi-access-point-network font-size-24 text-primary"
          : "mdi mdi-access-point-network font-size-24 text-secondary";
        return (
          <span
            className={spanStyle}
            style={{ display: "inline-block", width: "40px" }}>
            <i className={icon}></i>
          </span>
        );
      },
    },
    {
      dataField: "share_auth.machine_read",
      text: "Read",
      formatter: (value: any) => {
        const spanStyle = value
          ? "avatar-title bg-soft-primary rounded-circle"
          : "avatar-title bg-soft-secondary rounded-circle";
        const icon = value
          ? "mdi mdi-access-point-network font-size-24 text-primary"
          : "mdi mdi-access-point-network font-size-24 text-secondary";
        return (
          <span
            className={spanStyle}
            style={{ display: "inline-block", width: "40px" }}>
            <i className={icon}></i>
          </span>
        );
      },
    },
    {
      dataField: "share_auth.machine_update",
      text: "Update",
      formatter: (value: any) => {
        const spanStyle = value
          ? "avatar-title bg-soft-primary rounded-circle"
          : "avatar-title bg-soft-secondary rounded-circle";
        const icon = value
          ? "mdi mdi-access-point-network font-size-24 text-primary"
          : "mdi mdi-access-point-network font-size-24 text-secondary";
        return (
          <span
            className={spanStyle}
            style={{ display: "inline-block", width: "40px" }}>
            <i className={icon}></i>
          </span>
        );
      },
    },
    {
      dataField: "share_auth.machine_delete",
      text: "Delete",
      formatter: (value: any) => {
        const spanStyle = value
          ? "avatar-title bg-soft-primary rounded-circle"
          : "avatar-title bg-soft-secondary rounded-circle";
        const icon = value
          ? "mdi mdi-access-point-network font-size-24 text-primary"
          : "mdi mdi-access-point-network font-size-24 text-secondary";
        return (
          <span
            className={spanStyle}
            style={{ display: "inline-block", width: "40px" }}>
            <i className={icon}></i>
          </span>
        );
      },
    },
    {
      dataField: "share_auth.share_user_update",
      text: "Share User",
      formatter: (value: any) => {
        const spanStyle = value
          ? "avatar-title bg-soft-primary rounded-circle"
          : "avatar-title bg-soft-secondary rounded-circle";
        const icon = value
          ? "mdi mdi-access-point-network font-size-24 text-primary"
          : "mdi mdi-access-point-network font-size-24 text-secondary";
        return (
          <span
            className={spanStyle}
            style={{ display: "inline-block", width: "40px" }}>
            <i className={icon}></i>
          </span>
        );
      },
    },
    {
      dataField: "edit",
      text: "Edit",
      style: {
        verticalAlign: "middle",
      },
      formatter: (clicked: boolean, row: any, rowIndex: number) => {
        return (
          <div
            onClick={() => {
              setModal(true);
              setCurrentRow(rowIndex);
            }}>
            <a className='btn btn-outline-secondary btn-sm edit' title='Edit'>
              <i className='fas fa-pencil-alt' title='Edit'></i>
            </a>
          </div>
        );
      },
    },
  ];

  const handleValidUserSubmit = (values: any, id: any) => {
    const authUser: any = localStorage.getItem("authUser");
    if (authUser) {
      const obj = JSON.parse(authUser);
      const data = { ...shareUser.filter((item: any) => item.id === id) };
      put(`/api/main/v1/share`, data[0], {
        headers: {
          Authorization: localStorage.getItem("access_token"),
        },
      }).then((res) => {
        toggle();
      });
    }
  };

  const { factoryList } = useSelector((state: any) => ({
    factoryList: state.factory.factoryList,
  }));

  useEffect(() => {
    if (email !== undefined && email) {
      const factoryList = localStorage.getItem("factoryList");
      if (factoryList) {
        const obj = JSON.parse(factoryList);
        getShareUser(obj.factory[0].id);
        setSelectFac(obj.factory[0]);
      }
    }
  }, [email]);

  const handleSearch = (value: string) => {
    setSearch(value);
    setShareUser(
      originData.filter((v: any) => v.share_email.indexOf(value) !== -1)
    );
  };

  const getShareUser = (value: any) => {
    const authUser: any = localStorage.getItem("authUser");
    if (authUser) {
      const obj = JSON.parse(authUser);

      get(
        `/api/main/v1/share?owner_email=${obj.email}&factory_id=${value}&cursor=0&count=1000`
      )
        .then(async (res) => {
          if (!isEmpty(res)) {
            const userList = res.share_user.map((item: any) => {
              const share_auth = {
                machine_create: item.share_auth.machine_create
                  ? item.share_auth.machine_create
                  : false,
                machine_read: item.share_auth.machine_read
                  ? item.share_auth.machine_read
                  : false,
                machine_update: item.share_auth.machine_update
                  ? item.share_auth.machine_update
                  : false,
                machine_delete: item.share_auth.machine_delete
                  ? item.share_auth.machine_delete
                  : false,
                share_user_update: item.share_auth.share_user_update
                  ? item.share_auth.share_user_update
                  : false,
              };
              return {
                ...item,
                share_auth: {
                  ...share_auth,
                },
              };
            });

            const existUserList = [];

            for (let i = 0; i < userList.length; i++) {
              const targetUser = userList[i].share_email;
              const users = await get(`/api/auth/v1/users/${targetUser}`);

              if (users.users.length && targetUser !== email) {
                existUserList.push(userList[i]);
                // userList[i]["isExist"] = true;
              } else {
                // userList[i]["isExist"] = false;
              }
            }

            setShareUser(existUserList);
            setOriginData(existUserList);
            setUserCnt(existUserList.length);
          } else {
            setShareUser([]);
            setOriginData([]);
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  const handleAddUser = (user) => {
    setShareUser((users) => [user, ...users]);
    setOriginData((users) => [user, ...users]);
    setUserCnt((pre) => pre + 1);
  };

  const handleChange = (e: any) => {
    setSelectedValue(e.target.value);
    getShareUser(e.target.value);
    const factoryList = localStorage.getItem("factoryList");
    if (factoryList) {
      const obj = JSON.parse(factoryList);
      setSelectFac(obj.factory.find((v: any) => v.id === e.target.value));
    }
  };

  if (!isEmpty(addUser)) {
    // addUser.map((v: any) => {
    //   console.log(v);
    // });
  }

  // shareUser 유저 리스트
  // select 선택 값
  const fac = factory && factory.find((obj: any) => obj.id === selectedValue);

  return (
    <React.Fragment>
      <div>
        <div className='text-start'>
          <div className='mb-3'>
            <h5 className='card-title'>
              User List{" "}
              <span className='text-muted fw-normal ms-2'>({userCnt})</span>
            </h5>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{
              width: "200px",
              marginRight: "10px",
              padding: "calc(30px / 2) 0",
            }}>
            {factory && (
              <AvForm>
                <AvField
                  type='select'
                  name='factory'
                  value={selectedValue}
                  className='form-select'
                  onChange={handleChange}>
                  {factory.map((v: any, index: number) => {
                    return (
                      <option key={`${v.id}_${index}`} value={v.id}>
                        {v.name}
                      </option>
                    );
                  })}
                </AvField>
              </AvForm>
            )}
          </div>
          <div className='app-search' style={{ width: "250px" }}>
            <div className='search-box position-relative'>
              <input
                type='text'
                className='form-control'
                placeholder='Search...'
                value={search}
                onChange={(e: any) => {
                  handleSearch(e.target.value);
                }}
              />
              <i className='bx bx-search search-icon'></i>
            </div>
          </div>
          <div style={{ padding: "calc(30px / 2) 0px 0px 10px" }}>
            <button
              className='btn btn-primary'
              type='button'
              onClick={() => {
                setAddModal(true);
              }}>
              Add
            </button>
          </div>
        </div>
      </div>
      <div className='table-responsive'>
        {shareUser && (
          <BootstrapTable
            keyField='id'
            data={shareUser}
            columns={columns}
            noDataIndication={() => {
              return <EmptyData />;
            }}
          />
        )}
      </div>
      <AddShare
        addModal={addModal}
        setAddModal={setAddModal}
        addUser={addUser}
        selectFac={selectFac}
        setAddUser={setAddUser}
        handleAddUser={handleAddUser}
        toggle={() => setAddModal(false)}
      />

      {!isEmpty(shareUser) && !isEmpty(shareUser[currentRow]) && (
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle} tag='h4'>
            {shareUser[currentRow].share_email}
          </ModalHeader>
          <ModalBody>
            <AvForm
              onValidSubmit={(e: any, values: any) => {
                handleValidUserSubmit(values, shareUser[currentRow].id);
              }}>
              <Row form>
                <Col xs={12}>
                  <div className='mb-3'>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <h5>Create</h5>{" "}
                      <div
                        className='square-switch'
                        style={{ marginLeft: "20px" }}>
                        <Input
                          type='checkbox'
                          name='square-switch1'
                          id='square-switch1'
                          switch='none'
                          checked={
                            shareUser[currentRow].share_auth.machine_create
                          }
                          onChange={(e: any) => {
                            const updateRow = { ...shareUser[currentRow] };
                            updateRow.share_auth.machine_create =
                              !updateRow.share_auth.machine_create;

                            setShareUser(
                              shareUser.map((v: any, index: number) => {
                                return index === currentRow ? updateRow : v;
                              })
                            );
                          }}
                        />
                        <Label
                          htmlFor='square-switch1'
                          data-on-label='On'
                          data-off-label='Off'></Label>
                      </div>
                    </div>
                    <div
                      style={{
                        paddingBottom: "10px",
                        borderBottom: "1px solid #7a7fdc",
                      }}>
                      공장을 만들 수 있는 권한입니다.
                    </div>
                  </div>
                  <div className='mb-3'>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <h5>Read</h5>{" "}
                      <div
                        className='square-switch'
                        style={{ marginLeft: "20px" }}>
                        <Input
                          type='checkbox'
                          name='square-switch2'
                          checked={
                            shareUser[currentRow].share_auth.machine_read
                          }
                          id='square-switch2'
                          switch='none'
                          onChange={(e: any) => {
                            const updateRow = { ...shareUser[currentRow] };
                            updateRow.share_auth.machine_read =
                              !updateRow.share_auth.machine_read;

                            setShareUser(
                              shareUser.map((v: any, index: number) => {
                                return index === currentRow ? updateRow : v;
                              })
                            );
                          }}
                        />
                        <Label
                          htmlFor='square-switch2'
                          data-on-label='On'
                          data-off-label='Off'></Label>
                      </div>
                    </div>
                    <div
                      style={{
                        paddingBottom: "10px",
                        borderBottom: "1px solid #7a7fdc",
                      }}>
                      공장을 읽을 수 있는 권한입니다.
                    </div>
                  </div>
                  <div className='mb-3'>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <h5>Update</h5>{" "}
                      <div
                        className='square-switch'
                        style={{ marginLeft: "20px" }}>
                        <Input
                          type='checkbox'
                          id='square-switch3'
                          name='square-switch3'
                          checked={
                            shareUser[currentRow].share_auth.machine_update
                          }
                          onChange={(e: any) => {
                            const updateRow = { ...shareUser[currentRow] };
                            updateRow.share_auth.machine_update =
                              !updateRow.share_auth.machine_update;

                            setShareUser(
                              shareUser.map((v: any, index: number) => {
                                return index === currentRow ? updateRow : v;
                              })
                            );
                          }}
                          switch='none'
                        />
                        <Label
                          htmlFor='square-switch3'
                          data-on-label='On'
                          data-off-label='Off'></Label>
                      </div>
                    </div>
                    <div
                      style={{
                        paddingBottom: "10px",
                        borderBottom: "1px solid #7a7fdc",
                      }}>
                      공장을 수정할 수 있는 권한입니다.
                    </div>
                  </div>
                  <div className='mb-3'>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <h5>Delete</h5>{" "}
                      <div
                        className='square-switch'
                        style={{ marginLeft: "20px" }}>
                        <Input
                          type='checkbox'
                          name='square-switch4'
                          id='square-switch4'
                          checked={
                            shareUser[currentRow].share_auth.machine_delete
                          }
                          onChange={(e: any) => {
                            const updateRow = { ...shareUser[currentRow] };
                            updateRow.share_auth.machine_delete =
                              !updateRow.share_auth.machine_delete;

                            setShareUser(
                              shareUser.map((v: any, index: number) => {
                                return index === currentRow ? updateRow : v;
                              })
                            );
                          }}
                          switch='none'
                        />
                        <Label
                          htmlFor='square-switch4'
                          data-on-label='On'
                          data-off-label='Off'></Label>
                      </div>
                    </div>
                    <div
                      style={{
                        paddingBottom: "10px",
                        borderBottom: "1px solid #7a7fdc",
                      }}>
                      공장을 삭제할 수 있는 권한입니다.
                    </div>
                  </div>
                  <div className='mb-3'>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <h5>Share User</h5>{" "}
                      <div
                        className='square-switch'
                        style={{ marginLeft: "20px" }}>
                        <Input
                          type='checkbox'
                          id='square-switch5'
                          name='square-switch5'
                          checked={
                            shareUser[currentRow].share_auth.share_user_update
                          }
                          onChange={(e: any) => {
                            const updateRow = { ...shareUser[currentRow] };
                            updateRow.share_auth.share_user_update =
                              !updateRow.share_auth.share_user_update;

                            setShareUser(
                              shareUser.map((v: any, index: number) => {
                                return index === currentRow ? updateRow : v;
                              })
                            );
                          }}
                          switch='none'
                        />
                        <Label
                          htmlFor='square-switch5'
                          data-on-label='On'
                          data-off-label='Off'></Label>
                      </div>
                    </div>
                    <div
                      style={{
                        paddingBottom: "10px",
                        borderBottom: "1px solid #7a7fdc",
                      }}>
                      공장을 다른 사람에게 공유할 수 있는 권한입니다.
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className='text-end'>
                    <div
                      className='btn btn-danger save-user'
                      onClick={() => {
                        const id = shareUser[currentRow].id;
                        const deleteData = shareUser.filter(
                          (v: any) => v.id === id
                        );

                        del("/api/main/v1/share", {
                          data: {
                            factory_id: deleteData[0].factory_id,
                            id: deleteData[0].id,
                            owner_email: deleteData[0].owner_email,
                          },

                          headers: {
                            Authorization: localStorage.getItem("access_token"),
                          },
                        }).then(() => {
                          getShareUser(selectedValue);
                          setModal(!modal);
                        });
                      }}>
                      Delete
                    </div>{" "}
                    <button type='submit' className='btn btn-primary save-user'>
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default ShareTable;
