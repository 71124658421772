import MetaTags from "react-meta-tags";
import React, { useEffect } from "react";

import { Row, Container } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

// actions
import { loginUser, socialLogin } from "../../store/actions";

//Import config
import CarouselPage from "../AuthenticationInner/CarouselPage";

interface LoginProps {
  history: object;
}

const Main = ({ history }: LoginProps) => {
  const dispatch = useDispatch();
  const { error } = useSelector((state: any) => ({
    error: state.login.error,
  }));

  const signIn = (res: any, type: any) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, history, type));
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, history, type));
    }
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Plandy</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Main);
