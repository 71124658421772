import { LoginTypes } from "./actionTypes";

export const loginUser = (user: any, history: any) => {
  return {
    type: LoginTypes.LOGIN_USER,
    payload: { user, history },
  };
};

export const loginSuccess = ({ ...user }: any) => {
  return {
    payload: user,
    type: LoginTypes.LOGIN_SUCCESS,
  };
};

export const logoutUser = (history: any) => {
  return {
    type: LoginTypes.LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = (response: any) => {
  return {
    type: LoginTypes.LOGOUT_USER_SUCCESS,
    payload: response,
  };
};

export const apiError = (error: any) => {
  return {
    type: LoginTypes.API_ERROR,
    payload: error,
  };
};

export const socialLogin = (data: any, history: any, type: any) => {
  return {
    type: LoginTypes.SOCIAL_LOGIN,
    payload: { data, history, type },
  };
};

export const verificationSendMail = (data: any, history: any) => {
  return {
    type: LoginTypes.VERIFICATION_SEND_MAIL,
    payload: { data, history },
  };
};

export const verificationCode = (data: any) => {
  return {
    type: LoginTypes.VERIFICATION_CODE,
    payload: { data },
  };
};

export const sendResetCode = (data: any) => {
  return {
    type: LoginTypes.SEND_RESET_CODE,
    payload: { data },
  };
};

export const resetVerificationCode = (data: any) => {
  return {
    type: LoginTypes.RESET_VERIFICATION_CODE,
    payload: { data },
  };
};

export const resetPassword = (data: any, history: any) => {
  return {
    type: LoginTypes.RESET_PASSWORD,
    payload: { data, history },
  };
};

export const changeInfo = (data: any) => {
  return {
    type: LoginTypes.PUT_USER,
    payload: { data },
  };
};

export const retiredUser = (data: any, history: any) => {
  return {
    type: LoginTypes.DELETE_USER,
    payload: { data, history },
  };
};

export const removeToken = (data: any, history: any) => {
  return {
    type: LoginTypes.REMOVE_TOKEN,
    payload: { data, history },
  };
};

export const getUser = () => {
  return {
    type: LoginTypes.GETUSER,
    payload: {},
  };
};
