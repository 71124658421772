import { LoginTypes } from "./actionTypes";

const initialState = {
  error: {
    message: "",
    code: 0,
    userList: {},
  },
  loading: false,
  user: {},
};

const login = (state = initialState, action: any) => {
  switch (action.type) {
    case LoginTypes.LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LoginTypes.LOGIN_SUCCESS:
      state = {
        ...state,
        user: { ...action.payload },
        loading: false,
        error: {
          message: "",
          code: 0,
          userList: {},
        },
      };
      break;
    case LoginTypes.LOGOUT_USER:
      state = { ...state };
      break;
    case LoginTypes.LOGOUT_USER_SUCCESS:
      state = {
        ...state,
        user: {
          email: "",
          name: "",
          phoneNumber: "",
        },
      };
      break;
    case LoginTypes.API_ERROR:
      state = { ...state, error: { ...action.payload }, loading: false };
      break;
    case LoginTypes.VERIFICATION_SEND_MAIL:
      state = { ...state, loading: true };
      break;
    case LoginTypes.VERIFICATION_CODE:
      state = { ...state, loading: true };
      break;
    case LoginTypes.SEND_RESET_CODE:
      state = { ...state, loading: true };
      break;
    case LoginTypes.RESET_VERIFICATION_CODE:
      state = { ...state, loading: true };
      break;
    case LoginTypes.RESET_PASSWORD:
      state = { ...state, loading: true };
      break;
    case LoginTypes.GET_USER:
      state = { ...state, loading: true };
      break;
    case LoginTypes.PUT_USER:
      state = { ...state, loading: true };
      break;
    case LoginTypes.DELETE_USER:
      state = {
        ...state,
        loading: true,
        user: {
          email: "",
          name: "",
          phoneNumber: "",
        },
      };
      break;
    case LoginTypes.REMOVE_TOKEN:
      state = { ...state, loading: true };
      break;
    case LoginTypes.GETUSER:
      state = {
        ...state,
        user: { ...action.payload },
        loading: false,
        error: {
          message: "",
          code: 0,
          userList: {},
        },
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
