/** @format */

import React from "react";
import { Card, CardBody } from "reactstrap";

//i18n
import { useTranslation } from "react-i18next";

//SimpleBar
import SimpleBar from "simplebar-react";

const RecentActivity = ({ prod1, prod2, time }: any) => {
  const { t } = useTranslation();
  const errorList = [];
  for (let i = time.length; i > 0; i--) {
    if (i > 0) {
      if (Number.parseInt(prod1[i]) === 0) {
        // danger
        const etc = {
          time: time[i],
          type: "danger",
          prod: "prod1",
          describe: `${t("Abnormal Quantity Need Confirm")}`,
        };
        errorList.push(etc);
      } else {
        if (Number.parseInt(prod1[i]) === Number.parseInt(prod1[i - 1])) {
          // warning
          const etc = {
            time: time[i],
            type: "warning",
            prod: "prod1",
            describe: `${t("Same Previous Quantity")}`,
          };
          errorList.push(etc);
        }
        if (Number.parseInt(prod1[i]) < Number.parseInt(prod1[i - 1])) {
          // warning
          const etc = {
            time: time[i],
            type: "warning",
            prod: "prod1",
            describe: `${t("Less Previous Quantity")}`,
          };
          errorList.push(etc);
        }
      }

      if (Number.parseInt(prod2[i]) === 0) {
        // danger
        const etc = {
          time: time[i],
          type: "danger",
          prod: "prod2",
          describe: `${t("Abnormal Quantity Need Confirm")}`,
        };
        errorList.push(etc);
      } else {
        if (Number.parseInt(prod2[i]) === Number.parseInt(prod2[i - 1])) {
          // warning
          const etc = {
            time: time[i],
            type: "warning",
            prod: "prod2",
            describe: `${t("Same Previous Quantity")}`,
          };
        }
        if (Number.parseInt(prod2[i]) < Number.parseInt(prod2[i - 1])) {
          // warning

          const etc = {
            time: time[i],
            type: "warning",
            prod: "prod2",
            describe: `${t("Less Previous Quantity")}`,
          };
          errorList.push(etc);
        }
      }
    }
  }
  let today = new Date();
  let date = `${today.getFullYear()}-${(
    "00" + (today.getMonth() + 1).toString()
  ).slice(-2)}-${("00" + today.getDate().toString()).slice(-2)}`;

  return (
    <React.Fragment>
      <Card style={{ marginBottom: 0 }}>
        <CardBody className='px-0'>
          <SimpleBar className='px-3' style={{ height: "292px" }}>
            <ul className='list-unstyled activity-wid mb-0'>
              {errorList &&
                errorList.map((v: any, index: number) => {
                  return (
                    <li
                      key={v.time + index}
                      className='activity-list activity-border'>
                      <div className='activity-icon avatar-md'>
                        <span
                          className='avatar-title text-warning rounded-circle'
                          style={{
                            backgroundColor:
                              v.prod === "prod1" ? "#556ee6" : "#34c38f",
                          }}>
                          <i className='text-white font-size-13'>{v.prod}</i>
                        </span>
                      </div>
                      <div className='timeline-list-item'>
                        <div className='d-flex'>
                          <div className='flex-grow-1 overflow-hidden me-4'>
                            <h5 className='font-size-15 mb-1'>
                              {date + " " + v.time}
                            </h5>
                            <p className='text-truncate text-muted font-size-14'>
                              {v.describe}
                            </p>
                          </div>
                          <div className='flex-shrink-0 text-end me-3 mt-3'>
                            {v.type === "warning" ? (
                              <h3 className='text-warning mb-1'>
                                <i className='mdi mdi-alarm-light-outline'></i>
                              </h3>
                            ) : (
                              <h3 className='text-danger mb-1'>
                                <i className='mdi mdi-alarm-light'></i>
                              </h3>
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </SimpleBar>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default RecentActivity;
