/** @format */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Card, CardBody, Row } from "reactstrap";
import ApexRadial from "./ApexRadial";

//i18n
import { useTranslation } from "react-i18next";

interface InvestedOverviewProps {
  title: string;
  percent: number;
  goal: string;
  prevProd: string;
  prod: string;
  totalPercent: number;
  prevPsb: undefined | [];
}

const InvestedOverview = ({
  title,
  goal,
  prod,
  percent,
  prevProd,
  totalPercent = 0,
  prevPsb,
}: InvestedOverviewProps) => {
  const [current, setCurrent] = useState<any>();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col>
        <Card className='card-h-100'>
          <CardBody>
            <div className='d-flex flex-wrap align-items-center justify-content-between'>
              <h5 className='card-title me-2'>{title}</h5>
              <p className=' me-2 mt-0 mb-0'>
                {(prevPsb && percent !== Infinity && !Number.isNaN(percent)) ||
                prevProd
                  ? ""
                  : `* ${t("No Previous Data")}`}
              </p>
            </div>

            <Row className='align-items-center'>
              <div className='col-lg'>
                <div id='invested-overview' className='apex-charts'>
                  <ApexRadial percent={totalPercent} />
                </div>
              </div>
              <div className='col-sm align-self-center'>
                <div className='mt-4 mt-sm-0'>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}>
                    <div>
                      <p className='mb-2'>{t("Target quantity")}</p>
                      <h3 className='text-center'>
                        {goal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </h3>
                    </div>
                    <div>
                      <p className='mb-2'>{t("Production Quantity")}</p>
                      <h3 className='text-center'>
                        {prod.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </h3>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "space-around",
                    }}>
                    <div>
                      <p className='mb-2'>{t("Same time the day before")}</p>
                      <h3 style={{ textAlign: "center" }}>
                        {percent !== Infinity && !Number.isNaN(percent)
                          ? `${percent} %`
                          : "-"}

                        {percent !== Infinity && !Number.isNaN(percent) ? (
                          percent === 0 ? (
                            ""
                          ) : percent >= 0 ? (
                            <i className='mdi mdi-arrow-up ms-1 text-success'></i>
                          ) : (
                            <i className='mdi mdi-arrow-down ms-1 text-danger'></i>
                          )
                        ) : (
                          ""
                        )}
                      </h3>
                    </div>
                    <div>
                      <p className='mb-2'> {t("Previous day's production")}</p>
                      <h3 style={{ textAlign: "center" }}>
                        {prevProd
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default InvestedOverview;
