/** @format */

import EmptyData from "src/pages/Utility/EmptyData";
import { Table, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { memo, useState } from "react";
import { isEmpty } from "lodash";

const SearchTable = memo(({ chartData, time }: any) => {
  const labels = Object.keys(chartData);
  const datasets = labels.map((item) => {
    const data = [...chartData[item]];
    return data.reverse();
  });

  // pageNumber : 현재 페이지
  // pageIndexRow: 페이지 당 row 수
  // totalPage:  전체 페이지 수
  // pageIndex page의 pageNumber '<' '>'
  const [pageNumber, setPageNumber] = useState(1);
  const pageIndexRow = 5;
  const pageIndex = 5;
  // 나머지 있으면 페이지 하나 더 있어야함
  const totalPage = datasets[0]
    ? Math.floor(datasets[0].length / pageIndexRow) +
      (datasets[0].length % pageIndexRow ? 1 : 0)
    : 0;
  const [nextPage, setNextPage] = useState(1);

  return (
    <div className='table-responsive mt-2'>
      <div style={{ overflow: "auto" }}>
        <Table className='table mb-0'>
          <thead>
            <tr>
              {!isEmpty(labels) && <th>#</th>}
              <th className='text-center'>
                <div style={{ width: "200px", display: "inline-block" }}>
                  Date
                </div>
              </th>
              {labels.map((item) => (
                <th className='text-center' key={item}>
                  {item.includes("leak")
                    ? item.replace("leak", "overcurrent")
                    : item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* index, page: row  */}
            {datasets[0] ? (
              datasets[0]
                .filter(
                  (_, row) =>
                    row >= (pageNumber - 1) * pageIndexRow &&
                    row < pageNumber * pageIndexRow
                )
                .map((_: any, pageRow: number) => {
                  const index = pageRow + (pageNumber - 1) * pageIndexRow;
                  const rowData = datasets.map((item) => item[index]);
                  return (
                    <tr key={`${index}_${time[index]}`}>
                      <th style={{ width: "30px", display: "inline-block" }}>
                        {index + 1}
                      </th>
                      <th className='text-center'>
                        <div
                          style={{ width: "200px", display: "inline-block" }}>
                          {time[index]}
                        </div>
                      </th>
                      {rowData.map((item, idx) => (
                        <th
                          className='text-center'
                          key={`${labels[idx]}_${time[index]}_${item}`}>
                          {item}
                        </th>
                      ))}
                    </tr>
                  );
                })
            ) : (
              <tr>
                <td colSpan={2} className='text-center'>
                  No data
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <br />
      <div className='d-flex justify-content-end'>
        <Pagination aria-label='Page navigation example'>
          <PaginationItem
            onClick={() => {
              if (nextPage !== 1) {
                setNextPage(nextPage - 1);
                // 0 이하고 내려가면 안됨
                if (pageNumber - pageIndex < 0) {
                  setPageNumber(1);
                } else {
                  setPageNumber((nextPage - 2) * pageIndex + 1);
                }
              }
            }}>
            <PaginationLink previous href='' />
          </PaginationItem>
          {totalPage
            ? [...Array(totalPage)]
                .filter((_, index) => {
                  if (Math.floor(index / pageIndex) === nextPage - 1) {
                    return true;
                  }
                  return false;
                })
                .map((_, index) => (
                  <PaginationItem
                    key={`${totalPage}_${index}`}
                    active={
                      index + (nextPage - 1) * pageIndex + 1 === pageNumber
                    }>
                    <PaginationLink
                      onClick={() =>
                        setPageNumber(index + (nextPage - 1) * pageIndex + 1)
                      }
                      href=''>
                      {index + (nextPage - 1) * pageIndex + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))
            : ""}
          <PaginationItem
            onClick={() => {
              if (nextPage !== totalPage) {
                // total보다 위로 가면 안됨
                if (pageNumber + pageIndex > totalPage) {
                  if (nextPage * pageIndex + 1 > totalPage) {
                    // 현재 페이지 + (페이지)의 다음페이지가 넘었을 떄
                    setPageNumber(pageNumber);
                  } else {
                    setPageNumber(nextPage * pageIndex + 1);
                    setNextPage(nextPage + 1);
                  }
                } else {
                  setNextPage(nextPage + 1);
                  setPageNumber(nextPage * pageIndex + 1);
                }
              }
            }}>
            <PaginationLink next href='' />
          </PaginationItem>
        </Pagination>
      </div>
    </div>
  );
});

export default SearchTable;
