import { MachineTypes } from "./actionTypes";

const initData: any = {
  error: {
    message: "",
    code: 0,
  },
  targetMachine: "",
};

const machine = (state = initData, action: any) => {
  switch (action.type) {
    case MachineTypes.SET_TARGET_MACHINE:
      state = {
        ...state,
        targetMachine: action.payload.targetMachine,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default machine;
