/** @format */

import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { get } from "src/helpers/api_helper";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import Facility from "./Facility";
import Press from "./Press";

import pDataJson from "../../mock/press.json";
import octDataJson from "../../mock/oct.json";

//i18n
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import DetectionChart from "./DetectionChart";
import { useRef } from "react";

const Detection = ({ match }: any) => {
  const [pData, setPData]: any[] = useState();
  const [fData, setFData]: any[] = useState();

  let today = new Date();

  let prev_date = `${today.getFullYear()}-${
    (today.getMonth() + 1).toString().length === 1
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1
  }-${
    today.getDate().toString().length === 1
      ? "0" + today.getDate()
      : today.getDate()
  }`;

  const [pressDate, setPressDate] = useState(prev_date.replace(/-/g, "/"));
  const [octDate, setOctDate] = useState(prev_date.replace(/-/g, "/"));

  useEffect(() => {
    //
    get(`/api/main/v1/press_dection?date=${pressDate.replace(/\//g, "-")}`, {
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    }).then((res) => {
      if (!isEmpty(res)) {
        setPData(res.pressDetectionData);
      } else {
        setPData();
      }
    });
  }, [pressDate]);

  useEffect(() => {
    // api/main/v1/press_dection?date=2022-06-02 프레스 1호기
    // api/main/v1/oct_dection?date=2022-06-01 과전류시험설비

    get(`/api/main/v1/oct_dection?date=${octDate.replace(/\//g, "-")}`, {
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    }).then((res) => {
      if (!isEmpty(res)) {
        setFData(res.octDetectionData);
      } else {
        setFData();
      }
    });
  }, [octDate]);

  const [chartSize, setChartSize] = useState(500);
  const refChart = useRef<any>(null);

  useEffect(() => {
    if (refChart.current) {
      setChartSize(refChart.current.clientHeight);
    }
  }, [refChart]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <MetaTags>
          <title>Plandy</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title='Detection' breadcrumbItem='Detection' />
          <Row>
            <Col lg={7}>
              <Press
                data={pData}
                pressDate={pressDate}
                setPressDate={setPressDate}
                chartSize={chartSize}
              />
            </Col>
            <Col lg={5}>
              <div ref={refChart}>
                <DetectionChart
                  data={pData ? pData.reverse() : []}
                  title={"프레스 1호기"}
                />
              </div>
            </Col>
            <Col lg={7}>
              <Facility
                chartSize={chartSize}
                data={fData}
                octDate={octDate}
                setOctDate={setOctDate}
              />
            </Col>
            <Col lg={5}>
              <div>
                <DetectionChart
                  data={fData ? fData.reverse() : []}
                  title={"과전류시험설비"}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Detection;
