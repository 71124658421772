import React, { useState } from "react";
import EmptyData from "src/pages/Utility/EmptyData";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Alert,
  Row,
} from "reactstrap";
import { COLOR } from "../../mock/COLOR";

//i18n
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { emit } from "process";

const InputAlgorithm = ({
  input,
  setInput,
  inputNodesDict,
  completeGraph,
  setResultTime,
  setOutput,
  setInputNetworkNodes,
  setInputNetworkEdges,
  handleResultNode,
  handleStartNode,
  setMaxTime,
}: any) => {
  const { t } = useTranslation();
  const [alert, setAlert] = useState("");

  let inputNodes = Object.keys(inputNodesDict); // 프로세스(노드) 전체 담기
  let sortNodes: any = []; // node 순서 (경로)
  let visitedNodes: any = {}; //방문체크
  let visitedCount: any = { end: 0 }; // 방문체크 (최대길이)
  let resultNodes: any = {}; // 결과 (최적시간이 같은 경로가 여러개 일 수 있음)
  inputNodes.push("start");
  inputNodes.push("end");
  let maxNodeValue = 0;
  const dfsValue: any = [];
  let index = 1;
  // startNodes = start와 연결될 node
  // endNodes = end와 연결될 node
  let startNodes: any = ["p1", "p2", "p3", "p6", "p8"];
  let endNodes: any = [];

  const handleAddRow = () => {
    const addField = {
      key: input.length.toString(),
      process: "",
      time: "",
      dependency: "",
    };
    setInput([...input, addField]);
  };

  const handleDeleteRow = (index: number) => {
    let data = [...input]
      .filter((item, idx) => {
        return idx != index;
      })
      .map((item, idx) => ({ ...item, key: idx.toString() }));
    setInput(data);
  };

  const handleRowValueChange = (index: number, event: any) => {
    let data = [...input];
    data[index][event.target.name] = event.target.value;
    setInput(data);
  };

  const handleSample = (e: any) => {
    setInput([
      {
        key: "0",
        process: "p1",
        time: "2000",
        dependency: "",
      },
      {
        key: "1",
        process: "p2",
        time: "1000",
        dependency: "",
      },
      {
        key: "2",
        process: "p3",
        time: "1500",
        dependency: "",
      },
      {
        key: "3",
        process: "p4",
        time: "500",
        dependency: "p2/p3",
      },
      {
        key: "4",
        process: "p5",
        time: "3000",
        dependency: "p1/p4",
      },
      {
        key: "5",
        process: "p6",
        time: "500",
        dependency: "",
      },
      {
        key: "6",
        process: "p7",
        time: "800",
        dependency: "p6/p8",
      },
      {
        key: "7",
        process: "p8",
        time: "700",
        dependency: "",
      },
      {
        key: "8",
        process: "p9",
        time: "1000",
        dependency: "p5/p7",
      },
    ]);

    setOutput([
      {
        key: "0",
        process: "p1",
        time: "2.000초",
        dependency: "-",
        waitingTime: "0.000초",
      },
      {
        key: "1",
        process: "p2",
        time: "0.000초",
        dependency: "-",
        waitingTime: "0.000초",
      },
      {
        key: "2",
        process: "p3",
        time: "1.500초",
        dependency: "-",
        waitingTime: "0.000초",
      },
      {
        key: "3",
        process: "p4",
        time: "0.500초",
        dependency: "p2/p3",
        waitingTime: "1.500초",
      },
      {
        key: "4",
        process: "p5",
        time: "3.000초",
        dependency: "p1/p4",
        waitingTime: "2.000초",
      },
      {
        key: "5",
        process: "p6",
        time: "0.500초",
        dependency: "-",
        waitingTime: "0.000초",
      },
      {
        key: "6",
        process: "p7",
        time: "0.800초",
        dependency: "p6/p8",
        waitingTime: "0.700초",
      },
      {
        key: "7",
        process: "p8",
        time: "0.700초",
        dependency: "-",
        waitingTime: "0.000초",
      },
      {
        key: "8",
        process: "p9",
        time: "1.000초",
        dependency: "p5/p7",
        waitingTime: "5.000초",
      },
    ]);

    setInputNetworkNodes([
      {
        id: "p1",
        label: "p1",
        color: "#f1791d",
      },
      {
        id: "p2",
        label: "p2",
        color: "#bff11d",
      },
      {
        id: "p3",
        label: "p3",
        color: "#1db8f1",
      },
      {
        id: "p4",
        label: "p4",
        color: "#f52e2e",
      },
      {
        id: "p5",
        label: "p5",
        color: "#f1df1d",
      },
      {
        id: "p6",
        label: "p6",
        color: "#601df1",
      },
      {
        id: "p7",
        label: "p7",
        color: "#f1721d",
      },
      {
        id: "p8",
        label: "p8",
        color: "#441df1",
      },
      {
        id: "p9",
        label: "p9",
        color: "#1ddff1",
      },
    ]);

    setInputNetworkEdges([
      {
        from: "p4",
        to: "p2",
        label: "1초",
      },
      {
        from: "p4",
        to: "p3",
        label: "1.5초",
      },
      {
        from: "p5",
        to: "p1",
        label: "2초",
      },
      {
        from: "p5",
        to: "p4",
        label: "0.5초",
      },
      {
        from: "p7",
        to: "p6",
        label: "0.5초",
      },
      {
        from: "p7",
        to: "p8",
        label: "0.7초",
      },
      {
        from: "p9",
        to: "p5",
        label: "0.3초",
      },
      {
        from: "p9",
        to: "p7",
        label: "0.8초",
      },
      {
        from: "end",
        to: "p9",
        label: "1초",
      },
    ]);
    setResultTime(6000);
    setMaxTime(6000);
    handleResultNode({
      "6000": [
        ["p1", "p5", "p9", "end", 0, 0, 0, 0, 0],
        ["p3", "p4", "p5", "p9", "end", 0, 0, 0, 0],
      ],
    });
    handleStartNode(startNodes);
  };

  const handleInit = (e: any) => {
    setInput([]);
    setOutput([]);
  };

  const handleConversion = () => {
    for (let i = 0; i < input.length; i++) {
      const item = input[i];
      if (
        (!item.process && item.time) ||
        (item.process && !item.time) ||
        (item.dependency && !item.process) ||
        (item.dependency && !item.time)
      ) {
        // 사용자가 제대로 입력하지 않았을 때
        setAlert(`${i + 1}번째 컬럼의 데이터를 채워주세요.`);
        return;
      }

      // 빈값 pass
      if (
        !item.process.trim() &&
        !item.time.trim() &&
        !item.dependency.trim()
      ) {
        continue;
      }

      if (Number(item.time) === 0) {
        setAlert(`검사시간은 0이 될 수 없습니다.(${i + 1}번)`);

        return;
      }

      const time = Number(item.time);

      try {
        item.dependency.split("/");
      } catch {
        setAlert(`${i + 1}번째 종속성을 형식에 맞게 입력해 주세요.`);
      }

      // p1/p2/ 이렇게 입력되는거 방지
      const nodes = item.dependency.length
        ? item.dependency.split("/").filter((item: any) => item.length)
        : [];

      // 프로세스 이름이 같게 입력했는데 검사시간 다르게 입력하게 되면 오류 출력
      if (
        inputNodesDict[item.process] !== undefined &&
        inputNodesDict[item.process].time !== time
      ) {
        setAlert(
          `${item.process}가 중복으로 입력되었지만 검사시간이 서로 다르게 입력되었습니다.`
        );

        inputNodesDict = {};
        return;
      }

      // 넣기
      if (inputNodesDict[item.process] === undefined) {
        inputNodesDict[item.process] = {
          time,
          nodes,
        };
      } else {
        // 프로세스 중복 입력시 종속성만 추가하기 (중복 종속성 입력시 하나만 입력 => 중복 제거)
        const data = [...inputNodesDict[item.process].nodes, ...nodes];
        inputNodesDict[item.process].nodes = data.filter((element, index) => {
          return data.indexOf(element) === index;
        });
      }
    }
    inputNodes = Object.keys(inputNodesDict);
    // startNodes = start와 연결될 node
    // endNodes = end와 연결될 node
    startNodes = inputNodes.filter(
      (item) => item !== "end" && item !== "start"
    );
    endNodes = inputNodes.filter((item) => item !== "end" && item !== "start");

    for (let i = 0; i < inputNodes.length + 1; i++) {
      if (inputNodes[i] === undefined) break;
      sortNodes.push(0);
      visitedCount[inputNodes[i]] = 0;
      visitedNodes[inputNodes[i]] = 0;
    }

    // 연결 노드 정리 (시작노드, 끝 노드, 가중치)
    // startNodes => 종속성을 작성하지 않은 node를 startNode라고 했음
    // endNodes => 작성된 종속성 중에서 (종속성에 작성된 모든 노드들 중에서) 없는 것들을 endNode라고 생각함
    for (let [key, processValue] of Object.entries<any>(inputNodesDict)) {
      if (processValue.nodes.length) {
        // 종속성 있다면
        let nodeError = false;

        processValue.nodes.forEach((node: any) => {
          try {
            dfsValue.push([node, key, inputNodesDict[node].time]);
          } catch {
            setAlert(`${node}의 대한 정보가 없습니다.`);
            nodeError = true;
          }
          endNodes = endNodes.filter((item: any) => item !== node);
        });
        if (nodeError) {
          return;
        }
        startNodes = startNodes.filter((item: any) => item !== key);
      }
    }

    // 끝 연결
    for (let key of endNodes) {
      dfsValue.push([key, "end", inputNodesDict[key].time]);
    }

    // start 노드가 있을 때 없을 때 나눠서
    if (startNodes.length) {
      for (let node of startNodes) {
        for (let key of sortNodes) {
          sortNodes[key] = 0;
        }
        sortNodes[0] = node;
        visitedNodes[node] = 1;
        dfs2(node);
        visitedNodes[node] = 0;
      }
      handleResultNode(resultNodes);
      handleStartNode(startNodes);
    } else {
      // 없으면
      for (let item of dfsValue) {
        if (maxNodeValue < Number(item[2])) {
          maxNodeValue = Number(item[2]);
        }
      }
      inputNodes.pop();
      inputNodes.pop();
    }

    // // 그림 만들기 전에 노드 정보 모아둔 것
    setInputNetworkNodes(
      inputNodes.map((item, index) => {
        if (item === "start" || item === "end") {
          return { id: item, label: item, color: COLOR[index] };
        }
        return { id: item, label: item, color: COLOR[index] };
      })
    );

    // // 그림 만들기 전에 연결선 모아둔 것
    setInputNetworkEdges(
      dfsValue.map((item: any) => {
        const data = {
          // from: item[0],
          // to: item[1],
          from: item[1],
          to: item[0],
          label: item[2] / 1000 + "초",
        };
        return data;
      })
    );

    setAlert("");
    completeGraph(visitedCount);
    setResultTime(maxNodeValue);
    setMaxTime(maxNodeValue);
  };

  function dfs2(node: any) {
    for (let i = 0; i < dfsValue.length; i++) {
      // 이전 끝노드와 지금 시작노드가 같고 방문체크 안했으면 (방문 안했고 연결 되어있다면)
      if (dfsValue[i][0] === node && !visitedNodes[dfsValue[i][1]]) {
        // 지금 꺼낸 노드가 시작 노드
        if (
          visitedCount[dfsValue[i][1]] <=
          visitedCount[dfsValue[i][0]] + dfsValue[i][2] // 다음 길이보다 크면
        ) {
          visitedNodes[dfsValue[i][1]] = 1; // 방문 체크
          visitedCount[dfsValue[i][1]] =
            visitedCount[dfsValue[i][0]] + dfsValue[i][2]; // 기록 갱신
          sortNodes[index] = dfsValue[i][1];
          index++;

          if (maxNodeValue < visitedCount[dfsValue[i][1]]) {
            // 최대 길이 갱신하면 순서 생신
            maxNodeValue = visitedCount[dfsValue[i][1]];
            resultNodes[maxNodeValue] = [[...sortNodes]];
          } else if (maxNodeValue === visitedCount[dfsValue[i][1]]) {
            // 최대 길이랑 같으면 순서 추가
            resultNodes[maxNodeValue].push([...sortNodes]);
          }
          dfs2(dfsValue[i][1]); // 연결된 다음 노드 반복
          index--;
          sortNodes[index] = 0;
          visitedNodes[dfsValue[i][1]] = 0;
        }
      }
    }
  }
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <h5 className="font-size-16 text-muted text-truncate mn-0">
            {t("Input")}
          </h5>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row className="justify-content-center">
          <div className="col-xl-12 col-lg-8">
            <form onSubmit={handleConversion}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "7px",
                }}
              >
                <div style={{ marginRight: "50px" }}>
                  {!isEmpty(alert) ? (
                    <Alert color="warning">{alert}</Alert>
                  ) : null}
                </div>
                <div>
                  <div
                    onClick={handleSample}
                    className="btn btn-soft-primary waves-effect waves-light "
                    style={{ marginRight: "5px" }}
                  >
                    SAMPLE
                  </div>
                  <div
                    onClick={handleInit}
                    className="btn btn-soft-primary waves-effect waves-light "
                    style={{ marginRight: "5px" }}
                  >
                    {t("Initialization")}
                  </div>
                </div>
                <div>
                  <div
                    onClick={handleConversion}
                    className="btn btn-soft-success waves-effect waves-light"
                  >
                    {t("Conversion")}
                  </div>
                </div>
              </div>
              <table className="table table-bordered mb-0 table-centered">
                <tbody>
                  <tr>
                    <th style={{ width: "20%" }}>
                      <div className="py-3 text-center">
                        <h5 className="font-size-14 mb-0">
                          {t("Process Name")}
                        </h5>
                      </div>
                    </th>
                    <th style={{ width: "20%" }}>
                      <div className="py-3 text-center">
                        <h5 className="font-size-14 mb-0">
                          {t("Algorithm Time")}
                        </h5>
                      </div>
                    </th>
                    <th style={{ width: "20%" }}>
                      <div className="py-3 text-center">
                        <h5 className="font-size-14 mb-0">
                          {t("Dependencies")}
                        </h5>
                      </div>
                    </th>
                    <th style={{ width: "20%" }}>
                      <div className="py-3 text-center">
                        <h5 className="font-size-14 mb-0">{t("Delete")}</h5>
                      </div>
                    </th>
                  </tr>
                  {input && input.length > 0 ? (
                    input.map((input: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td style={{ width: "20%" }}>
                            <div className="text-center">
                              <input
                                className="form-control"
                                name="process"
                                placeholder="p1"
                                value={input.process}
                                onChange={(event) =>
                                  handleRowValueChange(index, event)
                                }
                              />
                            </div>
                          </td>
                          <td style={{ width: "20%" }}>
                            <div className="text-center">
                              <input
                                className="form-control"
                                name="time"
                                placeholder="1000"
                                value={input.time}
                                onChange={(event) =>
                                  handleRowValueChange(index, event)
                                }
                              />
                            </div>
                          </td>
                          <td style={{ width: "20%" }}>
                            <div className="text-center">
                              <input
                                className="form-control"
                                name="dependency"
                                placeholder="p2/p3"
                                value={input.dependency}
                                onChange={(event) =>
                                  handleRowValueChange(index, event)
                                }
                              />
                            </div>
                          </td>
                          <td style={{ width: "20%" }}>
                            <div className="text-center">
                              <div
                                onClick={() => {
                                  handleDeleteRow(index);
                                }}
                                className="btn btn-warning waves-effect waves-light"
                              >
                                <i className="mdi mdi-trash-can d-block font-size-14"></i>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4}>
                        <EmptyData />
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th colSpan={4} scope="row">
                      <div className="text-center">
                        <div
                          className="btn btn-soft-primary waves-effect waves-light"
                          onClick={handleAddRow}
                        >
                          Add row
                        </div>
                      </div>
                    </th>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

export default InputAlgorithm;
