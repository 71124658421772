/** @format */

import { Oval } from "react-loader-spinner";
import React, { useEffect, useState } from "react";

import {
  Switch,
  BrowserRouter as Router,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

// import calender css
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import "moment/locale/ko";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

//redux
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "./store/actions";
import { get } from "./helpers/api_helper";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/index";
import NonAuthLayout from "./components/NonAuthLayout";
import WebSocketProvider from "./websocket/WebSocketProvider";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";

// import axios
import { axiosApi } from "./helpers/api_helper";
import { isEmpty } from "lodash";
import { getUser, getFactory } from "./store/actions";
import { Route } from "react-router-dom";
import Error404 from "./pages/Utility/Error404";

import { setLoading } from "src/store/actions";

interface LogoutProps {
  history: any;
}

let subscribers: any[] = [];
let isAlreadyFetchingAccessToken = false;
let initAxios = false;

function addSubscriber(callback: any) {
  subscribers.push(callback);
}

function onAccessTokenFetched(token: any) {
  subscribers.forEach((callback) => callback(token));
  subscribers = [];
}

const App = () => {
  const { layoutType, userMail } = useSelector((state: any) => ({
    layoutType: state.Layout.layoutType,
    userMail: state.login.user.email,
  }));

  const history = useHistory();

  // 공장 리스트 가져오기
  useEffect(() => {
    if (!isEmpty(userMail)) {
      dispatch(getFactory(userMail, history));
    }
  }, [userMail]);

  function getLayout() {
    let layoutCls: Object = VerticalLayout;
    switch (layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const accessToken = localStorage.getItem("access_token");

  const dispatch = useDispatch();

  // useEffect(() => {
  if (!initAxios) {
    initAxios = true;
    axiosApi.interceptors.request.use(
      (config) => {
        dispatch(setLoading(true));
        return config;
      },
      (error) => {
        dispatch(setLoading(false));
        return Promise.reject(error);
      }
    );

    axiosApi.interceptors.response.use(
      (response: any) => {
        dispatch(setLoading(false));

        return response;
      },
      async (err: any) => {
        try {
          const {
            config,
            response: { status },
          } = err;

          if (status > 499) {
            localStorage.removeItem("access_token");
            localStorage.removeItem("login_key");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("factoryList");
            window.location.replace("/login");
            dispatch(setLoading(false));
            return Promise.reject(err);
          }

          const reqURL = config.url;
          const reqMethod = config.method;
          const refreshToken = localStorage.getItem("refresh_token");

          if (
            status === 401 &&
            refreshToken !== null &&
            !(reqURL === "/api/auth/v1/access-token" && reqMethod === "get")
          ) {
            try {
              const { response: errorResponse } = err;

              const retryOriginalRequest = new Promise((resolve, reject) => {
                addSubscriber(async (token: any) => {
                  axiosApi.defaults.headers.common["Authorization"] = token;
                  config.headers.Authorization = token;
                  try {
                    resolve(axiosApi(errorResponse.config));
                  } catch (err) {
                    reject(err);
                  }
                });
              });
              // refresh token을 이용해서 access token 요청
              if (!isAlreadyFetchingAccessToken) {
                isAlreadyFetchingAccessToken = true; // 문닫기 (한 번만 요청)
                const res: any = await get(`/api/auth/v1/access-token`, {
                  headers: {
                    Authorization: localStorage.getItem("refresh_token"),
                  },
                });

                if (!isEmpty(res)) {
                  localStorage.setItem("access_token", res.accessToken);
                }
                axiosApi.defaults.headers.common["Authorization"] =
                  res.accessToken;
                config.headers.Authorization = res.accessToken;
                isAlreadyFetchingAccessToken = false; // 문열기 (초기화)
                onAccessTokenFetched(res.accessToken);
              }
              return retryOriginalRequest; // pending 됐다가 onAccessTokenFetched가 호출될 때 resolve
            } catch (error) {
              dispatch(setLoading(false));

              return Promise.reject(error);
            }
          } else if (
            status === 401 &&
            reqURL === "/api/auth/v1/access-token" &&
            reqMethod === "get"
          ) {
            console.log("만료");
            dispatch(logoutUser(history));
          }
          dispatch(setLoading(false));

          return Promise.reject(err);
        } catch (err) {
          dispatch(setLoading(false));

          return Promise.reject(err);
        }
      }
    );
  }
  // }, []);

  const Layout = getLayout();

  const { isLoading } = useSelector((state: any) => ({
    isLoading: state.Layout.isLoading,
  }));

  useEffect(() => {
    if (accessToken !== null) {
      dispatch(getUser());
    } else {
      const path = window.location.pathname;

      const auth = [
        "/login",
        "/logout",
        "/register",
        "/page-recoverpw",
        "page-email-verification",
      ];

      if (!auth.includes(path)) {
        window.location.replace("/login");
      }
    }
  }, []);
  return (
    <React.Fragment>
      {isLoading && (
        <div className='loading'>
          <div className='loading-child'>
            <Oval
              height={100}
              width={100}
              color='#4fa94d'
              wrapperStyle={{}}
              wrapperClass=''
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor='#4fa94d'
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        </div>
      )}
      <WebSocketProvider>
        <Router>
          <Switch>
            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {userRoutes.map((route: any, idx: number) => {
              return (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              );
            })}

            <Route component={Error404} />
          </Switch>
        </Router>
      </WebSocketProvider>
    </React.Fragment>
  );
};

export default App;
