import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Container, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { get } from "../../../helpers/api_helper";

//import components
import ProfileTab from "./ProfileTab";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { isEmpty } from "lodash";

const ContactsProfile = () => {
  const [infos, setInfos] = useState();
  useEffect(() => {
    getInfos();
  }, []);

  const { infoList } = useSelector((state: any) => ({
    infoList: state.login.user,
  }));

  const getInfos = () => {
    const authUser: any = localStorage.getItem("authUser");
    if (authUser) {
      const obj = JSON.parse(authUser);
      //Request URL: api/auth/v1/logininfo

      get(`/api/auth/v1/logininfo`, {
        headers: {
          Authorization: localStorage.getItem("access_token"),
        },
      })
        .then((res) => {
          if (!isEmpty(res)) setInfos(res.infos);
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status === 401) {
            get(`/api/auth/v1/access-token`, {
              headers: {
                Authorization: localStorage.getItem("refresh_token"),
              },
            }).then((res) => {
              if (!isEmpty(res)) {
                localStorage.setItem("access_token", res.accessToken);
                // location.reload();
              }
            });
          }
        });
    }
  };
  return (
    <React.Fragment>
      <div className='page-content'>
        <MetaTags>
          <title>Plandy</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title='Contacts' breadcrumbItem='Profile' />

          <Row>
            {/* Render profilemenu */}
            <ProfileTab infos={infos} getInfos={getInfos} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContactsProfile;
