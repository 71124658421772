/** @format */

import React, { memo, useEffect, useState } from "react";

import ReactApexChart from "react-apexcharts";

const FacCam08Chart = ({ chartData, time, number }: any) => {
  const [series, setSeries] = useState<any>([]);

  // useEffect(() => {
  //   const chartLabel = Object.keys(chartData);
  //   setSeries(
  //     chartLabel
  //       .filter((serial: any) => {
  //         const num = Number(serial.replace("leak", ""));
  //         if ((number - 1) * 6 + 1 <= num && number * 6 >= num) {
  //           return true;
  //         }
  //         return false;
  //       })
  //       .map((item: any) => {
  //         return {
  //           name: item.includes("leak")
  //             ? item.replace("leak", "overcurrent")
  //             : item,
  //           data: [...chartData[item]].reverse(),
  //         };
  //       })
  //   );
  // }, [chartData]);

  const options: any = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      events: {},
    },
    title: {
      text: `Time Counter ${number}`,
      align: "left",
    },

    grid: {
      row: { colors: ["transparent", "transparent"], opacity: 0.2 },
    },
    markers: { style: "inverted", size: 0 },
    xaxis: {
      categories: time,
      title: { text: "Time" },
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      width: 2,
      dashArray: 0,
    },
  };

  return (
    <>
      <ReactApexChart
        options={options}
        series={chartData}
        type='line'
        height='350'
      />
    </>
  );
};

export default FacCam08Chart;
