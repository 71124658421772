import React from "react";

const EmptyData = () => {
  return (
    <React.Fragment>
      <div className="text-center">
        <i className="dripicons-document-new font-size-20 text-muted text-truncate mn-0"></i>
        <br />
        No data
      </div>
    </React.Fragment>
  );
};

export default EmptyData;
