/** @format */

import React, { memo, useEffect, useState } from "react";

import ReactApexChart from "react-apexcharts";

const SearchChartApex = memo(({ chartData, time }: any) => {
  const [series, setSeries] = useState<any>([]);

  useEffect(() => {
    const chartLabel = Object.keys(chartData);
    setSeries(
      chartLabel.map((item: any) => {
        return {
          name: item.includes("leak")
            ? item.replace("leak", "overcurrent")
            : item,
          data: [...chartData[item]].reverse(),
        };
      })
    );
  }, [chartData]);

  function chartMoveEvent(chartContext: any, config: any) {}

  const options: any = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      height: 600,
      events: {
        updated: chartMoveEvent,
      },
    },
    title: {
      text: "Search Chart",
      align: "left",
    },

    grid: {
      row: { colors: ["transparent", "transparent"], opacity: 0.2 },
    },
    markers: { style: "inverted", size: 0 },
    xaxis: {
      categories: time,
      title: { text: "Time" },
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      width: 2,
      dashArray: 0,
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type='line'
      height='350'
    />
  );
});

export default SearchChartApex;
