import EmptyData from "src/pages/Utility/EmptyData";

import { Card, CardBody, CardHeader, CardTitle, Row } from "reactstrap";

//i18n
import { useTranslation } from "react-i18next";

const OutputAlgorithm = ({ output }: any) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <h5 className="font-size-16 text-muted text-truncate mn-0">
            {t("Output")}
          </h5>
          <div></div>
        </CardTitle>
      </CardHeader>
      <CardBody className="mt-5 mb-5">
        <Row className="justify-content-center">
          <div className="col-xl-12 col-lg-8">
            <table className="table table-bordered mb-0 table-centered">
              <tbody>
                <tr>
                  <th style={{ width: "20%" }}>
                    <div className="py-3 text-center">
                      <h5 className="font-size-14 mb-0">{t("Process Name")}</h5>
                    </div>
                  </th>
                  <th style={{ width: "20%" }}>
                    <div className="py-3 text-center">
                      <h5 className="font-size-14 mb-0">
                        {t("Algorithm Time")}
                      </h5>
                    </div>
                  </th>
                  <th style={{ width: "20%" }}>
                    <div className="py-3 text-center">
                      <h5 className="font-size-14 mb-0">{t("Dependencies")}</h5>
                    </div>
                  </th>
                  <th style={{ width: "20%" }}>
                    <div className="py-3 text-center">
                      <h5 className="font-size-14 mb-0">{t("Waiting time")}</h5>
                    </div>
                  </th>
                </tr>
                {output && output.length > 0 ? (
                  output.map((item: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td style={{ width: "20%" }}>
                          <div className="text-center">{item.process}</div>
                        </td>
                        <td style={{ width: "20%" }}>
                          <div className="text-center">{item.time}</div>
                        </td>
                        <td style={{ width: "20%" }}>
                          <div className="text-center">
                            {item.dependency && item.dependency
                              ? item.dependency
                              : "-"}
                          </div>
                        </td>
                        <td style={{ width: "20%" }}>
                          <div className="text-center">{item.waitingTime}</div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <EmptyData />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

export default OutputAlgorithm;
