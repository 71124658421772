import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { FactoryTypes } from "./actionTypes";
import { getFactory, apiErr, getFactorySuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFactoryList } from "../../helpers/backend_helper";
import { isEmpty } from "lodash";

function* requestGetFactory({ payload: { email, history } }: any) {
  try {
    const list: Promise<any> = yield call(getFactoryList, email, {
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    });

    if (!isEmpty(list)) {
      localStorage.setItem("factoryList", JSON.stringify(list));
      yield put(getFactorySuccess(list, history));
    } else {
      yield put(getFactorySuccess([], history));
    }
  } catch (error) {
    yield put(apiErr(error));
  }
}

function* factorySaga() {
  yield takeEvery(FactoryTypes.GET_FACTORY, requestGetFactory);
}

export default factorySaga;
