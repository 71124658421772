import React from "react";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { useTranslation } from "react-i18next";
import { Row, Col, Alert } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import code from "../../mock/code.json";

// actions
import {
  apiError,
  verificationSendMail as actionsMailCheck,
  verificationCode as actionsCode,
} from "../../store/actions";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

//import images
import logo from "../../assets/images/logo-sm.svg";

interface EmailVerificationProps {
  history: object;
}
const EmailVerification = ({ history }: EmailVerificationProps) => {
  const { t } = useTranslation();
  const mail =
    localStorage.getItem("temp_mail") === null
      ? "example@abc.com"
      : localStorage.getItem("temp_mail");
  const dispatch = useDispatch();
  const { error } = useSelector((state: any) => ({
    error: state.login.error,
  }));

  const handleValidSubmit = (event: any, values: any) => {
    const data = { code: values.code, email: mail };
    dispatch(actionsMailCheck(data, history));
    dispatch(
      apiError({
        message: "",
        code: 0,
      })
    );
  };

  const verificationCode = () => {
    const data = {
      body: code.body,
      email: mail,
      from: code.from,
      contentType: code.contentType,
      charset: code.charset,
      subject: code.subject,
    };
    dispatch(actionsCode(data));
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Plandy</title>
      </MetaTags>
      <div className="auth-page" style={{ overflowX: "hidden" }}>
        <Row>
          <Col>
            <div className="auth-full-page-content d-flex p-sm-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5 text-center">
                    <Link to="/main" className="d-block auth-logo">
                      <img src={logo} alt="" height="28" />{" "}
                      <span className="logo-txt">Plandy</span>
                    </Link>
                  </div>
                  <div className="auth-content my-auto">
                    <div className="text-center">
                      <div className="avatar-lg mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bxs-envelope h2 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <p
                        style={{
                          display: "inline-block",
                          width: "300px",
                          marginTop: "20px",
                        }}
                      >
                        {error && error.message ? (
                          <Alert color="warning">{error.message}</Alert>
                        ) : null}
                      </p>
                      <div className="text-center">
                        <h4>{t("Verify your email")}</h4>
                        <p>
                          {t("Verify Message")}{" "}
                          <span className="fw-bold">{mail}</span>
                        </p>
                        <p>{t("Verify Message2")}</p>

                        <AvForm
                          className="custom-form mt-4 pt-2"
                          onValidSubmit={(e: any, v: any) => {
                            handleValidSubmit(e, v);
                          }}
                        >
                          <div>
                            <AvField
                              style={{ width: "300px", display: "inline" }}
                              name="code"
                              className="form-control"
                              placeholder={t("Verify Message4")}
                              errorMessage={t("Verify Message4")}
                              validate={{
                                pattern: {
                                  value: /\d/,
                                  errorMessage: t("Verify Message5"),
                                },
                              }}
                              type="text"
                              required
                            />
                          </div>
                          <div className="mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-sm waves-effect waves-light"
                            >
                              {t("Verify Btn")}
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </div>

                    <div className="mt-5 text-center">
                      <div className="text-muted mb-0">
                        {t("Verify Message3")}{" "}
                        <p
                          className="text-primary fw-semibold"
                          style={{ display: "inline-block", cursor: "pointer" }}
                          onClick={() => {
                            verificationCode();
                          }}
                        >
                          {" "}
                          {t("Resend")}{" "}
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0">
                      Copyright © {new Date().getFullYear()} Ijoon All right
                      reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default EmailVerification;
