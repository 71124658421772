/** @format */

import React, { memo, useState } from "react";

import { Card, CardBody, CardHeader, CardTitle, Row } from "reactstrap";

//i18n
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import FcailitySpinearea from "./FcailitySpinearea";

import ReactDatetime from "react-datetime";
import { currentDateNoTime } from "src/ts/operator";

const Facility = memo(({ data, octDate, setOctDate, chartSize }: any) => {
  const { t } = useTranslation();

  return (
    <Card style={{ height: `${chartSize}px`, overflow: "auto" }}>
      <CardHeader>
        <CardTitle>
          <div className='d-flex justify-content-between align-items-center'>
            <h5 className='font-size-16 mn-0'>
              과전류시험설비
              <span className='text-muted fw-normal ms-2'>
                ({data?.length})
              </span>
            </h5>
            <ReactDatetime
              inputProps={{
                placeholder: "Date Picker Here",
              }}
              timeFormat={false}
              className={"calender"}
              dateFormat={"YYYY/MM/DD"}
              value={octDate}
              closeOnSelect={true}
              onChange={(e: any) => setOctDate(currentDateNoTime(e._d))}
            />
          </div>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row className='justify-content-center'>
          <div className='col-xl-12 col-lg-8'>
            <FcailitySpinearea data={data} />
          </div>
        </Row>
      </CardBody>
    </Card>
  );
});

export default Facility;
