/** @format */

import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";

import { Row, Col, Alert, Container, Spinner } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import code from "../../mock/code.json";

// actions
import {
  loginUser,
  apiError,
  verificationCode as actionsCode,
} from "../../store/actions";

// import images
import logo from "../../assets/images/logo-sm.svg";

//i18n
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// user modal
import UserLogoutModal from "../AuthenticationInner/UserLogoutModal";

interface LoginProps {
  history: object;
}

const Login = ({ history }: LoginProps) => {
  let usehistory = useHistory();

  const { userMail } = useSelector((state: any) => ({
    userMail: state.login.user.email,
  }));

  useEffect(() => {
    if (userMail) {
      usehistory.push("/main");
    }
  }, [userMail]);

  const [email, setEmail] = useState(
    localStorage.getItem("isCheck") !== null
      ? localStorage.getItem("isCheck")
      : ""
  );
  const [isCheck, setIsCheck] = useState(
    localStorage.getItem("isCheck") !== null ? true : false
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let { error, user, loginUserList } = useSelector((state: any) => ({
    error: state.login.error,
    user: state.login.user,
    loginUserList: state.login.error.userList,
  }));
  // 5명 로그인 모달 열기
  const [isOpen, setIsOpen] = useState<boolean>(false);
  function closeModal() {
    setIsOpen(false);
    loginUserList = {};
  }

  useEffect(() => {
    if (error.code === 429) {
      setIsOpen(true);
    }
  }, [error]);

  useEffect(() => {
    if (isCheck && email !== null) {
      localStorage.setItem("isCheck", email);
    } else {
      localStorage.removeItem("isCheck");
    }
  }, [isCheck]);
  // handleValidSubmit
  const [userInputInfo, setUserInputInfo] = useState({});
  const handleValidSubmit = (event: any, values: any) => {
    setUserInputInfo(values);
    dispatch(loginUser(values, history));
    if (isCheck && email !== null) {
      localStorage.setItem("isCheck", email);
    }
  };
  return (
    <React.Fragment>
      <MetaTags>
        <title>Plandy</title>
      </MetaTags>
      <div className='auth-page '>
        <Container className='p-0'>
          <Row>
            <Col>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "100px",
                }}
              >
                <div style={{ width: "600px" }}>
                  <div className='w-100'>
                    <div className='d-flex flex-column h-100'>
                      <div className='mb-4 mb-md-5 text-center'>
                        <Link to='/main' className='d-block auth-logo'>
                          <img src={logo} alt='' height='28' />{" "}
                          <span className='logo-txt'>Plandy</span>
                        </Link>
                      </div>
                      <div
                        className='auth-content my-auto'
                        style={{ padding: "10px" }}
                      >
                        <div className='text-center'>
                          <h5 className='mb-0'>Welcome Back !</h5>
                          <p className='text-muted mt-2'>
                            Sign in to continue to Plandy.
                          </p>
                        </div>
                        <AvForm
                          className='custom-form mt-4 pt-2'
                          onValidSubmit={(e: any, v: any) => {
                            localStorage.setItem("temp_mail", v.email);
                            handleValidSubmit(e, v);
                          }}
                        >
                          {error && error.message ? (
                            <Alert color='warning'>
                              {error.message}
                              {error.code === 401 ? (
                                <div
                                  style={{
                                    float: "right",
                                    marginTop: "-3px",
                                  }}
                                >
                                  <button
                                    type='button'
                                    className='btn btn-soft-secondary btn-sm waves-effect waves-light'
                                    onClick={() => {
                                      const data = {
                                        body: code.body,
                                        email:
                                          localStorage.getItem("temp_mail"),
                                        from: code.from,
                                        contentType: code.contentType,
                                        charset: code.charset,
                                        subject: code.subject,
                                      };
                                      dispatch(actionsCode(data));
                                      usehistory.push(
                                        "/page-email-verification"
                                      );

                                      dispatch(
                                        apiError({
                                          message: "",
                                          code: 0,
                                        })
                                      );
                                    }}
                                  >
                                    인증하기
                                  </button>
                                </div>
                              ) : null}
                            </Alert>
                          ) : null}
                          <div className='mb-3'>
                            <AvField
                              name='email'
                              value={email}
                              onChange={(event: any) =>
                                setEmail(event.target.value)
                              }
                              label={t("Email")}
                              className='form-control'
                              placeholder={t("Email placeholder")}
                              validate={{
                                pattern: {
                                  value:
                                    /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/,
                                  errorMessage: t("Email check"),
                                },
                              }}
                              errorMessage={t("Email placeholder")}
                              type='email'
                              required
                            />
                          </div>
                          <div className='mb-3'>
                            <AvField
                              name='password'
                              label={t("Password")}
                              type='password'
                              required
                              placeholder={t("Password placeholder")}
                              errorMessage={t("Password placeholder")}
                            />
                          </div>
                          <div className='row mb-4'>
                            <div className='col'>
                              <div
                                className='form-check'
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    checked={isCheck}
                                    onChange={() => {
                                      setIsCheck(!isCheck);
                                    }}
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor='remember-check'
                                    onClick={() => {
                                      setIsCheck(!isCheck);
                                    }}
                                  >
                                    Remember me
                                  </label>
                                </div>

                                <div className=''>
                                  <Link
                                    to='/page-recoverpw'
                                    className='text-muted'
                                  >
                                    {t("Forget password")}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='mb-3'>
                            <button
                              className='btn btn-primary w-100 waves-effect waves-light'
                              type='submit'
                            >
                              {t("Log In")}
                            </button>
                          </div>
                        </AvForm>

                        <div className='mt-5 text-center'>
                          <p className='text-muted mb-0'>
                            {t("Dont Account")}{" "}
                            <Link
                              to='/register'
                              className='text-primary fw-semibold'
                            >
                              {" "}
                              {t("Signup")}{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                      <div className='mt-4 mt-md-5 text-center'>
                        <p className='mb-0'>
                          Copyright © {new Date().getFullYear()} Ijoon All right
                          reserved.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            {/* <CarouselPage /> */}
          </Row>
        </Container>
      </div>
      <UserLogoutModal
        isOpen={isOpen}
        closeModal={closeModal}
        loginUserList={loginUserList}
        userInputInfo={userInputInfo}
      />
    </React.Fragment>
  );
};

export default withRouter(Login);
