/** @format */

import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { MachineTypes } from "./actionTypes";

const setTargetMachine = ({ payload: { serial } }: any) => {};

function* machineSaga() {
  yield takeEvery(MachineTypes.SET_TARGET_MACHINE, setTargetMachine);
}

export default machineSaga;
