/** @format */

import { Alert } from "reactstrap";
import React, { useState, useEffect, memo } from "react";
import { mergeDate, currentDateNoTime, currentTime } from "src/ts/operator";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import { Row, Col, InputGroup } from "reactstrap";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

const optionMulti = [
  { label: "1초", value: 1 },
  { label: "10초 마다", value: 10 },
  { label: "30초 마다", value: 20 },
  { label: "1분 마다", value: 40 },
  { label: "10분 마다", value: 300 },
  { label: "30분 마다", value: 1200 },
  { label: "1시간 마다", value: 2400 },
];

const constrainText: any = {
  2400: "1시간은 최대 7일 동안의 데이터를 확인할 수 있습니다.",
  1200: "30분은 최대 5일 동안의 데이터를 확인할 수 있습니다.",
  300: "10분은 최대 2일 동안의 데이터를 확인할 수 있습니다.",
  40: "1분은 최대 5시간 동안의 데이터를 확인할 수 있습니다.",
  20: "30초는 최대 3시간 동안의 데이터를 확인할 수 있습니다.",
  10: "10초는 최대 1시간 동안의 데이터를 확인할 수 있습니다.",
  1: "1초은 최대 5분 동안의 데이터를 확인할 수 있습니다.",
};

const constrainChartX: any = {
  2400: 1000 * 60 * 60 * 24 * 7,
  1200: 1000 * 60 * 60 * 24 * 5,
  300: 1000 * 60 * 60 * 24 * 2,
  40: 1000 * 60 * 60 * 5,
  20: 1000 * 60 * 60 * 3,
  10: 1000 * 60 * 60 * 1,
  1: 1000 * 60 * 5,
};

function completeZero(value: any) {
  return (value < 10 ? "0" : "") + value;
}

const DateTimeFields = ({ requestStorageData }: any) => {
  const [interval, setInterval] = useState(1);
  function handleInterval(data: any) {
    setInterval(data.value);
  }

  const now = new Date(new Date().getTime() - constrainChartX[interval]);

  const [preDate, setpreDate] = useState({
    date: `${now.getFullYear()}/${completeZero(
      now.getMonth() + 1
    )}/${completeZero(now.getDate())}`,
    time: `${completeZero(now.getHours())}:${completeZero(now.getMinutes())}`,
  });

  // pre_date 고르면 자동 next_date 구하기 (구해진 next_date 이상 시간 검색 불가)
  function constrainDateTime(isDate: boolean, data: any) {
    if (isDate) {
      if (300 <= interval) {
        const currentTime = new Date(
          `${preDate.date.replace(/-/g, "/")}`
        ).getTime();
        const newTime = currentTime + constrainChartX[interval];
        const newDate = new Date(newTime);
        return `${newDate.getFullYear()}/${completeZero(
          newDate.getMonth() + 1
        )}/${completeZero(newDate.getDate())}`;
      } else {
        return data;
      }
    } else {
      const currentTime = new Date(
        `${preDate.date.replace(/-/g, "/")} ${data}`
      ).getTime();
      const newTime = currentTime + constrainChartX[interval];
      const newDate = new Date(newTime);
      return `${completeZero(newDate.getHours())}:${completeZero(
        newDate.getMinutes()
      )}`;
    }
  }

  const [secondDate, setSecondDate] = useState({
    date: constrainDateTime(true, preDate.date),
    time: constrainDateTime(false, preDate.time),
  });

  useEffect(() => {
    setSecondDate({
      date: constrainDateTime(true, preDate.date),
      time: constrainDateTime(false, preDate.time),
    });
  }, [preDate]);

  // 시간 선택
  function changeDateTime(isFirst: boolean, isDate: boolean, value: any) {
    if (isFirst) {
      if (isDate) {
        setpreDate({
          ...preDate,
          date: value,
        });
      } else {
        setpreDate({
          ...preDate,
          time: value,
        });
      }
    } else {
      if (isDate) {
        setSecondDate({
          ...secondDate,
          date: value,
        });
      } else {
        setSecondDate({
          ...secondDate,
          time: value,
        });
      }
    }
  }

  const [errorMessage, setErrorMessage] = useState("");

  // 검색 (이상 있으면 에러메세지 출력)
  function onSubmit() {
    const beforeDate = new Date(
      `${preDate.date.replace(/-/g, "/")} ${preDate.time}`
    ).getTime();
    const afterDate = new Date(
      `${secondDate.date.replace(/-/g, "/")} ${secondDate.time}`
    ).getTime();

    if (afterDate <= beforeDate) {
      setErrorMessage("검색 기간을 잘못 설정하셨습니다.");
    } else if (afterDate - beforeDate > constrainChartX[interval]) {
      setErrorMessage(constrainText[interval]);
    } else {
      setErrorMessage("");
      //
      requestStorageData(preDate, secondDate, interval);
    }
  }

  useEffect(() => {
    requestStorageData(preDate, secondDate, interval);
  }, []);

  return (
    <>
      <div className='mb-3 '>
        <Row>
          <Col className='d-flex align-items-center' lg={4} xl={3}>
            {/* 시작 시간 */}
            <ReactDatetime
              className='date'
              inputProps={{
                placeholder: "Date Picker Here",
              }}
              timeFormat={false}
              dateFormat={"YYYY/MM/DD"}
              closeOnSelect={true}
              value={preDate.date}
              isValidDate={(data) => {
                const cu_date = new Date().getTime();

                return (
                  cu_date - 1000 * 60 * 60 * 24 * 7 <=
                  new Date(data._d).getTime()
                );
              }}
              onChange={(e: any) =>
                setpreDate({
                  ...preDate,
                  date: currentDateNoTime(e._d),
                })
              }
            />
            <Flatpickr
              style={{ width: "100px" }}
              className='form-control d-block'
              placeholder='Select time'
              value={preDate.time}
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
              }}
              onChange={([date]) => {
                changeDateTime(true, false, currentTime(date));
              }}
            />
          </Col>

          <Col className='d-flex align-items-center' lg={4} xl={3}>
            {/* 끝 시간 */}
            <ReactDatetime
              className=' date'
              inputProps={{
                placeholder: "Date Picker Here",
              }}
              isValidDate={(data) => {
                const cu_date = new Date().getTime();
                return (
                  cu_date - 1000 * 60 * 60 * 24 * 7 <=
                  new Date(data._d).getTime()
                );
              }}
              timeFormat={false}
              dateFormat={"YYYY/MM/DD"}
              closeOnSelect={true}
              value={secondDate.date}
              onChange={(e: any) =>
                changeDateTime(false, true, currentDateNoTime(e._d))
              }
            />

            <Flatpickr
              style={{ width: "100px" }}
              className='form-control d-block'
              placeholder='Select time'
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
              }}
              value={secondDate.time}
              onChange={([date]) => {
                changeDateTime(false, false, currentTime(date));
              }}
            />
          </Col>
          <Col className='d-flex align-items-center'>
            <Select
              isMulti={false}
              options={optionMulti}
              classNamePrefix='select2-selection'
              closeMenuOnSelect={true}
              onChange={handleInterval}
              className='selector me-3'
              value={optionMulti.find((obj: any) => obj.value === interval)}
            />
            <button
              type='submit'
              className='btn btn-secondary save-user'
              onClick={onSubmit}>
              검색
            </button>
          </Col>
        </Row>

        {/* <input
            className='date me-3'
            type='date'
            onChange={(e) => changeDateTime(true, true, e.target.value)}
            value={preDate.date}
            onKeyDown={() => false}
            />
            <input
            className='date me-5 ms-3'
            type='time'
            onChange={(e) => changeDateTime(true, false, e.target.value)}
            value={preDate.time}
            onKeyDown={() => false}
            />
            <input
            className='date'
            type='date'
            onChange={(e) => changeDateTime(false, true, e.target.value)}
            onKeyDown={() => false}
            value={secondDate.date}
            />
            <input
            className='date me-3 ms-3'
            type='time'
            onChange={(e) => changeDateTime(false, false, e.target.value)}
            onKeyDown={() => false}
            value={secondDate.time}
          /> */}
      </div>
      {errorMessage && (
        <Alert color='warning' className='mt-3 err'>
          {errorMessage}
        </Alert>
      )}
    </>
  );
};

export default DateTimeFields;
