import React, { useState, useEffect, useRef } from "react";
import { Card, Alert, CardBody, CardHeader, CardTitle } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { retiredUser, apiError } from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
//i18n
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";

const Profiletab4 = ({ history }: any) => {
  let usehistory = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const authUser: any = localStorage.getItem("authUser");
  if (!authUser) return null;
  const user = JSON.parse(authUser);

  const { error } = useSelector((state: any) => ({
    error: state.login.error,
  }));

  // handleValidSubmit
  const handleValidSubmit = (values: any) => {
    const token = {
      accessToken: localStorage.getItem("access_token"),
      refreshToken: localStorage.getItem("refresh_token"),
      loginKey: localStorage.getItem("login_key"),
    };
    values.token = JSON.stringify(token);
    dispatch(retiredUser(values, history));
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardTitle className="mb-0">{t("User Retire")}</CardTitle>
        </CardHeader>
        <CardBody>
          <div>
            {error && error.message && error.code !== 200 ? (
              <Alert color="warning">{error.message}</Alert>
            ) : error.code === 200 ? (
              <Alert color="success">{error.message}</Alert>
            ) : null}
            <div>
              <div className="text-muted">
                <ul className="list-unstyled mb-0">
                  <AvForm
                    className="needs-validation custom-form mt-4 pt-2"
                    onValidSubmit={(e: any, v: any) => {
                      handleValidSubmit(v);
                      e.target.reset();
                    }}
                  >
                    <li className="py-1">
                      <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                      {t("User Pw Check")}{" "}
                      <div className="mb-3">
                        <AvField
                          name="password"
                          type="password"
                          required
                          placeholder={t("Password placeholder")}
                          errorMessage={t("Password placeholder")}
                        />
                      </div>
                    </li>

                    <div className="mb-3" style={{ marginTop: "20px" }}>
                      <button className="btn btn-danger w-100 " type="submit">
                        {t("User Retire")}
                      </button>
                    </div>
                  </AvForm>
                </ul>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Profiletab4;
