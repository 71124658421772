import React, { useState, useEffect } from "react";
import { Card, Alert, CardBody, CardHeader, CardTitle } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { changeInfo, apiError } from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
//i18n
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
const Profiletab2 = ({ toggleTab }: any) => {
  let usehistory = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authUser: any = localStorage.getItem("authUser");
  if (!authUser) return null;
  const user = JSON.parse(authUser);

  const [name, setName] = useState(user.name);
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
  const { error } = useSelector((state: any) => ({
    error: state.login.error,
  }));

  // handleValidSubmit
  const handleValidSubmit = (values: any) => {
    values.old_password = values.password;
    dispatch(changeInfo(values));
  };
  useEffect(() => {
    if (error.code === 200) {
      const timerId = setInterval(() => {
        setPassword("");
        dispatch(
          apiError({
            message: "",
            code: 0,
          })
        );
      }, 3000);
      return () => clearInterval(timerId);
    }
  }, [error]);

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardTitle className="mb-0">{t("User Info Change")}</CardTitle>
        </CardHeader>
        <CardBody>
          <div>
            {error && error.message && error.code !== 200 ? (
              <Alert color="warning">{error.message}</Alert>
            ) : error.code === 200 ? (
              <Alert color="success">{error.message}</Alert>
            ) : null}
            <div>
              <div className="text-muted">
                <ul className="list-unstyled mb-0">
                  <AvForm
                    className="needs-validation custom-form mt-4 pt-2"
                    onValidSubmit={(e: any, v: any) => {
                      handleValidSubmit(v);
                    }}
                  >
                    <li className="py-1">
                      <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                      {t("User Name")}{" "}
                      <div className="mb-3">
                        <AvField
                          name="name"
                          type="text"
                          value={name}
                          required
                          placeholder={t("User Name placeholder")}
                          errorMessage={t("User Name placeholder")}
                        />
                      </div>
                    </li>
                    <li className="py-1">
                      <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                      {t("Phone Number")}{" "}
                      <div className="mb-3">
                        <AvField
                          name="phone_number"
                          type="phone"
                          value={phoneNumber}
                          required
                          placeholder={t("Phone Number placeholder")}
                          validate={{
                            pattern: {
                              value:
                                /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
                              errorMessage: t("Phone Number message"),
                            },
                          }}
                          errorMessage={t("Phone Number empty")}
                        />
                      </div>
                    </li>
                    <li className="py-1">
                      <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                      {t("Password")}{" "}
                      <div className="mb-3">
                        <AvField
                          value={password}
                          name="password"
                          type="password"
                          onChange={(event: any) =>
                            setPassword(event.target.value)
                          }
                          placeholder={t("Password placeholder")}
                        />
                      </div>
                    </li>

                    <div className="mb-3" style={{ marginTop: "20px" }}>
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        type="submit"
                      >
                        {t("Change")}
                      </button>
                    </div>
                  </AvForm>
                </ul>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Profiletab2;
