import { FactoryTypes } from "./actionTypes";

const initialState: any = {
  error: {
    message: "",
    code: 0,
  },
  loading: false,
  factoryList: [],
};

const factory = (state = initialState, action: any) => {
  switch (action.type) {
    case FactoryTypes.GET_FACTORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FactoryTypes.GET_FACTORY_SUCCESS:
      state = {
        ...state,
        loading: true,
        factoryList: [...action.payload.factoryList.factory],
      };
      break;
    case FactoryTypes.API_ERROR:
      state = { ...state, error: { ...action.payload }, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default factory;
