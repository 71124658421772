/** @format */

import { useState } from "react";
import EmptyData from "src/pages/Utility/EmptyData";

import { Card, CardBody, CardHeader, CardTitle, Table, Row } from "reactstrap";
import { COLOR } from "../../mock/COLOR";

//i18n
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import ReactDatetime from "react-datetime";
import { currentDate, currentDateNoTime } from "src/ts/operator";
import { memo } from "react";

const Press = memo(({ data, pressDate, setPressDate, chartSize }: any) => {
  const { t } = useTranslation();
  const [alert, setAlert] = useState("");

  return (
    <Card style={{ height: `${chartSize}px`, overflow: "auto" }}>
      <CardHeader>
        <CardTitle>
          <div className='d-flex justify-content-between align-items-center'>
            <h5 className='font-size-16 mn-0'>
              프레스 1호기
              <span className='text-muted fw-normal ms-2'>
                ({data?.length})
              </span>
            </h5>
            <ReactDatetime
              className='calender'
              inputProps={{
                placeholder: "Date Picker Here",
              }}
              timeFormat={false}
              dateFormat={"YYYY/MM/DD"}
              value={pressDate}
              closeOnSelect={true}
              onChange={(e: any) => setPressDate(currentDateNoTime(e._d))}
            />
            {/* <ReactDatetime
              className='calender'
              inputProps={{
                placeholder: "Date Picker Here",
              }}
              timeFormat={"HH:mm"}
              dateFormat={false}
              closeOnSelect={true}
              initialViewMode={"time"}
            /> */}
          </div>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row className='justify-content-center'>
          <div className='col-xl-12 col-lg-8'>
            <div className='table-responsive'>
              <Table className='table mb-0'>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>No</th>
                    <th>Date</th>
                    <th>take_time</th>
                  </tr>
                </thead>
                <tbody>
                  {data ? (
                    data.map((v: any, index: number) => {
                      console.log(v.date);

                      return (
                        <tr key={`${v.index}_${v.date}`}>
                          <th>
                            <div className='text-danger'>
                              <i className='bx bx-error font-size-15 align-middle'></i>
                            </div>
                          </th>
                          <td scope='row'>{index + 1}</td>
                          <td>
                            {
                              currentDate(v.date.replace(/-/g, "/")).split(
                                " "
                              )[1]
                            }
                          </td>
                          <th>{v.take_time}</th>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4}>
                        <EmptyData />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
});

export default Press;
