import React from "react";

import ReactApexChart from "react-apexcharts";

const chartapex = ({ prod1, prod2, time }: any) => {
  const series = [
    { name: "prod1", data: prod1 },
    { name: "prod2", data: prod2 },
  ];
  const options: Object = {
    chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
    colors: ["#556ee6", "#34c38f"],
    dataLabels: { enabled: !0 },
    stroke: { width: [3, 3], curve: "straight" },
    grid: {
      row: { colors: ["transparent", "transparent"], opacity: 0.2 },
      borderColor: "#f1f1f1",
    },
    markers: { style: "inverted", size: 6 },
    xaxis: {
      categories: time,
      title: { text: "Time" },
    },
    yaxis: { title: { text: "Count" }, min: 0, max: 1000 },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: !0,
      offsetY: -25,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: { chart: { toolbar: { show: !1 } }, legend: { show: !1 } },
      },
    ],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="320"
    />
  );
};

export default chartapex;
