/** @format */

import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { isEmpty } from "lodash";
//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
// editable Table
import ShareTable from "./ShareTable";
import { get } from "../../helpers/api_helper";
//i18n
import { useTranslation } from "react-i18next";

const Share = ({ match }: any) => {
  const [factory, setFactory] = useState();
  useEffect(() => {
    // Request URL: api/main/v1/factory?owner_email=airwalk741%40naver.com&cursor=0&count=1000
    const authUser: any = localStorage.getItem("authUser");
    if (authUser) {
      const obj = JSON.parse(authUser);
      get(`/api/main/v1/factory?owner_email=${obj.email}&cursor=0&count=1000`, {
        headers: {
          Authorization: localStorage.getItem("access_token"),
        },
      }).then((res) => {
        console.log(res.factory);
        if (!isEmpty(res)) setFactory(res.factory);
      });
    }
  }, []);
  return (
    <React.Fragment>
      <div className='page-content'>
        <MetaTags>
          <title>Plandy</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title='Share' breadcrumbItem='Share' />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className='justify-content-center'>
                    <div className='col-xl-12 col-lg-8'>
                      <div className='text-center'>
                        <ShareTable factory={factory} />
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Share;
