/** @format */

import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";

//import images
import avatar from "../../../assets/images/users/avatar-2.jpg";
import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import avatar3 from "../../../assets/images/users/avatar-3.jpg";

import classnames from "classnames";
import ProfileTab1 from "./ProfileTab1";
import ProfileTab2 from "./Profiletab2";
import ProfileTab3 from "./Profiletab3";
import ProfileTab4 from "./Profiletab4";

//i18n
import { useTranslation } from "react-i18next";

const ProfileTab = ({ infos, getInfos }: any) => {
  const { t } = useTranslation();
  const [activeTab, toggleTab] = useState("1");
  const authUser: any = localStorage.getItem("authUser");
  if (!authUser) return null;
  const user = JSON.parse(authUser);

  return (
    <React.Fragment>
      <div
        className='d-flex justify-content-center mt-3'
        style={
          {
            // backgroundImage: `url("data:image/svg+xml,%3Csvg id='visual' viewBox='0 0 1800 800' width='1800' height='800' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1'%3E%3Crect x='0' y='0' width='1800' height='800' fill='%23ffffff'%3E%3C/rect%3E%3Cg fill='%23e9d4e7'%3E%3Ccircle r='155' cx='1250' cy='493'%3E%3C/circle%3E%3Ccircle r='97' cx='458' cy='324'%3E%3C/circle%3E%3Ccircle r='98' cx='984' cy='166'%3E%3C/circle%3E%3Ccircle r='146' cx='1601' cy='233'%3E%3C/circle%3E%3Ccircle r='122' cx='177' cy='493'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E")`,
            // height: "78vh",
          }
        }>
        <Col xl={9} lg={8}>
          <Card>
            <CardBody>
              <Row>
                <div className='col-sm order-2 order-sm-1'>
                  <div className='d-flex align-items-start mt-3 mt-sm-0'>
                    <div className='flex-shrink-0'>
                      <div className='avatar-xl me-3'>
                        <img
                          src={avatar}
                          alt=''
                          className='img-fluid rounded-circle d-block'
                        />
                      </div>
                    </div>
                    <div className='flex-grow-1'>
                      <div>
                        <h5 className='font-size-18 mb-3'>{user.name}</h5>
                        <p className='text-muted font-size-13'>
                          <i className='mdi mdi-circle-medium me-1 text-success align-middle'></i>
                          {user.email}
                        </p>
                        <p className='text-muted font-size-13'>
                          <i className='mdi mdi-circle-medium me-1 text-success align-middle'></i>
                          {user.phoneNumber}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>

              <Nav className='nav-tabs-custom card-header-tabs border-top mt-4'>
                <NavItem>
                  <NavLink
                    to='#'
                    className={classnames(
                      {
                        active: activeTab === "1",
                      },
                      "px-3"
                    )}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      toggleTab("1");
                    }}>
                    {t("User Info")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to='#'
                    className={classnames(
                      {
                        active: activeTab === "2",
                      },
                      "px-3"
                    )}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      toggleTab("2");
                    }}>
                    {t("User Info Change")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to='#'
                    className={classnames(
                      {
                        active: activeTab === "3",
                      },
                      "px-3"
                    )}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      toggleTab("3");
                    }}>
                    {t("User Pw Change")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to='#'
                    className={classnames(
                      {
                        active: activeTab === "4",
                      },
                      "px-3"
                    )}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      toggleTab("4");
                    }}>
                    {t("User Retire")}
                  </NavLink>
                </NavItem>
              </Nav>
            </CardBody>
          </Card>
          <TabContent activeTab={activeTab}>
            <TabPane tabId='1'>
              <ProfileTab1 infos={infos} getInfos={getInfos} />
            </TabPane>
            <TabPane tabId='2'>
              <ProfileTab2 toggleTab={toggleTab} />
            </TabPane>
            <TabPane tabId='3'>
              <ProfileTab3 />
            </TabPane>
            <TabPane tabId='4'>
              <ProfileTab4 />
            </TabPane>
          </TabContent>
        </Col>
        {/* <Col xl={3} lg={4}>
          <Card>
            <CardBody>
              <CardTitle className="mb-3">Skills</CardTitle>

              <div className="d-flex flex-wrap gap-2 font-size-16">
                <Link to="#" className="badge badge-soft-primary">
                  Photoshop
                </Link>
                <Link to="#" className="badge badge-soft-primary">
                  illustrator
                </Link>
                <Link to="#" className="badge badge-soft-primary">
                  HTML
                </Link>
                <Link to="#" className="badge badge-soft-primary">
                  CSS
                </Link>
                <Link to="#" className="badge badge-soft-primary">
                  Javascript
                </Link>
                <Link to="#" className="badge badge-soft-primary">
                  Php
                </Link>
                <Link to="#" className="badge badge-soft-primary">
                  Python
                </Link>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <CardTitle className="mb-3">Portfolio</CardTitle>

              <div>
                <ul className="list-unstyled mb-0">
                  <li>
                    <Link to="#" className="py-2 d-block text-muted">
                      <i className="mdi mdi-web text-primary me-1"></i> Website
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="py-2 d-block text-muted">
                      <i className="mdi mdi-note-text-outline text-primary me-1"></i>{" "}
                      Blog
                    </Link>
                  </li>
                </ul>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <CardTitle className="mb-3">Similar Profiles</CardTitle>

              <div className="list-group list-group-flush">
                <Link to="#" className="list-group-item list-group-item-action">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0 me-3">
                      <img
                        src={avatar1}
                        alt=""
                        className="img-thumbnail rounded-circle"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <div>
                        <h5 className="font-size-14 mb-1">James Nix</h5>
                        <p className="font-size-13 text-muted mb-0">
                          Full Stack Developer
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="#" className="list-group-item list-group-item-action">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0 me-3">
                      <img
                        src={avatar3}
                        alt=""
                        className="img-thumbnail rounded-circle"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <div>
                        <h5 className="font-size-14 mb-1">Darlene Smith</h5>
                        <p className="font-size-13 text-muted mb-0">
                          UI/UX Designer
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="#" className="list-group-item list-group-item-action">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0 me-3">
                      <div className="avatar-title bg-soft-light text-light rounded-circle font-size-22">
                        <i className="bx bxs-user-circle"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <div>
                        <h5 className="font-size-14 mb-1">William Swift</h5>
                        <p className="font-size-13 text-muted mb-0">
                          Backend Developer
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </CardBody>
          </Card>
        </Col> */}
      </div>
    </React.Fragment>
  );
};

export default ProfileTab;
