import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback, useState } from "react";

//Import Icons
import Icon from "@ailibs/feather-react-ts";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

//i18n
import { withTranslation } from "react-i18next";

// MetisMenu
import MetisMenu from "metismenujs";
import { useLocation, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const SidebarContent = (props: any) => {
  const ref = useRef<any>();

  const { menu } = useSelector((state: any) => ({
    menu: state.factory.factoryList,
  }));

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag
        const parent3 = parent2.parentElement; // li tag
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;
    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul: any = document.getElementById("side-menu");

      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (i > 0) {
          if (pathName === items[i].pathname) {
            matchingMenuItem = items[i];
            break;
          }
        }
      }

      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  const top_header: any = document.querySelector("#page-topbar");

  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (window) {
      if (window.innerWidth < 768) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }
  }, []);

  return (
    <React.Fragment>
      <SimpleBar
        style={{
          maxHeight: "100%",
          position: mobile ? "fixed" : "relative",
          top: mobile ? (top_header ? top_header.clientHeight : 0) : "auto",
        }}
        ref={ref}
      >
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="#" className="has-arrow">
                <Icon name="home" />
                <span>{props.t("Dashboard")}</span>
              </Link>
              <ul className="sub-menu">
                {menu.map((v: any, index: number) => {
                  return (
                    <li key={v.id + index}>
                      <Link to={`/dashboard/${v.id}`}>{v.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </li>

            <li>
              <Link to="/share">
                <Icon name="share-2" />
                <span>{props.t("Share")}</span>
              </Link>
            </li>

            <li>
              <Link to="/algorithm">
                <Icon name="cpu" />
                <span>{props.t("Algorithm")}</span>
              </Link>
            </li>

            <li>
              <Link to="/detection">
                <Icon name="pie-chart" />
                <span>{props.t("Detection")}</span>
              </Link>
            </li>

            <li>
              <Link to="/pages-faqs">
                <Icon name="file-text" />
                <span>{props.t("FAQs")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(SidebarContent));
