import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

//i18n
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import { post } from "../../../helpers/api_helper";
import _ from "lodash";

const ProfileTab1 = ({ infos, getInfos }: any) => {
  const { t } = useTranslation();
  const [userList, setUserList] = useState<any>([]);

  useEffect(() => {
    if (infos) {
      const data = [];

      for (let item of infos) {
        const info = JSON.parse(item.info);
        const login_key = item.loginKey;

        data.push({
          ...info,
          login_key,
        });
      }

      for (let i = 0; i < data.length - 1; i++) {
        for (let j = i + 1; j < data.length; j++) {
          const date1 = Number(data[i].date);
          const date2 = Number(data[j].date);
          if (date1 < date2) {
            const temp = _.cloneDeep(data[i]);
            data[i] = data[j];
            data[j] = temp;
          }
        }
      }

      setUserList(data);
    }
  }, [infos]);

  const handleLogout = (login_key: string) => {
    post(
      `/api/auth/v1/logout`,
      {
        login_key: login_key,
      },
      {
        headers: {
          Authorization: localStorage.getItem("access_token"),
        },
      }
    ).then((res) => {
      getInfos();
    });
  };

  function formatDate(timestamp: any) {
    var d = new Date(Number(timestamp) * 1000), // Convert the passed timestamp to milliseconds
      yyyy = d.getFullYear(),
      mm = ("0" + (d.getMonth() + 1)).slice(-2), // Months are zero based. Add leading 0.
      dd = ("0" + d.getDate()).slice(-2), // Add leading 0.
      hh = d.getHours(),
      h = hh,
      min = ("0" + d.getMinutes()).slice(-2), // Add leading 0.
      ampm = "AM",
      time;

    if (hh > 12) {
      h = hh - 12;
      ampm = "PM";
    } else if (hh === 12) {
      h = 12;
      ampm = "PM";
    } else if (hh == 0) {
      h = 12;
    }

    // ie: 2013-02-18, 8:35 AM
    time = yyyy + "-" + mm + "-" + dd + " " + h + ":" + min + " " + ampm;

    return time;
  }
  return (
    <React.Fragment>
      <Card style={{ height: "auto" }}>
        <CardHeader>
          <CardTitle className='mb-0'>{t("Concurrent access list")}</CardTitle>
        </CardHeader>
        <CardBody>
          <div>
            <div className='py-3'>
              <Row>
                <div className='col-xl'>
                  <div className='text-muted'>
                    <table className='table table-bordered mb-0 table-centered'>
                      <tbody>
                        <tr>
                          <th style={{ width: "20%" }}>
                            <div className='py-2 text-center'>
                              <h5 className='font-size-14 mb-0'>Date</h5>
                            </div>
                          </th>
                          <th style={{ width: "20%" }}>
                            <div className='py-2 text-center'>
                              <h5 className='font-size-14 mb-0'>Info</h5>
                            </div>
                          </th>
                          <th style={{ width: "20%" }}>
                            <div className='py-2 text-center'>
                              <h5 className='font-size-14 mb-0'>Action</h5>
                            </div>
                          </th>
                        </tr>
                        {infos &&
                          !isEmpty(userList) &&
                          _.sortBy(userList, "date").map(
                            (v: any, index: number) => {
                              const obj = v;
                              return (
                                <tr key={index}>
                                  <td style={{ width: "20%" }}>
                                    <div className='text-center'>
                                      {formatDate(obj.date)}
                                    </div>
                                  </td>
                                  <td style={{ width: "20%" }}>
                                    <div className='text-center'>{`${
                                      obj.device
                                    } / ${obj.os ? obj.os : ""} ${
                                      obj.name
                                    }`}</div>
                                  </td>
                                  <td style={{ width: "20%" }}>
                                    <div
                                      className='text-center text-mute'
                                      style={{
                                        fontWeight: "bold",
                                        color: "#5a5c5f",
                                      }}
                                    >
                                      {v.login_key ===
                                      localStorage.getItem("login_key") ? (
                                        "현재 기기"
                                      ) : (
                                        <div
                                          onClick={() => {
                                            handleLogout(v.login_key);
                                          }}
                                          style={{
                                            fontWeight: "bold",
                                            color: "#2176d7",
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                          }}
                                        >
                                          Log Out
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ProfileTab1;
