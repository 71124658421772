/** @format */

import React from "react";

import { Card, CardBody, CardHeader, CardTitle, Table, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { useEffect } from "react";
import _ from "lodash";
import { useState } from "react";
import { isEmpty } from "lodash";
import { useRef } from "react";
import { currentDate } from "src/ts/operator";

interface SeriseType {
  name: string;
  label: string;
  data: {
    x: string;
    y: string;
  }[];
}

const DetectionChart = ({ data, title }: any) => {
  const [series, setSeries] = useState<any>([]);

  const baseLine = title === "과전류시험설비" ? 30 : 2;

  useEffect(() => {
    const fake_series = _.cloneDeep(series);
    const fake_data = _.cloneDeep(data);

    if (isEmpty(fake_data)) {
      setSeries([]);
      return;
    }

    fake_data.forEach((item: any) => {
      const keys = Object.keys(item);
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] !== "date") {
          let exist = false;
          for (let j = 0; j < fake_series.length; j++) {
            if (fake_series[j].name === keys[i]) {
              fake_series[j].data.push({
                x: item.date,
                y: item[keys[i]],
              });
              exist = true;
              break;
            }
          }
          if (!exist) {
            fake_series.push({
              name: keys[i],
              label: keys[i],
              data: [
                {
                  x: item.date,
                  y: item[keys[i]],
                },
              ],
            });
          }
        }
      }
    });

    setSeries(fake_series);
  }, [data]);

  const options: any = {
    chart: {
      id: "realtime",
      type: "line",
      animations: {
        enabled: false,
        easing: "linear",
        dynamicAnimation: {
          speed: 1000,
        },
      },

      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "",
      align: "left",
    },
    markers: {
      size: 0,
    },
    xaxis: {
      // categories,
      title: { text: "Time" },
      labels: {
        formatter: (value: number | string, timeStamp: any, opts: any) => {
          const date = currentDate(value);
          return date.split(" ")[1];
        },
      },
    },
    yaxis: {
      // max: 100,
    },
    legend: {
      show: false,
    },
    annotations: {
      yaxis: [
        // {
        //   y: baseLine,
        //   borderColor: "#775DD0",
        //   label: {
        //     borderColor: "#775DD0",
        //     style: {
        //       color: "#fff",
        //       background: "#775DD0",
        //     },
        //     text: `Detection Line (${baseLine})`,
        //   },
        // },
      ],
    },
  };

  const refChart = useRef<any>();

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>
            <h5 className='font-size-16 mn-0'>{title}</h5>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <ReactApexChart
            ref={refChart}
            options={options}
            type='line'
            height={500}
            series={series}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default DetectionChart;
