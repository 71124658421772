import React, { useState } from "react";

const CarouselPage = () => {
  return (
    <React.Fragment>
      <div className="col-xxl-12 col-lg-12 col-md-12">
        <div className="auth-bg pt-md-5 p-4 d-flex" style={{ height: "100vh" }}>
          <div className="bg-overlay bg-primary"></div>
          <ul className="bg-bubbles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <div> </div>
          <div> </div>
          <div> </div>

          <div
            className="w-100 ReactModal__Overlay"
            style={{ marginTop: "200px" }}
          >
            <div className="auth-content my-auto">
              <div className="text-center">
                <h1 style={{ color: "#ced4da" }}>
                  Manage your Company, faster!
                </h1>
                <h2 style={{ color: "#ced4da" }}>Welcome to Plandy</h2>
                <p className="text-white mt-5">
                  Plandy는 고객만족과 더불어 고객의 가치를 최우선으로 하는
                  장기적인 파트너쉽을 추구합니다.
                </p>
                <p className="text-white mt-2">
                  고객의 니즈에 맞는 최고의 품질과 서비스, 그리고 나아가
                  장기적인 로드맵과 성공적인 전략제안을 통해 고객이 기대하는 그
                  이상의 서비스를 제공함으로써 고객과의 탄탄한 신뢰를 이끌어
                  가겠습니다.
                </p>
              </div>

              <div className="mt-5 text-center">
                <h2 style={{ color: "#ced4da" }}>Thank you</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CarouselPage;
