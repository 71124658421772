/** @format */

import React, { memo, useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Graph from "react-graph-vis";

//i18n
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

const GraphNode = ({
  resultTime,
  inputNetworkNodes,
  newNetworkEdges,
  resultNodes,
  maxNum,
}: any) => {
  const { t } = useTranslation();

  const [resultLength, setResultLength] = useState(0);

  const inputNetworkEdges = newNetworkEdges.map((item: any) => ({
    from: item.from,
    to: item.to,
    label: item.label,
  }));

  const startNodeEdge = [];
  for (let i = 0; i < inputNetworkEdges.length; i++) {
    let check = false;

    for (let j = 0; j < inputNetworkEdges.length; j++) {
      if (inputNetworkEdges[j].from === inputNetworkEdges[i].to) {
        check = true;
        break;
      }
    }
    if (check === false) {
      startNodeEdge.push({ from: inputNetworkEdges[i].to, to: "start" });
    }
  }

  if (Object.keys(resultNodes).length) {
    // 스타트 노드 연결
    for (let i = 0; i < resultNodes[maxNum][resultLength].length - 1; i++) {
      const s = resultNodes[maxNum][resultLength][i];
      const e = resultNodes[maxNum][resultLength][i + 1];
      if (i === 0) {
        for (let item of inputNetworkEdges) {
          if (item.to === "start" && item.from === s) {
            item["color"] = "crimson";
          }
        }
      }
      if (s === "end") {
        break;
      }

      for (let item of inputNetworkEdges) {
        if (item.to === s && item.from === e) {
          item["color"] = "crimson";
        }
      }
    }
  }

  const graph = {
    nodes: [
      { id: "start", label: "start", color: "#73c7f5" },
      { id: "end", label: "end", color: "#95eaaa" },
      ...inputNetworkNodes,
    ],
    edges: [...inputNetworkEdges],
  };

  const options = {
    layout: {
      hierarchical: {
        enabled: true,
        levelSeparation: 200,
        nodeSpacing: 200,
        // treeSpacing: 400,
        blockShifting: true,
        edgeMinimization: true,
        parentCentralization: true,
        direction: "RL", // UD, DU, LR, RL
        sortMethod: "directed", // hubsize, directed
      },
    },
    edges: {
      color: { color: "#1b61e4" },
      width: 2,
      arrows: {
        from: {
          enabled: true,
          scaleFactor: 0.7,
        },
        to: {
          enabled: false,
        },
      },
    },
    nodes: {
      shape: "box",
      font: {
        face: "Circular, Futura",
        color: "#fff",
        size: 15,
      },
      color: {
        border: "red",
      },
      margin: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20,
      },
      mass: 1,
    },
    height: "500px",
  };

  const events = {
    select: function (event: any) {
      var { nodes, edges } = event;
    },
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h5 className='font-size-16 text-muted text-truncate '>
                {t("Algorithm2")}
              </h5>
            </div>
            <div className='d-flex align-items-center'>
              <FormGroup>
                <Input
                  type='select'
                  name='select'
                  id='exampleSelect'
                  onChange={(e) => {
                    setResultLength(Number(e.target.value));
                  }}>
                  {!isEmpty(resultNodes[maxNum]) &&
                    resultNodes[maxNum].map((item: any, index: number) => {
                      return (
                        <option value={index} key={index}>
                          {index + 1}번 경로
                        </option>
                      );
                    })}
                </Input>
              </FormGroup>
              <h5 className='font-size-16 text-muted text-truncate ms-5'>
                Result: {resultTime}
              </h5>
            </div>
          </div>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row className='justify-content-center'>
          <div className='col-xl-12 col-lg-8'>
            <Graph
              graph={graph}
              options={options}
              events={events}
              // getNetwork={(network) => {
              //   //  if you want access to vis.js network api you can set the state in a parent component using this property
              // }}
            />
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

export default GraphNode;
