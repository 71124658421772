import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";
import { logoutToken as logout } from "./jwt-token-access/logoutToken";

//pass new generated access token here
const token = accessToken;
const logoutToken = logout;

//apply base url for axios
// const API_URL = "https://ijoon.iptime.org:18081";

export const axiosApi = axios.create({
  // baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] =
  localStorage.getItem("access_token");
// axiosApi.defaults.headers.common["origin"] = "http://localhost:3000/";

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);
export async function get(url: string, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url: string, data: any, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        localStorage.setItem(
          "errorCode",
          JSON.stringify(error.response.status)
        );
      }
      error.status = error.response.status;
      error.message = error.response.message;
      throw error;
    });
}

export async function put(url: string, data: any, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url: string, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
