/** @format */

import React, { useState, useEffect, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import { currentDate } from "src/ts/operator";
import { useTranslation } from "react-i18next";

const RealTimeChart = ({ series, number, serial }: any) => {
  const refChart = useRef<any>(null);
  const { t } = useTranslation();

  const [removedLabel, setRemovedLabel] = useState([]);

  // 차트 없데이트
  if (refChart.current) {
    refChart.current.chart.updateSeries([...series]);
    // refChart.current.chart.
    series.forEach((item: any, index: any) => {
      if (removedLabel.includes(index)) {
        refChart.current.chart.hideSeries(item.name);
      } else {
        refChart.current.chart.showSeries(item.name);
      }
    });
  }
  // useEffect(() => {
  //   if (refChart.current) {
  //     refChart.current.chart.updateSeries([...series]);
  //   }
  // }, [series]);

  const options: any = {
    chart: {
      id: "realtime",
      type: "line",
      animations: {
        enabled: false,
        easing: "linear",
        dynamicAnimation: {
          speed: 1000,
        },
      },

      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      events: {
        legendClick: function (chartContext, seriesIndex, config) {
          setRemovedLabel((pre: any) => {
            if (pre.includes(seriesIndex)) {
              return pre.filter((item: any) => {
                return item !== seriesIndex;
              });
            } else {
              return setRemovedLabel((pre) => [...pre, seriesIndex]);
            }
          });
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      width: 2,
      dashArray: 0,
    },
    title: {
      text: "",
      align: "left",
    },
    markers: {
      size: 0,
    },
    xaxis: {
      // categories,
      type: "datetime",
      title: { text: "Time" },
      labels: {
        formatter: (value: number | string, timeStamp: any, opts: any) => {
          const date = currentDate(value);
          return date.split(" ")[1];
        },
      },
    },
    yaxis: {
      // max: 100,
    },
    legend: {
      show: true,
    },
  };

  // const series: any = [];
  return (
    <div>
      <div className='d-flex flex-wrap align-items-center'>
        <h5 className='card-title me-2'>
          {serial === "fac-cam-08" ? "Time Counter" : ""} {number}
        </h5>
      </div>
      <div id='chart' style={{ width: "100%", margin: "auto" }}>
        <ReactApexChart
          options={options}
          series={series}
          type='line'
          height={500}
          ref={refChart}
        />
      </div>
    </div>
  );
};
export default RealTimeChart;
