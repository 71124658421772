import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//import drawer
import ReactDrawer from "react-drawer";
import "react-drawer/lib/react-drawer.css";

//Import Icons
import Icon from "@ailibs/feather-react-ts";

//import component
import RightSidebar from "../CommonForBoth/RightSidebar";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import LightDark from "../CommonForBoth/Menus/LightDark";

//import images
import logoSvg from "../../assets/images/logo-sm.svg";
//redux
import { useSelector } from "react-redux";

const Header = (props: any) => {
  const { layoutMode } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
  }));

  const [isClick, setClick] = useState<boolean>(true);
  const [position, setPosition] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  /**
   * Rightsidebar drawer
   */
  const toggleTopDrawer = () => {
    setPosition("right");
    setOpen(!open);
  };

  /**
   * Close drawer
   */
  const onDrawerClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   if (window.innerWidth < 768) {
  //     setClick(true);
  //   }
  // }, []);

  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    var body = document.body;
    setClick(!isClick);
    if (isClick === true) {
      body.classList.add("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "sm");
    } else {
      body.classList.remove("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "lg");
    }
  }

  useEffect(() => {
    setTimeout(() => {
      var body = document.body;
      body.classList.remove("sidebar-enable");
    }, 1000);
  }, []);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <div className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSvg} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logoSvg} alt="" height="24" />{" "}
                  <span className="logo-txt">Plandy</span>
                </span>
              </div>

              <div className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSvg} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logoSvg} alt="" height="24" />{" "}
                  <span className="logo-txt">Plandy</span>
                </span>
              </div>
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
          </div>
          <div className="d-flex">
            <LanguageDropdown />

            {/* light / dark mode */}
            <LightDark
              layoutMode={layoutMode}
              onChangeLayoutMode={props.onChangeLayoutMode}
            />

            {/* <div
              onClick={() => {
                toggleTopDrawer();
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle"
              >
                <Icon name="settings" className="icon-lg" />
              </button>
            </div> */}
            <ProfileMenu />
          </div>
        </div>
      </header>
      <ReactDrawer open={open} onClose={onDrawerClose} position={position}>
        <RightSidebar
          onClose={onDrawerClose}
          onChangeLayoutMode={props.onChangeLayoutMode}
        />
      </ReactDrawer>
    </React.Fragment>
  );
};

export default Header;
