import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { useTranslation } from "react-i18next";
import { Row, Col, Alert } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import reset from "../../mock/reset.json";

// actions
import {
  apiError,
  verificationSendMail as actionsMailCheck,
  verificationCode as actionsCode,
  sendResetCode,
  resetVerificationCode,
  resetPassword,
} from "../../store/actions";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

//import images
import logo from "../../assets/images/logo-sm.svg";

interface RecoverPasswordProps {
  history: object;
}
const RecoverPassword = ({ history }: RecoverPasswordProps) => {
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { error } = useSelector((state: any) => ({
    error: state.login.error,
  }));

  const handleValidSubmit = (event: any, values: any) => {
    if (step1 === false) {
      const data = {
        body: reset.body,
        email: email,
        from: reset.from,
        contentType: reset.contentType,
        charset: reset.charset,
        subject: reset.subject,
      };
      dispatch(sendResetCode(data));
    } else if (step1 && step2 === false && step3 === false) {
      const data = {
        code,
        email,
      };
      dispatch(resetVerificationCode(data));
    } else if (step1 && step2 && step3 === false) {
      const data = {
        code,
        email,
        password,
      };
      dispatch(resetPassword(data, history));
    }
  };

  useEffect(() => {
    if (step1 === false && error.code === 0 && email !== "") {
      setStep1(true);
    } else if (step1 && step2 === false && error.code === 0) {
      setStep2(true);
    } else if (!step3) {
    }
  }, [error]);
  return (
    <React.Fragment>
      <MetaTags>
        <title>Plandy</title>
      </MetaTags>
      <div className="auth-page" style={{ overflowX: "hidden" }}>
        <Row>
          <Col>
            <div className="auth-full-page-content d-flex p-sm-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5 text-center">
                    <Link to="/main" className="d-block auth-logo">
                      <img src={logo} alt="" height="28" />{" "}
                      <span className="logo-txt">Plandy</span>
                    </Link>
                  </div>
                  <div className="auth-content my-auto">
                    <div className="text-center">
                      <div className="text-center">
                        <h4>Reset Password</h4>
                        <p>Reset Password with Plandy.</p>
                        <div
                          className="alert alert-success text-center"
                          role="alert"
                          style={{ display: "inline-block", width: "320px" }}
                        >
                          {t("Reset Message")}
                        </div>
                        <div>
                          <p
                            style={{
                              display: "inline-block",
                              width: "320px",
                            }}
                          >
                            {error && error.message ? (
                              <Alert color="warning">{error.message}</Alert>
                            ) : null}
                          </p>
                        </div>
                        <AvForm
                          className="custom-form "
                          onValidSubmit={(e: any, v: any) => {
                            handleValidSubmit(e, v);
                          }}
                        >
                          <div>
                            <AvField
                              style={{ width: "300px", display: "inline" }}
                              name="email"
                              value={email}
                              readOnly={step1}
                              onChange={(event: any) =>
                                setEmail(event.target.value)
                              }
                              className="form-control"
                              placeholder={t("Email placeholder")}
                              validate={{
                                pattern: {
                                  value:
                                    /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/,
                                  errorMessage: t("Email check"),
                                },
                              }}
                              errorMessage={t("Email placeholder")}
                              type="email"
                              required
                            />
                          </div>

                          {!step1 ? (
                            <>
                              <div className="mt-4">
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-sm waves-effect waves-light"
                                >
                                  {t("Send Mail")}
                                </button>
                              </div>
                            </>
                          ) : null}

                          {step1 && !step3 ? (
                            <>
                              <div className="mt-3">
                                <AvField
                                  style={{ width: "300px", display: "inline" }}
                                  name="code"
                                  value={code}
                                  readOnly={step2}
                                  onChange={(event: any) =>
                                    setCode(event.target.value)
                                  }
                                  className="form-control"
                                  placeholder={t("Verify Message4")}
                                  errorMessage={t("Verify Message4")}
                                  type="text"
                                  required
                                />
                              </div>
                              {step1 && step2 ? null : (
                                <div className="mt-3">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-sm waves-effect waves-light"
                                  >
                                    {t("Send")}
                                  </button>
                                </div>
                              )}
                            </>
                          ) : null}

                          {step1 && step2 && !step3 ? (
                            <>
                              <div className="mt-3">
                                <AvField
                                  style={{ width: "300px", display: "inline" }}
                                  name="password"
                                  value={password}
                                  onChange={(event: any) =>
                                    setPassword(event.target.value)
                                  }
                                  className="form-control"
                                  type="password"
                                  required
                                  placeholder={t("Password placeholder")}
                                  errorMessage={t("Password placeholder")}
                                />
                              </div>
                              <div className="mt-3">
                                <AvField
                                  style={{ width: "300px", display: "inline" }}
                                  name="passwordConfirm"
                                  value={passwordConfirm}
                                  onChange={(event: any) =>
                                    setPasswordConfirm(event.target.value)
                                  }
                                  className="form-control"
                                  type="password"
                                  required
                                  placeholder={t(
                                    "Confirm Password placeholder"
                                  )}
                                  validate={{
                                    match: {
                                      value: "password",
                                      errorMessage: t("Check Password"),
                                    },
                                  }}
                                  errorMessage={t(
                                    "Confirm Password placeholder"
                                  )}
                                />
                              </div>
                              <div className="mt-3">
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-sm waves-effect waves-light"
                                >
                                  {t("Reset PW")}
                                </button>
                              </div>
                            </>
                          ) : null}
                        </AvForm>
                      </div>
                    </div>

                    <div className="mt-5 text-center">
                      <p className="text-muted mb-0">
                        {t("Remember It")}{" "}
                        <Link to="/login" className="text-primary fw-semibold">
                          {" "}
                          {t("Sing In")}{" "}
                        </Link>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0">
                      Copyright © {new Date().getFullYear()} Ijoon All right
                      reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default RecoverPassword;
