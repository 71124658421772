/** @format */

import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

/** import Mini Widget data */
import InvestedOverview from "./InvestedOverview";
import FacilityOperation from "./FacilityOperation";
import CurrentStatus from "./CurrentStatus";

import { get } from "src/helpers/api_helper";
import { GET_MACHINE_LIST, GET_STORAGE } from "src/helpers/url_helper";

//i18n
import { useTranslation } from "react-i18next";
import { isEmpty, sortBy } from "lodash";

import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { WebSocketContext } from "src/websocket/WebSocketProvider";
import mqtt from "mqtt";
import { useRef } from "react";
import { currentDate } from "src/ts/operator";

import ApexErr from "./ApexErr";

import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

let sortedData: any = {
  // psb: undefined,
  // oct: undefined,
  // wvt: undefined,
  // press_1: undefined,
  // wdmc_1: undefined,
  // wdmc_4: undefined,
  // wdmc_6: undefined,
  // etc: [{}],
};

let medianFilterLength = 5;
let medianFilter = {
  goal1: [],
  goal2: [],
  prod1: [],
  prod2: [],
};

const Dashboard = ({ match }: any) => {
  const { t } = useTranslation();
  const [data, setData] = useState<any>(undefined);
  const [psb, setPsb] = useState<any>(undefined);
  const [prevPsb, setPrevPsb] = useState<any>(undefined);
  const owner = JSON.parse(localStorage.getItem("authUser") || "");
  const machineQueryString = `?owner_email=${owner.email}&factory_id=${match.params.factory_id}&cursor=0&count=100`;
  const location = useLocation();
  let usehistory = useHistory();
  const dispatch = useDispatch();

  const { user } = useSelector((state: any) => {
    return {
      user: state.login.user,
    };
  });
  // console.log(user);

  useEffect(() => {
    if (isEmpty(user)) return;
    sortedData = {};
    get(GET_MACHINE_LIST + machineQueryString, {
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    })
      .then((res) => {
        if (match.params.factory_id === "1") {
          res.machine.map(async (v: any) => {
            if (v.name === "OCT") {
              sortedData.oct = v;
            } else if (v.name === "PSB") {
              let today = new Date();
              let tomorrow = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() + 1
              );
              let yesterDay = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() - 1
              );
              let prev_prev_date = `${yesterDay.getFullYear()}-${
                (yesterDay.getMonth() + 1).toString().length === 1
                  ? "0" + (yesterDay.getMonth() + 1)
                  : yesterDay.getMonth() + 1
              }-${
                yesterDay.getDate().toString().length === 1
                  ? "0" + yesterDay.getDate()
                  : yesterDay.getDate()
              }`;

              let prev_date = `${today.getFullYear()}-${
                (today.getMonth() + 1).toString().length === 1
                  ? "0" + (today.getMonth() + 1)
                  : today.getMonth() + 1
              }-${
                today.getDate().toString().length === 1
                  ? "0" + today.getDate()
                  : today.getDate()
              }`;

              let next_date = `${tomorrow.getFullYear()}-${
                (tomorrow.getMonth() + 1).toString().length === 1
                  ? "0" + (tomorrow.getMonth() + 1)
                  : tomorrow.getMonth() + 1
              }-${
                tomorrow.getDate().toString().length === 1
                  ? "0" + tomorrow.getDate()
                  : tomorrow.getDate()
              }`;
              const storageQueryString = `?serial=${v.serial}&prev_date=${prev_date}+00:00:00&next_date=${next_date}+00:00:00&interval=2400&cursor=0&count=100000`;
              const storagePrevQueryString = `?serial=${v.serial}&prev_date=${prev_prev_date}+00:00:00&next_date=${prev_date}+00:00:00&interval=2400&cursor=0&count=100000`;

              sortedData.psb = v;

              console.log("요청");
              console.log(storageQueryString);

              try {
                const res = await get(GET_STORAGE + storageQueryString);
                const { storageData } = res;
                let myfilter = {
                  prod1: [-1],
                  prod2: [-1],
                  goal1: [-1],
                  goal2: [-1],
                };

                let lastData = {
                  prod1: 0,
                  prod2: 0,
                  goal1: 0,
                  goal2: 0,
                };

                if (!isEmpty(storageData)) {
                  ///
                  // 인식이 잘 안되서 사용한 코드
                  // 인식 잘되면 지워주기
                  // 필터링 750 넘으면 안됨 보통 700
                  // 목표 < 생산일때 다시 한번 확인하기 위해 실시간으로 그 시간 때의 데이터 가져오기
                  for (let i = 0; i < storageData.length; i++) {
                    const item = storageData[i];
                    const processing = JSON.parse(item.processing);
                    const { goal1, goal2, prod1, prod2 } = processing;
                    if (
                      Number(goal1) < Number(prod1) ||
                      Number(goal2) < Number(prod2)
                    ) {
                      const { date } = item;
                      const year = date.substring(0, 10);
                      const hour = date.substring(11, 13);
                      const storageQueryString = `?serial=${
                        v.serial
                      }&prev_date=${year}+${hour}:00:00&next_date=${year}+${
                        Number(hour) + 1
                      }:00:00&interval=1&cursor=0&count=100000`;
                      const res = await get(GET_STORAGE + storageQueryString);
                      const myStorageData = res.storageData.reverse();
                      let myfilter = {
                        prod1: [-1],
                        prod2: [-1],
                        goal1: [-1],
                        goal2: [-1],
                      };

                      let lastData = {
                        prod1: 0,
                        prod2: 0,
                        goal1: 0,
                        goal2: 0,
                      };
                      myStorageData.map((item) => {
                        const processing = JSON.parse(item.processing);
                        const { goal1, goal2, prod1, prod2 } = processing;
                        for (let [key, v] of Object.entries(processing)) {
                          const value = Number(v);

                          if (
                            myfilter[key][myfilter[key].length - 1] === value
                          ) {
                            myfilter[key].push(value);
                          } else {
                            myfilter[key] = [value];
                          }

                          if (myfilter[key].length >= 5 && value < 750) {
                            lastData[key] = value;
                            myfilter[key] = [value];
                          }
                        }
                      });

                      if (
                        lastData.goal1 !== -1 &&
                        lastData.goal2 !== -1 &&
                        lastData.prod1 !== -1 &&
                        lastData.prod2 !== -1
                      ) {
                        item.processing = JSON.stringify(lastData);
                      }
                    }
                  }

                  //// 마지막 시간 때는 실시간 데이터 보내서 필터링 적용하기
                  const { date } = storageData[0];
                  const year = date.substring(0, 10);
                  const hour = date.substring(11, 13);
                  const storageQueryString = `?serial=${
                    v.serial
                  }&prev_date=${year}+${hour}:00:00&next_date=${year}+${
                    Number(hour) + 1
                  }:00:00&interval=1&cursor=0&count=100000`;
                  const res = await get(GET_STORAGE + storageQueryString);
                  const myStorageData = res.storageData.reverse();

                  myStorageData.map((item) => {
                    const processing = JSON.parse(item.processing);
                    for (let [key, v] of Object.entries(processing)) {
                      const value = Number(v);

                      if (myfilter[key][myfilter[key].length - 1] === value) {
                        myfilter[key].push(value);
                      } else {
                        myfilter[key] = [value];
                      }

                      if (myfilter[key].length >= 5) {
                        if (lastData[key] < value && value < 750) {
                          lastData[key] = value;
                        }
                        myfilter[key] = [value];
                      }
                    }
                  });

                  if (
                    lastData.goal1 !== -1 &&
                    lastData.goal2 !== -1 &&
                    lastData.prod1 !== -1 &&
                    lastData.prod2 !== -1
                  ) {
                    storageData[0].processing = JSON.stringify(lastData);
                  }
                }
                setPsb(storageData);
              } catch (err) {
                console.log(err);
              }

              try {
                const res = await get(GET_STORAGE + storagePrevQueryString, {});
                const { storageData } = res;
                let myfilter = {
                  prod1: [-1],
                  prod2: [-1],
                  goal1: [-1],
                  goal2: [-1],
                };

                let lastData = {
                  prod1: 0,
                  prod2: 0,
                  goal1: 0,
                  goal2: 0,
                };

                if (!isEmpty(storageData)) {
                  const { date } = storageData[0];
                  const year = date.substring(0, 10);
                  const hour = date.substring(11, 13);
                  const storageQueryString = `?serial=${
                    v.serial
                  }&prev_date=${year}+${hour}:00:00&next_date=${year}+${
                    Number(hour) + 1
                  }:00:00&interval=1&cursor=0&count=100000`;
                  console.log(storageQueryString);
                  const res = await get(GET_STORAGE + storageQueryString);
                  const myStorageData = res.storageData;

                  myStorageData.map((item) => {
                    const processing = JSON.parse(item.processing);

                    for (let [key, v] of Object.entries(processing)) {
                      const value = Number(v);

                      if (myfilter[key][myfilter[key].length - 1] === value) {
                        myfilter[key].push(value);
                      } else {
                        myfilter[key] = [value];
                      }

                      if (myfilter[key].length >= 5) {
                        if (lastData[key] < value && value < 750) {
                          lastData[key] = value;
                        }
                        myfilter[key] = [value];
                      }
                    }
                  });

                  if (
                    lastData.goal1 !== -1 &&
                    lastData.goal2 !== -1 &&
                    lastData.prod1 !== -1 &&
                    lastData.prod2 !== -1
                  ) {
                    storageData[0].processing = JSON.stringify(lastData);
                  }
                }
                setPrevPsb(storageData);
              } catch (err) {
                console.log(err);
              }
            } else if (v.name === "WVT") {
              sortedData["wvt"] = v;
            } else if (v.name.indexOf("WDMC_1") !== -1) {
              sortedData["wdmc_1"] = v;
            } else if (v.name.indexOf("WDMC_4") !== -1) {
              sortedData["wdmc_4"] = v;
            } else if (v.name.indexOf("WDMC_6") !== -1) {
              sortedData["wdmc_6"] = v;
            } else if (v.name.indexOf("PRESS_1") !== -1) {
              sortedData["press_1"] = v;
            } else {
              sortedData[v.name] = v;
            }
          });

          setData(sortedData);
        } else {
          setPsb(undefined);
          setPrevPsb(undefined);

          if (!isEmpty(res)) {
            res.machine.map((item: any) => {
              sortedData[item.name] = item;
            });
            setData(sortedData);
          } else {
            setData([]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        // if (err.response.status !== 401) {
        //   usehistory.push("/main");
        // }
      });
  }, [match.params.factory_id, user]);

  const processing = psb && JSON.parse(psb[0].processing);
  const prevPprocessing = prevPsb && JSON.parse(prevPsb[0].processing);
  const totalPercent1 =
    processing && processing.prod1 && processing.goal1 && processing.goal1 > 0
      ? Math.floor(
          (Number.parseInt(processing.prod1) /
            Number.parseInt(processing.goal1)) *
            100
        )
      : 0;

  const totalPercent2 =
    processing && processing.prod2 && processing.goal2 && processing.goal2 > 0
      ? Math.floor(
          (Number.parseInt(processing.prod2) /
            Number.parseInt(processing.goal2)) *
            100
        )
      : 0;

  let data1: any[] = [];
  let data2: any[] = [];
  let prodTime: any[] = [];
  let prod1Per = Infinity;
  let prod2Per = Infinity;

  if (psb) {
    psb.map((v: any) => {
      const processing = JSON.parse(v.processing);
      data1.push(processing.prod1 === "" ? "0" : processing.prod1);
      data2.push(processing.prod2 === "" ? "0" : processing.prod2);
      prodTime.push(v.date.substring(11, 13) + ":00");
    });
  }

  if (psb && prevPsb) {
    const now = psb[0].date.substring(11, 13);
    const processing = JSON.parse(psb[0].processing);
    // 동시간 대비
    prevPsb.map((v: any) => {
      if (v.date.substring(11, 13) === now) {
        const prevPprocessing = JSON.parse(v.processing);

        prod1Per =
          ((Number.parseInt(processing.prod1) -
            Number.parseInt(prevPprocessing.prod1)) /
            Number.parseInt(prevPprocessing.prod1)) *
          100;
        prod2Per =
          ((Number.parseInt(processing.prod2) -
            Number.parseInt(prevPprocessing.prod2)) /
            Number.parseInt(prevPprocessing.prod2)) *
          100;
        // prod2Per =
        //   ((Number.parseInt(processing.prod2) -
        //     Number.parseInt(prevPprocessing.prod2)) /
        //     Number.parseInt(prevPprocessing.prod2)) *
        //   100;
      }
    });
  }

  const [mqttData, setMqttData] = useState<any>(null);
  const client = useRef<any>(null);

  const mqttConnection = () => {
    if (match.params.factory_id !== "1") return;
    const MQTTURL = "wss://" + window.location.host + "/mqtt";
    const machine_topic = `ijoon/ncs/fac-cam-06/PSB/data`;

    if (client.current) {
      client.current.unsubscribe(machine_topic);
      client.current.end();
      client.current = null;
    } else {
      client.current = mqtt.connect(MQTTURL, {
        clientId: "mqttjs_" + Math.random().toString(16).substr(2, 8),
        username: "ijoon",
        password: "9DGQhyCH6RZ4",
      });

      client.current.on("connect", () => {
        const qos = 0;
        client.current.subscribe(
          machine_topic,
          { qos },
          (err: any, res: any) => {
            if (err) {
              console.log(machine_topic, "연결 실패", err);
            } else {
              console.log(machine_topic, "연결 성공");
            }
          }
        );

        // 실시간 데이터 받기
        client.current.on("message", (topic: any, res: any) => {
          if (topic === machine_topic) {
            const now = currentDate(new Date());
            setMqttData({
              date: now,
              processing: JSON.stringify(JSON.parse(res)),
            });
          }
        });
      });
    }
  };

  useEffect(() => {
    mqttConnection();
    return () => {
      if (client.current) {
        const machine_topic = `ijoon/ncs/fac-cam-06/PSB/data`;
        client.current.unsubscribe(machine_topic);
        client.current.end();
      }
    };
  }, []);

  useEffect(() => {
    if (mqttData) {
      let nowProcess = JSON.parse(mqttData.processing);
      console.log(nowProcess);
      let isMedian = false;
      let lastData = {
        prod1: -1,
        prod2: -1,
        goal1: -1,
        goal2: -1,
      };
      for (let [key, v] of Object.entries(nowProcess)) {
        const value = Number(v);

        if (medianFilter[key][medianFilter[key].length - 1] === value) {
          medianFilter[key].push(value);
        } else {
          medianFilter[key] = [value];
        }

        if (medianFilter[key].length >= medianFilterLength) {
          isMedian = true;
          lastData[key] = value;
          medianFilter[key] = [value];
        }
      }

      if (!isMedian) return;

      nowProcess.goal1 =
        lastData.goal1 !== -1 ? lastData.goal1.toString() : nowProcess.goal1;
      nowProcess.goal2 =
        lastData.goal2 !== -1 ? lastData.goal2.toString() : nowProcess.goal2;
      nowProcess.prod1 =
        lastData.prod1 !== -1 ? lastData.prod1.toString() : nowProcess.prod1;
      nowProcess.prod2 =
        lastData.prod2 !== -1 ? lastData.prod2.toString() : nowProcess.prod2;

      console.log(nowProcess);

      // medianFilter
      // medianFilterLength
      // calculMedianFilter(nowProcess);

      // nowProcess = {
      //   goal1:
      //     medianFilter.goal1.length !== medianFilterLength
      //       ? ""
      //       : medianFilter.goal1[2],
      //   goal2:
      //     medianFilter.goal2.length !== medianFilterLength
      //       ? ""
      //       : medianFilter.goal2[2],
      //   prod1:
      //     medianFilter.prod1.length !== medianFilterLength
      //       ? ""
      //       : medianFilter.prod1[2],
      //   prod2:
      //     medianFilter.prod2.length !== medianFilterLength
      //       ? ""
      //       : medianFilter.prod2[2],
      // };

      // if (medianFilter.goal1.length === medianFilterLength) {
      //   medianFilter.goal1 = [medianFilter.goal1[2]];
      // }
      // if (medianFilter.goal2.length === medianFilterLength) {
      //   medianFilter.goal2 = [medianFilter.goal2[2]];
      // }
      // if (medianFilter.prod1.length === medianFilterLength) {
      //   medianFilter.prod1 = [medianFilter.prod1[2]];
      // }
      // if (medianFilter.prod2.length === medianFilterLength) {
      //   medianFilter.prod2 = [medianFilter.prod2[2]];
      // }

      if (psb) {
        const newData = [...psb];
        const preProcess = JSON.parse(newData[0].processing);
        if (
          nowProcess.goal1 === "" ||
          nowProcess.goal2 === "" ||
          nowProcess.prod1 === "" ||
          nowProcess.prod2 === ""
        )
          return;

        const process = {
          goal1: nowProcess.goal1 === "" ? preProcess.goal1 : nowProcess.goal1,
          goal2: nowProcess.goal2 === "" ? preProcess.goal2 : nowProcess.goal2,
          prod1: nowProcess.prod1 === "" ? preProcess.prod1 : nowProcess.prod1,
          prod2: nowProcess.prod2 === "" ? preProcess.prod2 : nowProcess.prod2,
        };

        if (psb[0].date.substring(11, 13) === mqttData.date.substring(11, 13)) {
          if (
            preProcess.goal1 === nowProcess.goal1 &&
            preProcess.goal2 === nowProcess.goal2 &&
            preProcess.prod1 === nowProcess.prod1 &&
            preProcess.prod2 === nowProcess.prod2
          ) {
            return;
          }

          // const newMqttData = {
          //   goal1:
          // }

          // if (
          //   preProcess.goal1 >= nowProcess.goal1 ||
          //   preProcess.goal2 >= nowProcess.goal2 ||
          //   preProcess.prod1 >= nowProcess.prod1 ||
          //   preProcess.prod2 >= nowProcess.prod2
          // ) {
          //   return;
          // }
          newData.shift();
          setPsb([
            { ...mqttData, processing: JSON.stringify(process) },
            ...newData,
          ]);
        } else {
          setPsb([
            { ...mqttData, processing: JSON.stringify(process) },
            ...psb,
          ]);
        }
      } else {
        setPsb([{ ...mqttData }]);
      }
    }
  }, [mqttData]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <MetaTags>
          <title>Plandy</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title='Dashboard' breadcrumbItem='Dashboard' />
          <Row>
            <Col md={6}>
              <Row>
                {/* 생산 현황판 prod1 */}
                <InvestedOverview
                  goal={processing ? processing.goal1 : "-"}
                  prod={processing ? processing.prod1 : "-"}
                  percent={Number.parseFloat(prod1Per.toFixed(1))}
                  totalPercent={totalPercent1}
                  prevProd={prevPprocessing ? prevPprocessing.prod1 : 0}
                  title={t("Production status board") + "(prod1)"}
                  prevPsb={prevPsb}
                />
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                {/* 생산 현황판 prod2 */}
                <InvestedOverview
                  goal={processing ? processing.goal2 : "-"}
                  prod={processing ? processing.prod2 : "-"}
                  percent={Number.parseFloat(prod2Per.toFixed(1))}
                  totalPercent={totalPercent2}
                  prevProd={prevPprocessing ? prevPprocessing.prod2 : 0}
                  title={t("Production status board") + "(prod2)"}
                  prevPsb={prevPsb}
                />
              </Row>
            </Col>
            {/* <Col md={2}>
              <Card className='card-h-100'>
                <CardBody>
                  <h5 className='card-title me-2'>불량률</h5>
                  <Row className='align-items-center'>
                    <div className='col-lg'>
                      <div id='invested-overview' className='apex-charts'>
                        <ApexErr percent={"1.2"} />
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col> */}
          </Row>

          <Row>
            <Col>
              <Card className='card-h-100'>
                <CardBody>
                  {/* 생산 현황 추이 */}
                  <CurrentStatus
                    prod1={data1.reverse()}
                    prod2={data2.reverse()}
                    time={prodTime.reverse()}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Card className='card-h-100'>
              <CardBody>
                <div className='d-flex flex-wrap align-items-center'>
                  <h5 className='card-title me-2'>
                    {t("Facility Operation Status")}
                  </h5>
                </div>
                {/* 설비 운영 현황 */}
                <FacilityOperation
                  machine={data}
                  machineList={
                    !isEmpty(data)
                      ? Object.keys(data).filter((item) => {
                          return item !== "etc" && item !== "psb";
                          // return true;
                        })
                      : []
                  }
                />
              </CardBody>
            </Card>
          </Row>
          {/* <Row>
            과전류시험기
            {data && (
              <LineGraphFacility
                title={data?.oct?.note}
                subtitle={`${data?.oct?.serial} / ${data?.oct?.name}`}
              />
            )}
            내전압시험기
            {data && (
              <LineGraphFacility
                title={data?.wvt?.note}
                subtitle={`${data?.wvt?.serial} / ${data?.wvt?.name}`}
              />
            )}
            프레스1호기
            {data && (
              <LineGraphFacility
                title={data?.press_1?.note}
                subtitle={`${data?.press_1?.serial} / ${data?.press_1?.name}`}
              />
            )}
          </Row>
          <Row>
            자동용접기1호
            {data && (
              <LineGraphFacility
                title={data?.wdmc_1?.note}
                subtitle={`${data?.wdmc_1?.serial} / ${data?.wdmc_1?.name}`}
              />
            )}
            자동용접기4호
            {data && (
              <LineGraphFacility
                title={data?.wdmc_4?.note}
                subtitle={`${data?.wdmc_4?.serial} / ${data?.wdmc_4?.name}`}
              />
            )}
            자동용접기6호
            {data && (
              <LineGraphFacility
                title={data?.wdmc_6?.note}
                subtitle={`${data?.wdmc_6?.serial} / ${data?.wdmc_6?.name}`}
              />
            )}
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
