import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p className="mb-0">
                Copyright © {new Date().getFullYear()} Ijoon All right reserved.
              </p>
            </div>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
