/** @format */

import ReactDatetime from "react-datetime";

import React, { useState, useEffect, memo } from "react";
import ReactApexChart from "react-apexcharts";

import Select from "react-select";
import { Alert } from "reactstrap";
import { keyBy } from "lodash";
import SearchChartApex from "../AllCharts/apex/SearchChartApex";
import SearchTable from "./SearchTable";
import { mergeDate, currentDateNoTime, currentTime } from "src/ts/operator";
import FacCam08 from "./FacCam08";

import DateTimeFields from "src/pages/Dashboard/components/DateTimeFields";
import { useDispatch } from "react-redux";
import { setLoading } from "src/store/actions";

const SearchChartTable = memo(({ get, GET_STORAGE, serial }: any) => {
  // const series: any = [];

  const [searchChartData, setSearchChartData] = useState({
    chartData: {},
    chartTime: [],
  });

  const dispatch = useDispatch();

  // 검색 요청하기
  function requestStorageData(preDate: any, secondDate: any, interval: any) {
    dispatch(setLoading(true));
    let chartData: any = {};
    let chartLabel: string[] = [];
    let chartTime: any = [];
    const prev_date = `${preDate.date.replace(/-/g, "/")} ${preDate.time}:00`;
    // const prev_date = `${preDate.date.replace(/-/g, "/")} 00:00:00`;
    const next_date = `${secondDate.date.replace(/-/g, "/")} ${
      secondDate.time
    }:00`;

    // const next_date = `${preDate.date.replace(/-/g, "/")} 23:59:59`;
    const query =
      GET_STORAGE +
      `?serial=${serial}&prev_date=${prev_date}&next_date=${next_date}&interval=${interval}&cursor=1&count=10000`;
    get(query).then((res: any) => {
      try {
        const { storageData } = res;
        for (let item of storageData) {
          const processing = JSON.parse(item.processing);
          for (let [key, value] of Object.entries(processing)) {
            if (value !== false && value !== true) {
              if (!Object.keys(chartData).includes(key)) {
                chartLabel.push(key);
                chartData[key] = [value === "" ? "0" : value];
              } else {
                chartData[key].push(value === "" ? "0" : value);
              }
            }
          }
          chartTime.push(mergeDate(item.date, interval));
        }
        setSearchChartData({
          chartData,
          chartTime,
        });
        dispatch(setLoading(false));
      } catch (err) {
        dispatch(setLoading(false));
        setSearchChartData({
          chartData: {},
          chartTime: [],
        });
      }
    });
  }

  return (
    <div id='chart'>
      <div className=''>
        <DateTimeFields requestStorageData={requestStorageData} />
        {serial !== "fac-cam-08" ? (
          <SearchChartApex
            chartData={searchChartData.chartData}
            time={[...searchChartData.chartTime].reverse()}
          />
        ) : (
          <FacCam08
            chartData={searchChartData.chartData}
            time={[...searchChartData.chartTime].reverse()}
          />
        )}
        <SearchTable
          chartData={searchChartData.chartData}
          time={[...searchChartData.chartTime].reverse()}
        />
      </div>
    </div>
  );
});
export default SearchChartTable;
