/** @format */

import React, { useState, useContext, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import mqtt from "mqtt";

//i18n
import { useTranslation } from "react-i18next";
import { WebSocketContext } from "../../websocket/WebSocketProvider";
import { get } from "../../helpers/api_helper";
import "../../assets/scss/custom/components/_search_date.scss";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CameraPlay from "./CameraPlay";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import RealTimeChartTable from "./RealTimeChartTable";

interface GraphSeries {
  name: string;
  label: string;
  data: {
    x: number;
    y: string;
  }[];
}

interface MachineInfo {
  id: string;
  foctory_id: string;
  name: string;
  note: string;
  reg_time: string;
  serial: string;
}

// mqtt message에서 series가 빈값으로 나와서 새로 선언해 줌
let pannelList: string[] = [];
let pannelListData: GraphSeries[] = [];

const GET_STORAGE = "/api/main/v1/storage";
const GET_MACHINELIST = "/api/main/v1/machine";

const MachineDetail = ({ match }: any) => {
  const { userMail } = useSelector((state: any) => ({
    userMail: state.login.user.email,
  }));

  const [startComponent, setStartComponent] = useState<boolean>(false);

  const client = useContext(WebSocketContext);
  const [series, setSeries] = useState<GraphSeries[]>([]);

  const [latestMessage, setLatestMessage] = useState<any>({});

  const [categories, setCategories]: any = useState([]);

  const { factory_id } = match.params;
  const factoryList = localStorage.getItem("factoryList");
  const { serial } = match.params;

  const { name } = match.params;

  const history = useHistory();

  // 유저가 해당 장비를 읽을 수 있는지 확인
  const confirmUser = async () => {
    if (!userMail) return;
    try {
      const machineList = await get(
        GET_MACHINELIST +
          `?owner_email=${userMail}&factory_id=${factory_id}&cursor=0&count=10000`
      );

      let isExist: boolean = false;

      for (let item of machineList.machine) {
        if (item.serial === serial) {
          if (item.name === name) {
            isExist = true;
          }
        }
      }

      if (!isExist) {
        alert("접근 권한이 없습니다.");
        history.push("/main");
      } else {
        setStartComponent(true);
      }
    } catch (_e) {
      const err: any = _e;
      if (err.response) {
        if (err.response.status === 500) {
          alert("접근 권한이 없습니다.");
          history.push("/main");
        }
      }
    }
  };

  useEffect(() => {
    confirmUser();
  }, [userMail]);

  // 비디오 높이
  const [cardHeight, setCardHeight] = useState<number>(0);
  function handleHight(data: number) {
    if (cardHeight < data) {
      setCardHeight(data);
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <MetaTags>
          <title>Plandy</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={serial} breadcrumbItem={serial} detail={true} />

          <div className='detail-container'>
            <Card
              className='card-h-100 camera'
              // style={{ height: `${cardHeight}px` }}
            >
              <CardBody>
                <CameraPlay
                  serial={serial}
                  handleHight={handleHight}
                  cardHeight={cardHeight}
                />
              </CardBody>
            </Card>
            <RealTimeChartTable
              match={match}
              cardHeight={cardHeight}
              startComponent={startComponent}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MachineDetail;
